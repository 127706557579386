import { Interaction, InteractionData } from "@auvious/interaction";
import moment, { Duration } from "moment";
import { IInteractionMetrics, IRecorderInfo, RecorderStateEnum } from ".";
import {
  InteractionMetricEnum,
  ConversationTypeEnum,
  EndpointTypeEnum,
  IInteractionMetricConference,
} from "../core-ui.enums";

export class InteractionMetrics implements IInteractionMetrics {
  private _data: InteractionData = {};
  private _id: string;
  private _organizationId: string;
  private _duration: Duration;
  private _type: ConversationTypeEnum;
  private _mediaRecorderInfo: IRecorderInfo;
  private _cobrowseRecorderInfo: IRecorderInfo;
  private _cobrowseRequestType: EndpointTypeEnum;
  private _createdBy: string;
  private _customerDisplayName: string;
  private _conferences: IInteractionMetricConference[];
  private _callHoldDuration: Duration;

  constructor(_interaction: Interaction) {
    const { id, organizationId, data, type, createdBy } = _interaction;
    this._id = id;
    this._organizationId = organizationId;
    this._data = data;
    this._createdBy = createdBy;
    this._type = type as ConversationTypeEnum;
    this._conferences = this._data[InteractionMetricEnum.conferences];

    if (this._conferences) {
      this._duration = moment.duration();
      this._callHoldDuration = moment.duration();
      Object.values(this._conferences).reduce((total, data) => {
        total.add(data.duration);
        return total;
      }, this._duration);
      Object.values(this._conferences).reduce((total, data) => {
        total.add(data.on_hold_duration);
        return total;
      }, this._callHoldDuration);
    } else if (this.getCallStartAt() && this.getCallEndAt()) {
      this._duration = moment.duration(
        moment(this.getCallEndAt()).diff(moment(this.getCallStartAt()))
      );
    }
    if (this._data?.[InteractionMetricEnum.recorderInstanceId]) {
      this._mediaRecorderInfo = {
        instanceId: this._data[InteractionMetricEnum.recorderInstanceId],
        recorderId: this._data[InteractionMetricEnum.recorderId],
        conversationId: this._id,
        state: RecorderStateEnum.stopped,
      };
    }
    if (this._data?.[InteractionMetricEnum.cobrowseRecorderInstanceId]) {
      this._cobrowseRecorderInfo = {
        instanceId:
          this._data[InteractionMetricEnum.cobrowseRecorderInstanceId],
        recorderId: this._data[InteractionMetricEnum.cobrowseRecorderId],
        conversationId: this._id,
        state: RecorderStateEnum.stopped,
      };
    }
    this._cobrowseRequestType =
      this._data?.[InteractionMetricEnum.cobrowseRequestType] ||
      EndpointTypeEnum.coBrowse;
  }

  getId() {
    return this._id;
  }
  getCallType() {
    return this._type;
  }
  getCallOrigin() {
    return this._data?.[InteractionMetricEnum.callOrigin];
  }
  getCallDestination() {
    return this._data?.[InteractionMetricEnum.callDestination];
  }
  getCallOriginMode() {
    return this._data?.[InteractionMetricEnum.callOriginMode];
  }
  getCallStartAt() {
    return this._data?.[InteractionMetricEnum.callStartAt];
  }
  getCallEndAt() {
    return this._data?.[InteractionMetricEnum.callEndAt];
  }
  getCallDuration() {
    return this._duration;
  }
  getCallHoldDuration(): moment.Duration {
    return this._callHoldDuration;
  }
  getCallRecorderInfo(): IRecorderInfo {
    return this._mediaRecorderInfo;
  }
  getCobrowseRecorderInfo(): IRecorderInfo {
    return this._cobrowseRecorderInfo;
  }
  getCobrowseRequestType(): EndpointTypeEnum {
    return this._cobrowseRequestType;
  }
  getOriginUrl(): string {
    return this._data?.[InteractionMetricEnum.originUrl];
  }
  getCreatedBy(): string {
    return this._createdBy;
  }
  getCustomerDisplayName(): string {
    return this._data?.[InteractionMetricEnum.customerDisplayName];
  }
  getCustomerMetadata(): { [key: string]: any } {
    return this._data?.[InteractionMetricEnum.customerMetadata];
  }
  getCustomerId(): string {
    return this._data?.[InteractionMetricEnum.customerId];
  }
  setMetric(key: InteractionMetricEnum, value: any) {
    this._data = {
      ...this._data,
      [key]: value,
    };
    // recalculate duration
    if (
      [
        InteractionMetricEnum.callEndAt,
        InteractionMetricEnum.callStartAt,
      ].includes(key)
    ) {
      this._duration = moment.duration(
        moment(this.getCallEndAt()).diff(moment(this.getCallStartAt()))
      );
    }
  }

  getMetric(key: InteractionMetricEnum) {
    return this._data[key];
  }

  getMetrics(): InteractionData {
    return this._data;
  }
  getTermsAcceptedAt(): Date {
    return !!this._data?.[InteractionMetricEnum.termsAcceptedAt]
      ? new Date(this._data[InteractionMetricEnum.termsAcceptedAt])
      : null;
  }
  getTermsAcceptedSnapshot(): string {
    return this._data?.[InteractionMetricEnum.termsAcceptedSnapshot];
  }

  getIntegrationInteractionAvailable(): boolean {
    return (
      this._data?.[InteractionMetricEnum.integrationInteractionAvailable] ??
      true
    );
  }
}
