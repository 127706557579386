import {
  Component,
  Input,
  Output,
  EventEmitter,
  signal,
  OnInit,
  ViewChildren,
  QueryList,
} from "@angular/core";
import {
  AppConfigService,
  IInteraction,
  ISurvey,
  ISurveyQuestion,
  PublicParam,
  RatingService,
  UserService,
} from "../..";
import { RatingType } from "@auvious/rating";

export interface ISurveyQuestionForm {
  isValid: () => boolean;
  markAsTouched: () => void;
}

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent implements OnInit {
  @Input() interaction: IInteraction;

  @Output() done = new EventEmitter<ISurveyQuestion[]>();
  @Output() skipped = new EventEmitter();

  questions = signal<ISurveyQuestion[]>([]);
  isFormValid = signal(true);

  @ViewChildren("questionRef") questionsRef: QueryList<ISurveyQuestionForm>;

  typeShortText: RatingType = "TEXT_300";
  typeLongText: RatingType = "TEXT_1000";
  typeShortScore: RatingType = "RATING_1_5";
  typeLongScore: RatingType = "RATING_1_10";
  typeSingleChoice: RatingType = "SINGLE_CHOICE";
  typeMultipleChoice: RatingType = "MULTIPLE_CHOICE";

  answers = new Map<string, ISurveyQuestion>();

  constructor(
    private config: AppConfigService,
    private user: UserService,
    private rating: RatingService
  ) {}

  ngOnInit(): void {
    this.questions.set(
      this.config
        .publicParam(PublicParam.SURVEYS)
        .filter(
          (s: ISurvey) =>
            s.active &&
            this.user.getActiveUser().getRoles().includes(s.target) &&
            (!s.conversationType ||
              s.conversationType === this.interaction.getType())
        )
        .map((s: ISurvey) => s.questions)
        .flatMap((arr) => arr)
    );
  }

  answerChanged(item: ISurveyQuestion) {
    this.answers.set(item.id, item);
    this.isFormValid.set(true);
  }

  send() {
    let valid = true;
    this.questionsRef.toArray().forEach((f) => {
      valid = f.isValid() && valid;
      if (!f.isValid()) f.markAsTouched();
    });
    this.isFormValid.set(valid);
    if (valid) {
      this.done.emit(Array.from(this.answers.values()));
    }
  }

  skip() {
    this.skipped.emit();
  }
}
