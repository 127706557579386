import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import type TFuse from "fuse.js";
import Fuse from "fuse.js/dist/fuse.basic.esm.js";
import { debugError } from "./utils";
import { ISearchableItem } from "../models/interfaces";

interface Highlight {
  add(range: Range): void;
  remove(range: Range): void;
  clear(): void;
}

@Injectable()
export class SearchService {
  public highlight: Highlight =
    // @ts-expect-error
    // eslint-disable-next-line compat/compat
    typeof window.Highlight !== "undefined" ? new Highlight() : null;

  // notify when a search is run
  private _itemsMatched: Subject<string[]> = new Subject();
  public matchFound$ = this._itemsMatched.asObservable();

  private _indexedDB: TFuse<ISearchableItem>;

  constructor() {
    if (this.highlight) {
      // @ts-expect-error
      CSS.highlights.set("settings-search", this.highlight);
    }

    this._indexedDB = new Fuse([], {
      ignoreLocation: true,
      findAllMatches: false,
      includeMatches: true,
      minMatchCharLength: 2,
      threshold: 0.3,
      includeScore: true,
      keys: ["textNormalized"],
    });
  }

  /** breaks each character into its base character and its diacritical marks and then remove those */
  private normalizeText(text: string) {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f\u20D0-\u20FF]/g, "")
      .toLocaleLowerCase();
  }

  addSearchable(item: ISearchableItem) {
    try {
      item.textNormalized = this.normalizeText(item.text);
      this._indexedDB.add(item);
      // debug("search.add", item);
    } catch (ex) {
      debugError(ex);
    }
  }

  removeSearchable(id: string) {}

  search(query: string) {
    this.highlight?.clear();

    try {
      query = this.normalizeText(query).trim();

      const minTermLength =
        Math.min(...query.split(/\P{L}+/u).map((term) => term.length)) - 1;

      const results = this._indexedDB.search(`'${query}`);

      // debug: log what is being matched
      // results.forEach((result) => {
      //   debug("search.results", result.score, result.item.text);
      //   result.matches[0].indices.forEach((i) =>
      //     debug(
      //       "search.results",
      //       result.item.text.slice(i[0], i[1] + 1),
      //       -i[0] + i[1] + 1
      //     )
      //   );
      // });

      const categories: string[] = [];

      // remove nonsense: 1. low score 2. match lengh much smaller than shortest word in query
      for (const result of results) {
        if (result.score >= 0.89) continue;

        const matches = [
          ...new Set(
            result.matches[0].indices
              .filter((index) => index[1] - index[0] + 1 >= minTermLength)
              .map((index) => result.item.text.slice(index[0], index[1] + 1))
          ),
        ];

        if (!matches.length) continue;

        result.item.onMatch(matches);

        if (!categories.includes(result.item.key))
          categories.push(result.item.key);
      }

      this._itemsMatched.next(categories);
    } catch (ex) {
      debugError(ex);
    }
  }
}
