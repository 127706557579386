import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { IInteraction, GenericErrorHandler } from "../../../../core-ui";
import { InteractionService } from "../../../services";
import { AnalyticsService } from "../../../../core-ui/services/analytics.service";
import { IShareLinkAction } from "../share-link.component";
import {
  IMenuItemContainer,
  MENU_ITEM_CONTAINER_TOKEN,
  MenuItemComponent,
} from "../../../../modules/shared/components";

@Component({
  selector: "app-share-link-widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.scss"],
  providers: [
    {
      provide: MENU_ITEM_CONTAINER_TOKEN,
      useExisting: ShareLinkWidgetComponent,
    },
  ],
})
export class ShareLinkWidgetComponent
  implements OnInit, IShareLinkAction, IMenuItemContainer
{
  @Input() interaction: IInteraction;
  @Input() ticket: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChildren(MenuItemComponent) menuItems: QueryList<MenuItemComponent>;

  constructor(
    private interactionService: InteractionService,
    private errorHandler: GenericErrorHandler
  ) {}

  ngOnInit(): void {}

  getMenuItems(): MenuItemComponent[] {
    return this.menuItems.toArray();
  }

  shareLink() {
    try {
      this.interactionService.invite(
        this.interaction.getType(),
        this.interaction,
        this.ticket
      );
      // this.analyticsService.trackInvitationSent(this.interaction);
      // this.renewCustomerLink();
      this.completed.emit();
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }
}
