import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  signal,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";
import { ISurveyQuestion, ThemeService } from "../../..";
import { RatingScoreItem } from "@auvious/rating";
import { Subscription } from "rxjs";
import { ISurveyQuestionForm } from "../survey.component";

@Component({
  selector: "app-survey-score",
  templateUrl: "./score.component.html",
  styleUrls: ["./score.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyScoreComponent
  implements OnInit, OnDestroy, ISurveyQuestionForm
{
  @Input() question: ISurveyQuestion;

  @Output() changed = new EventEmitter<RatingScoreItem>();

  item = signal<RatingScoreItem>(undefined);
  hoverRating = signal(0);
  points: number[] = [];
  isDarkMode = signal(false);
  subscriptions = new Subscription();
  isTypeStar = false;
  isError = signal(false);

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    this.item.set({
      ...this.question,
      rating: null,
    });

    this.isTypeStar = this.question.visual === "stars";

    const max = this.item().type === "RATING_1_10" ? 10 : 5;
    const min = 1;
    for (var i = min; i <= max; i++) {
      this.points.push(i);
    }

    this.subscriptions.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        this.isDarkMode.set(theme.darkMode);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  rate(point) {
    this.isError.set(false);
    this.item.update((item) => ({ ...item, rating: point }));
    this.changed.emit(this.item());
  }

  empty() {
    this.hoverRating.set(0);
  }

  fill(point) {
    this.hoverRating.set(point);
  }

  isActive(point) {
    return this.hoverRating() >= point || this.item().rating >= point;
  }

  isValid() {
    return this.item().rating !== null;
  }

  markAsTouched() {
    this.isError.set(true);
  }
}
