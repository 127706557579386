import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

type IconCSSClass = "wave";
@Injectable()
export class ActivityIndicatorService {
  private _visible = new Subject<boolean>();
  public isVisible$ = this._visible.asObservable();

  private _message = new Subject<string>();
  public message$ = this._message.asObservable();

  private _icon = new Subject<{ name: string; cssClass?: IconCSSClass }>();
  public icon$ = this._icon.asObservable();

  private _backdrop = new Subject<boolean>();
  public backdrop$ = this._backdrop.asObservable();

  public setMessage(message: string) {
    this._message.next(message);
  }

  private setBackdrop(visible: boolean) {
    this._backdrop.next(visible);
  }

  public error(message: string, timeoutSeconds?: number) {
    this.showIconMessage("close-big", message, timeoutSeconds);
  }

  public show() {
    this._visible.next(true);
  }

  public hide() {
    this._visible.next(false);
    this.setMessage(null);
    this.setIcon(null);
  }

  public loading(loading: boolean, message?: string, backdrop?: boolean) {
    this.setMessage(message);
    this.setBackdrop(backdrop);

    if (loading) {
      this.show();
    } else {
      this.hide();
      this.setBackdrop(false);
    }
  }

  public setIcon(icon: string, cssClass?: IconCSSClass) {
    this._icon.next({ name: icon, cssClass });
  }

  public showIconMessage(
    icon: string,
    message: string,
    timeoutSeconds?: number,
    cssClass?: IconCSSClass
  ) {
    this.setIcon(icon, cssClass);
    this.setMessage(message);
    this.show();

    if (timeoutSeconds !== undefined) {
      setTimeout(() => {
        this.hide();
      }, timeoutSeconds * 1000);
    }
  }
}
