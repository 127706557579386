import { Component, Input, OnInit } from "@angular/core";
import { CompositionStateEnum } from "../../../../core-ui";

@Component({
  selector: "app-composition-state",
  templateUrl: "./composition-state.component.html",
  styleUrls: ["./composition-state.component.scss"],
})
export class CompositionStateComponent implements OnInit {
  @Input() state: CompositionStateEnum;

  stateSubmitted = CompositionStateEnum.submitted;
  stateQueued = CompositionStateEnum.queued;
  stateProcessing = CompositionStateEnum.processing;
  stateCancelled = CompositionStateEnum.cancelled;
  stateFailed = CompositionStateEnum.failed;
  stateCompleted = CompositionStateEnum.completed;

  ngOnInit(): void {
      
  }
}
