import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";

import {
  BrowserNotificationStrategy,
  NoAnalyticsStrategy,
  NoIntegrationStrategy,
  NoQueueStrategy,
  StandaloneInvitationStrategy,
  DefaultInteractionStrategy,
  NoConversationStrategy,
} from "../strategies";

import {
  ApplicationTypeEnum,
  AuviousRtcService,
  ProtectedTicketService,
} from "../../../core-ui";
import {
  IAuthenticationStrategy,
  IInteractionStrategy,
  TestAuthenticationStrategy,
  IAnalyticsStrategy,
  IQueueStrategy,
  IInvitationStrategy,
  INotificationStrategy,
  IIntegrationStrategy,
  IConversationStrategy,
} from "../../../core-ui/models/strategies";
import {
  IApplication,
  IApplicationConfig,
  IApplicationDTO,
} from "../../../core-ui/models/IApplication";

export class TestApplication implements IApplication {
  private mAuthenticationStrategy: IAuthenticationStrategy;
  private mInteractionStrategy: IInteractionStrategy;
  private mAnalyticsStrategy: IAnalyticsStrategy;
  private mQueueStrategy: IQueueStrategy;
  private mTicketStrategy: IInvitationStrategy;
  private mNotificationStrategy: INotificationStrategy;
  private mIntegrationStrategy: IIntegrationStrategy;
  private mConversationStrategy: IConversationStrategy;

  constructor(
    private dto: IApplicationDTO,
    injector: Injector,
    clientId: string
  ) {
    this.mAuthenticationStrategy = new TestAuthenticationStrategy(
      injector.get(AuviousRtcService),
      injector.get(HttpClient),
      clientId
    );
    this.mInteractionStrategy = new DefaultInteractionStrategy(
      injector.get(ProtectedTicketService)
    );
    this.mAnalyticsStrategy = new NoAnalyticsStrategy();
    this.mQueueStrategy = new NoQueueStrategy();
    this.mTicketStrategy = new StandaloneInvitationStrategy(
      this.getId(),
      this.getConfig()?.publicParameters,
      this.getConfig()?.agentParameters
    );
    this.mNotificationStrategy = BrowserNotificationStrategy.singleton();
    this.mIntegrationStrategy = new NoIntegrationStrategy();
    this.mConversationStrategy = new NoConversationStrategy();
  }

  getId(): string {
    return this.dto.id;
  }

  getDto(): IApplicationDTO {
    return this.dto;
  }

  getType(): ApplicationTypeEnum {
    return ApplicationTypeEnum.Test;
  }

  getOrganizationId(): string {
    return this.dto.organizationId;
  }

  getConfig(): IApplicationConfig {
    return this.dto.config;
  }

  getCreatedAt(): Date {
    return new Date(this.dto.createdAt);
  }

  getVersion(): number {
    return this.dto.version;
  }

  getFilterBackgrounds() {
    return [];
  }

  canCallWithoutActiveInteraction(): boolean {
    return true;
  }

  isReady(): boolean {
    return true;
  }

  registerLifecycleEventHandlers() {}

  authenticationStrategy(): IAuthenticationStrategy {
    return this.mAuthenticationStrategy;
  }

  interactionStrategy(): IInteractionStrategy {
    return this.mInteractionStrategy;
  }

  analyticsStrategy(): IAnalyticsStrategy {
    return this.mAnalyticsStrategy;
  }

  queueStrategy(): IQueueStrategy {
    return this.mQueueStrategy;
  }

  ticketStrategy(): IInvitationStrategy {
    return this.mTicketStrategy;
  }

  notificationStrategy(): INotificationStrategy {
    return this.mNotificationStrategy;
  }

  integrationStrategy(): IIntegrationStrategy {
    return this.mIntegrationStrategy;
  }

  supportsInteractions(): boolean {
    return false;
  }

  supportsInteractionMetrics(): boolean {
    return false;
  }

  conversationStrategy(): IConversationStrategy {
    return this.mConversationStrategy;
  }
}
