import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "av-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
})
export class ConfirmComponent implements OnInit, OnChanges {
  @Input()
  set open(value: boolean) {
    this._open = value;
    // Initial focus setting if the component loads and is already set to open
    if (this.open) {
      this.setFocusOnConfirm();
    }
  }

  get open(): boolean {
    return this._open;
  }

  @Input() arrow: "top-left" | "top-right" | "bottom-left" | "bottom-right" =
    "top-left";

  @Input() cancelLabel: string;

  @Output() confirmed: EventEmitter<void> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  @ViewChild("confirmRef", { read: ElementRef })
  confirmRef: ElementRef<HTMLButtonElement>;

  @ViewChild("cancelRef", { read: ElementRef })
  cancelRef: ElementRef<HTMLButtonElement>;

  hAlign: string;
  vAlign: string;
  _open = false;

  constructor() {}

  ngOnInit(): void {
    this.hAlign = this.arrow.split("-")?.[1] || "left";
    this.vAlign = this.arrow.split("-")?.[0] === "bottom" ? "top" : "bottom";
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["open"] && changes["open"].currentValue === true) {
      this.setFocusOnConfirm();
    }
  }

  private setFocusOnConfirm(): void {
    requestAnimationFrame(() => this.confirmRef?.nativeElement?.focus());
  }

  // get hAlign() {
  //   return  // === "top-left" ? "left" : "right";
  // }
  // get vAlign() {
  //   return this.arrow.split("-")?.[1] || "top";
  // }

  cancel() {
    this.canceled.emit();
  }

  confirm() {
    this.confirmed.emit();
  }
}
