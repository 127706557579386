<div
  role="alert"
  [attr.aria-live]="'polite'"
  [attr.aria-label]="'Video call ended' | translate"
  class="container text-center"
  *ngIf="isCustomer"
>
  <h1 aria-hidden="true" translate>Video call ended</h1>
  <p translate>Thank you for contacting us.</p>
</div>

<div
  role="alert"
  [attr.aria-live]="'polite'"
  [attr.aria-label]="'You left the call' | translate"
  class="container text-center"
  *ngIf="isAgent"
>
  <h1 aria-hidden="true" translate>You left the call</h1>
  <p translate>It is now safe to close this page.</p>
</div>
