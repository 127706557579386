<!-- [class.date-range-popup-open]="open" -->
<av-card
  class="date-range-popup"
  [backdrop]="false"
  [arrow]="arrowPos"
>
  <av-card-header (close)="closeRequest()"></av-card-header>
  <av-card-body size="sm" class="fx-row">
    <div class="fx-flex-1 date-range-presets">
      <av-menu>
        <av-menu-item (select)="rangeChange(rangeToday)">{{
          "Today" | translate
        }}</av-menu-item>
        <av-menu-item (select)="rangeChange(rangeYesterday)">{{
          "Yesterday" | translate
        }}</av-menu-item>
        <av-menu-item (select)="rangeChange(rangeThisWeek)">{{
          "This week" | translate
        }}</av-menu-item>
        <av-menu-item (select)="rangeChange(rangeLastWeek)">{{
          "Last week" | translate
        }}</av-menu-item>
        <av-menu-item (select)="rangeChange(rangeThisMonth)">{{
          "This month" | translate
        }}</av-menu-item>
        <av-menu-item (select)="rangeChange(rangeLastMonth)">{{
          "Last month" | translate
        }}</av-menu-item>
      </av-menu>
    </div>
    <div class="fx-flex-4 fx-row fx-justify-space-between date-range-calendars">
      <dp-day-time-calendar
        class="av-date-time-calendar"
        name="startDate"
        [(ngModel)]="dpStartAt"
        [config]="dateConfig"
        theme="av-picker av-calendar"
        (onChange)="startChange($event)"
        #startPicker
      >
      </dp-day-time-calendar>
      <dp-day-time-calendar
        class="av-date-time-calendar"
        name="endDate"
        [(ngModel)]="dpEndAt"
        [config]="dateConfig"
        theme="av-picker av-calendar"
        (onChange)="endChange($event)"
        #endPicker
      >
      </dp-day-time-calendar>
    </div>
  </av-card-body>
</av-card>
