import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { IInteraction, NotificationService } from "../../../../core-ui";
import { InteractionService } from "../../../services";
import {} from "../../../services/error-handlers.service";
import { TranslateService } from "@ngx-translate/core";
import {
  AnalyticsService,
  GenericErrorHandler,
} from "../../../../core-ui/services";
import { IShareLinkAction } from "../share-link.component";
import {
  IMenuItemContainer,
  MENU_ITEM_CONTAINER_TOKEN,
  MenuItemComponent,
} from "../../../../modules/shared/components";

@Component({
  selector: "app-share-link-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
  providers: [
    {
      provide: MENU_ITEM_CONTAINER_TOKEN,
      useExisting: ShareLinkChatComponent,
    },
  ],
})
export class ShareLinkChatComponent
  implements OnInit, IShareLinkAction, IMenuItemContainer
{
  @Input() interaction: IInteraction;
  @Input() link: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChildren(MenuItemComponent) menuItems: QueryList<MenuItemComponent>;

  constructor(
    private interactionService: InteractionService,
    private analyticsService: AnalyticsService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {}

  getMenuItems(): MenuItemComponent[] {
    return this.menuItems.toArray();
  }

  shareLink() {
    try {
      this.interactionService.postMessageToInteraction(
        this.interaction,
        this.link
      );
      this.analyticsService.trackInvitationSent(this.interaction);
      this.notification.show("auvious", {
        body: this.translate.instant("Link sent to chat."),
      });
      // this.renewCustomerLink();
      this.completed.emit();

      // this.shareOpen = false;
      this.openChange.emit(false);
    } catch (ex) {
      this.errorHandler.handleError(ex);
    }
  }
}
