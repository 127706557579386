<form #f="ngForm" class="fx-column fx-flex-1 fx-gap-sm">
  <textarea
    avInput
    #answerRef="ngModel"
    name="answer"
    [maxlength]="maxLength"
    [ngModel]="item().answer"
    (ngModelChange)="update($event)"
    data-tid="survey/answer/text"
    required
    rows="5"
  ></textarea>
  <div
    class="form-error"
    *ngIf="answerRef.touched && answerRef.hasError('required')"
    translate
  >
    Required
  </div>
  <div class="text-right">
    <small class="text-muted"
      >{{ item().answer?.length }} / {{ maxLength }}
    </small>
  </div>
</form>
