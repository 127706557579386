<ng-content></ng-content>
<span class="av-tooltip-point" *ngIf="help">?</span>
<span
  role="tooltip"
  class="av-tooltip av-tooltip-{{ position }}"
  *ngIf="enabled"
  [class.av-tooltip-wrap]="wrap"
  translate
>
  <ng-content select="[tooltipMessage]"></ng-content>
  {{ message }}
</span>
