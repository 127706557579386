import { Injectable } from "@angular/core";
import { localStore } from "@auvious/utils";
import { KEY_LANG, KEY_LANG_COUNTRY } from "../../app/app.enums";
import { ApplicationProviderTypeEnum } from "../core-ui.enums";
import { IApplicationServiceParams } from "../models/application-options/ServiceOptions";

import {
  AgentOptions,
  AgentParam,
  CustomerOptions,
  CustomerParam,
  IApplicationAgentParams,
  IApplicationConfig,
  IApplicationPublicParams,
  PublicOptions,
  PublicParam,
  ServiceOptions,
} from "../models";
import { Subject } from "rxjs";

// eslint-disable-next-line no-shadow
export enum FEATURES {
  LASER_TOOL = "LASER_TOOL",
  LASER_TOOL_AGENT_ONLY = "LASER_TOOL_AGENT_ONLY",
  AGENT_FULL_SCREEN = "AGENT_FULL_SCREEN",
  CALL_HOLD = "CALL_HOLD",
  CALL_HOLD_AGENT_ONLY = "CALL_HOLD_AGENT_ONLY",
  SCREEN_SHARE = "SCREEN_SHARE",
  SCREEN_SHARE_CUSTOMER_ONLY = "SCREEN_SHARE_CUSTOMER_ONLY",
  RECORDER = "RECORDER",
  WIDGET_ENABLED = "WIDGET_ENABLED",
  CALLBACK = "CALLBACK",
  MEDIA_FILTERS = "MEDIA_FILTERS",
  PC_IFRAME_ALLOW_DISPLAY_CAPTURE = "PC_IFRAME_ALLOW_DISPLAY_CAPTURE",
  PC_IFRAME_ALLOW_FULLSCREEN = "PC_IFRAME_ALLOW_FULLSCREEN",
  COMPOSITION_MAX_PARTICIPANTS = "COMPOSITION_MAX_PARTICIPANTS",
}

export interface IConfig {
  MQTT_URL: string;
  AUVIOUS_AUTH: boolean;
  STATISTICS: {
    interval: number;
    enable: boolean;
  };
  SENTRY_DSN: string;
  SENTRY_ENV: string;
  SENTRY_ENABLED: boolean;

  FEATURES: Record<FEATURES, boolean | number>;
  RECORDER_ABORT_WAIT_TIME: number;

  CLIENT_ID_CUSTOMER: string;
  CLIENT_ID_AGENT: string;
  CLIENT_ID_TEST_AGENT: string;

  PARTICIPANT_LIMIT: number;

  PAGE_NOT_FOUND_REDIRECT_URL: string;
  PAGE_NOT_FOUND_MESSAGE: string;

  GOOGLE_TAG_MANAGER_ENABLED: boolean;
}

export const DEFAULT_FEATURES_CONFIG = {
  [FEATURES.LASER_TOOL]: false,
  [FEATURES.AGENT_FULL_SCREEN]: false,
  [FEATURES.CALL_HOLD]: false,
  [FEATURES.CALL_HOLD_AGENT_ONLY]: true,
  [FEATURES.SCREEN_SHARE]: true,
  [FEATURES.SCREEN_SHARE_CUSTOMER_ONLY]: false,
  [FEATURES.RECORDER]: false,
  [FEATURES.LASER_TOOL_AGENT_ONLY]: true,
  [FEATURES.WIDGET_ENABLED]: false,
  [FEATURES.CALLBACK]: false,
  [FEATURES.MEDIA_FILTERS]: true,
  [FEATURES.PC_IFRAME_ALLOW_DISPLAY_CAPTURE]: false,
  [FEATURES.COMPOSITION_MAX_PARTICIPANTS]: 9,
};

declare var CONFIG;

@Injectable()
export class AppConfigService {
  private scriptConfig: IConfig;
  private applicationConfig: IApplicationConfig;

  public pcEnvironment: string;
  private kioskMode = false;

  private agentOptions = AgentOptions.create({});
  private customerOptions = CustomerOptions.create({});
  private publicOptions = PublicOptions.create({});
  private serviceOptions = new ServiceOptions({}).values;

  private _configChanged = new Subject<IApplicationConfig>();
  public configChanged$ = this._configChanged.asObservable();

  constructor() {
    this.scriptConfig = { ...CONFIG };
  }

  public setApplicationConfig(config: IApplicationConfig) {
    this.agentOptions = AgentOptions.create(config.agentParameters);
    this.customerOptions = CustomerOptions.create(config.customerParameters);
    this.publicOptions = PublicOptions.create(config.publicParameters);
    this.serviceOptions = new ServiceOptions(config.serviceParameters).values;
    // set default values to config
    this.applicationConfig = {
      ...config,
      agentParameters: this.agentOptions,
      customerParameters: this.customerOptions,
      publicParameters: this.publicOptions,
      serviceParameters: this.serviceOptions,
      admins: config.admins || [],
      supervisors: config.supervisors || [],
    };
    this._configChanged.next(this.applicationConfig);
  }

  public getApplicationConfig() {
    return this.applicationConfig;
  }

  public get participantLimit() {
    return this.scriptConfig.PARTICIPANT_LIMIT || 8;
  }

  public get config(): IConfig {
    return this.scriptConfig;
  }
  public get mqttUrl(): string {
    return this.scriptConfig.MQTT_URL;
  }
  public get auviousAuth(): boolean {
    return this.scriptConfig.AUVIOUS_AUTH;
  }
  public get statistics() {
    return this.scriptConfig.STATISTICS || { interval: 900, enable: false };
  }
  public get sentryDSN(): string {
    return this.scriptConfig.SENTRY_DSN;
  }
  public get sentryEnvironment(): string {
    return this.scriptConfig.SENTRY_ENV;
  }

  public get clientIdCustomer() {
    return this.scriptConfig.CLIENT_ID_CUSTOMER || "customer";
  }
  public get clientIdAgent() {
    return this.scriptConfig.CLIENT_ID_AGENT || "agent";
  }
  public get clientIdTestAgent() {
    return this.scriptConfig.CLIENT_ID_TEST_AGENT || "test-agent";
  }
  public get pageNotFoundRedirectURL() {
    return this.scriptConfig.PAGE_NOT_FOUND_REDIRECT_URL;
  }
  public get pageNotFoundMessage() {
    return this.scriptConfig.PAGE_NOT_FOUND_MESSAGE;
  }
  public get isGoogleTagManagerEnabled() {
    return "GOOGLE_TAG_MANAGER_ENABLED" in this.scriptConfig
      ? this.scriptConfig.GOOGLE_TAG_MANAGER_ENABLED
      : true;
  }
  public get isSentryEnabled() {
    return "SENTRY_ENABLED" in this.scriptConfig
      ? this.scriptConfig.SENTRY_ENABLED
      : true;
  }

  public get supportedLanguages(): string[] {
    return [
      "zh",
      "de",
      "en",
      "es",
      "fi",
      "nl",
      "pl",
      "sv",
      "tr",
      "el",
      "pt",
      "it",
    ];
  }

  public get language(): string {
    return localStore.getItem(KEY_LANG) || "en";
  }

  public get countryCode(): string {
    return localStore.getItem(KEY_LANG_COUNTRY);
  }

  // after we receive 'aborted' wait for this time to get 'active' or 'failed' before notifying the user of a terminal error.
  public get recorderAbortWaitTime() {
    return this.scriptConfig.RECORDER_ABORT_WAIT_TIME || 30000;
  }

  public featureEnabled(feature): boolean {
    return !!this.scriptConfig.FEATURES && feature in this.scriptConfig.FEATURES
      ? this.scriptConfig.FEATURES[feature]
      : DEFAULT_FEATURES_CONFIG[feature];
  }

  public getCompositionMaxParticipants(): number {
    return FEATURES.COMPOSITION_MAX_PARTICIPANTS in this.scriptConfig.FEATURES
      ? Number(
          this.scriptConfig.FEATURES[FEATURES.COMPOSITION_MAX_PARTICIPANTS]
        )
      : DEFAULT_FEATURES_CONFIG[FEATURES.COMPOSITION_MAX_PARTICIPANTS];
  }

  public agentParamEnabled(param: AgentParam): boolean {
    return Boolean(this.agentOptions[param]);
  }

  public agentParam<P extends AgentParam>(
    param: P
  ): IApplicationAgentParams[P] {
    return this.agentOptions[param] ?? null;
  }

  public customerParamEnabled(param: CustomerParam): boolean {
    return Boolean(this.customerOptions[param]);
  }

  public customerParam(param: CustomerParam): string {
    return param in this.customerOptions && this.customerOptions[param] !== null
      ? String(this.customerOptions[param])
      : null;
  }

  public publicParam<K extends keyof IApplicationPublicParams>(
    param: K
  ): PublicOptions[K] {
    return param in this.publicOptions && this.publicOptions[param];
  }

  public serviceParamEnabled<K extends keyof IApplicationServiceParams>(
    param: K
  ): boolean {
    return param in this.serviceOptions
      ? Boolean(this.serviceOptions[param])
      : false;
  }

  public serviceParam(param): any {
    return param in this.serviceOptions && this.serviceOptions[param] !== null
      ? this.serviceOptions[param]
      : null;
  }

  /**
   * Recorder configured is a legacy property and is automatically updated
   * in settings once the storage provider is configured
   */
  public get isStorageConfiguredAgent() {
    return this.applicationConfig?.agentParameters?.[
      AgentParam.RECORDER_CONFIGURED
    ];
  }

  /**
   * Agent does not have access to serviceParams
   */
  public get isStorageConfiguredAdmin() {
    return (
      ((this.applicationConfig?.serviceParameters?.storageProvider !==
        ApplicationProviderTypeEnum.SFTP &&
        this.applicationConfig?.agentParameters?.recorderConfigured) ||
        this.applicationConfig?.serviceParameters?.storageProvider ===
          ApplicationProviderTypeEnum.SFTP) &&
      !!this.applicationConfig?.serviceParameters?.storageProvider
    );
  }
}
