import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Renderer2,
  ElementRef,
  AfterViewInit,
  signal,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CopyClipboardComponent } from "../../../modules/shared/components";

@Component({
  selector: "app-qr",
  templateUrl: "./qr.component.html",
  styleUrls: ["./qr.component.scss"],
  animations: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QRComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  animate: boolean;
  animateDestroy: any;

  @ViewChild("copyRef") copyRef: CopyClipboardComponent;
  // @ViewChild("focusRef") focusRef: ElementRef<HTMLInputElement>;
  @ViewChild("cardRef", {read: ElementRef} ) cardRef: ElementRef<HTMLDivElement>;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private host: ElementRef,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.animate = true;
    this.animateDestroy = setTimeout(() => {
      this.animate = false;
      this.cdr.detectChanges();
    }, 2400);

    // this.renderer.setAttribute(this.host.nativeElement, "role", "dialog");
    // this.renderer.setAttribute(this.host.nativeElement, "aria-modal", "true");
    // this.renderer.setAttribute(this.host.nativeElement, "tabindex", "0");
    // this.renderer.setAttribute(
    //   this.host.nativeElement,
    //   "aria-label",
    //   this.translate.instant("QR code")
    // );
  }

  ngAfterViewInit(): void {
    // this.copyRef.focus();
    // this.renderer.setAttribute(this.cardRef.nativeElement, "tabindex", "0");
    this.cardRef.nativeElement.focus();
    // setTimeout(() => this.cardRef.nativeElement.focus(), 200);
    // this.cardRef.nativeElement.focus();
  }

  ngOnDestroy(): void {
    if (!!this.animateDestroy) {
      clearTimeout(this.animateDestroy);
    }
  }

  cancel() {
    this.close.emit();
  }
}
