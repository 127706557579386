<form>
  <!-- <av-card class="search" [backdrop]="false"> -->
  <!-- <av-card-body size="xs" class="fx-row"> -->
  <av-icon name="magnify"></av-icon>
  <input
    [attr.aria-label]="'Search' | translate"
    class="search"
    avInput
    name="query-string"
    [(ngModel)]="query"
    (ngModelChange)="change($event)"
    type="search"
    placeholder="{{ 'type to search...' | translate }}"
  />
  <!-- </av-card-body> -->
  <!-- </av-card> -->
</form>
