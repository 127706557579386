import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { IInteraction, NotificationService } from "../../../../core-ui";
import { QRService } from "../../../services";
import { TranslateService } from "@ngx-translate/core";
import { IShareLinkAction } from "../share-link.component";
import {
  IMenuItemContainer,
  MENU_ITEM_CONTAINER_TOKEN,
  MenuItemComponent,
} from "../../../../modules/shared/components";

@Component({
  selector: "app-share-link-qr",
  templateUrl: "./qr.component.html",
  styleUrls: ["./qr.component.scss"],
  providers: [
    {
      provide: MENU_ITEM_CONTAINER_TOKEN,
      useExisting: ShareLinkQrComponent,
    },
  ],
})
export class ShareLinkQrComponent
  implements OnInit, IShareLinkAction, IMenuItemContainer
{
  @Input() interaction: IInteraction;
  @Input() link: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChildren(MenuItemComponent) menuItems: QueryList<MenuItemComponent>;

  constructor(
    private translate: TranslateService,
    private notification: NotificationService,
    private qrService: QRService
  ) {}

  ngOnInit(): void {}

  getMenuItems(): MenuItemComponent[] {
    return this.menuItems.toArray();
  }

  shareLink() {
    if (!this.thereIsACustomerWhichSupportsQRCodeLink) {
      return;
    }
    this.qrService.sendQRLinkEvent(this.link);
    this.notification.show("auvious", {
      body: this.translate.instant(
        "The customer link has been sent as a QR code."
      ),
    });
    // this.shareOpen = false;
    this.openChange.emit(false);

    // this.renewCustomerLink();
    this.completed.emit();
  }

  get thereIsACustomerWhichSupportsQRCodeLink(): boolean {
    return this.qrService.isThereACustomerWhichSupportsQRCodeLink();
  }
}
