import { Event } from "@auvious/common";
import { Injectable } from "@angular/core";
import { AuviousRtcService } from "./rtc.service";
import { NotificationService } from "./notification.service";
import {
  CompositionNotification,
  INotificationEvent,
  TranscriptNotification,
} from "../models";
import { RecorderService } from "./recorder.service";
import { firstValueFrom } from "rxjs";
import { TranscriptService } from "./transcript.service";
import { TranscriptStateEnum } from "../core-ui.enums";
import { AppointmentService } from "./appointment.service";
import { VisionService } from "./vision.service";

@Injectable()
export class EventService {
  constructor(
    private rtcService: AuviousRtcService,
    private notification: NotificationService,
    private recorderService: RecorderService,
    private asrService: TranscriptService,
    private appointmentService: AppointmentService,
    private visionService: VisionService
  ) {}

  public start() {
    firstValueFrom(this.rtcService.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable.subscribe(this.handleEvent.bind(this));
      }
    );
  }

  private eventToStatusMap = {
    ASROfflineTranscriptCreatedEvent: TranscriptStateEnum.completed,
    ASROfflineTranscriptProcessingEvent: TranscriptStateEnum.processing,
    ASROfflineTranscriptFailedEvent: TranscriptStateEnum.failed,
    ASROfflineTranslationCreatedEvent: TranscriptStateEnum.completed,
    ASROfflineTranslationProcessingEvent: TranscriptStateEnum.processing,
    ASROfflineTranslationFailedEvent: TranscriptStateEnum.failed,
    AIPromptCreatedEvent: TranscriptStateEnum.completed,
    AIPromptProcessingEvent: TranscriptStateEnum.processing,
    AIPromptFailedEvent: TranscriptStateEnum.failed,
    AIRecognitionCreatedEvent: TranscriptStateEnum.completed,
    AIRecognitionProcessingEvent: TranscriptStateEnum.processing,
    AIRecognitionFailedEvent: TranscriptStateEnum.failed,
  };

  private handleEvent(event: Event) {
    let notification: INotificationEvent;
    switch (event.payload.type) {
      case "CompositionStateChangedEvent":
        notification = new CompositionNotification(event);
        break;
      case "RecorderStateChangedEvent":
        this.recorderService.propagateEvent(event.payload);
        break;
      case "ASROfflineTranscriptCreatedEvent":
      case "ASROfflineTranscriptProcessingEvent":
      case "ASROfflineTranscriptFailedEvent":
        notification = new TranscriptNotification(
          event,
          this.eventToStatusMap[event.payload.type]
        );
        this.asrService.propagateEvent(event.payload);
        break;
      case "ASROfflineTranslationCreatedEvent":
      case "ASROfflineTranslationProcessingEvent":
      case "ASROfflineTranslationFailedEvent":
        notification = new TranscriptNotification(
          event,
          this.eventToStatusMap[event.payload.type],
          "translation"
        );
        this.asrService.propagateEvent(event.payload);
        break;
      case "AIPromptCreatedEvent":
      case "AIPromptProcessingEvent":
      case "AIPromptFailedEvent":
        notification = new TranscriptNotification(
          event,
          this.eventToStatusMap[event.payload.type],
          event.payload.intent
        );
        this.asrService.propagateEvent(event.payload);
        break;
      case "AIRecognitionCreatedEvent":
      case "AIRecognitionProcessingEvent":
      case "AIRecognitionFailedEvent":
        this.visionService.propagateEvent(event.payload);
        break;
      case "APScheduledEvent":
      case "APRescheduledEvent":
      case "APReassignedEvent":
      case "APUpdatedEvent":
      case "APInProgressEvent":
      case "APCompletedEvent":
      case "APExpiredEvent":
      case "APAgentJoinedEvent":
      case "APAgentLeftEvent":
      case "APCustomerWaitingEvent":
      case "APCustomerJoinedEvent":
      case "APCustomerLeftEvent":
      case "APDeletedEvent":
        this.appointmentService.propagateEvent(event.payload);
        break;
      // add more
    }
    if (!notification || !notification.canShow()) {
      return;
    }

    this.notification.show(notification.getTitle(), {
      body: notification.getBody(),
    });
  }
}
