import { ApiResource, PagedCollection } from "@auvious/common";
import {
  ITranscriptMessage,
  ITranscriptStatus,
  ITranscriptTransformStatus,
  ITranscriptTranslateStatus,
} from "../../interfaces";
import {
  IProviderModel,
  IProvisionLanguageOptions,
  ITranscriptStrategy,
  ITranscriptTransformOptions,
  ITranscriptTransformStrategy,
  ITranscriptTranslateOptions,
  ITranscriptTranslateStrategy,
} from "../ITranscriptStrategy";
import { AuviousRtcService, GenericErrorHandler } from "../../../services";
import {
  IProviderLanguage,
  ProviderOrganizationLanguage,
} from "../../ProviderLanguage";
import {
  IOrganizationLanguage,
  IProviderLanguages,
  OrganizationLanguage,
  SpeechToTextProviderType,
} from "@auvious/asr";
import {
  ApplicationProviderTypeEnum,
  TranscriptStateEnum,
  TranscriptTransformType,
} from "../../../core-ui.enums";

interface IGoogleTranscriptStatus {
  completed: string;
  created: string;
  id: string;
  languageCode: string;
  languageName: string;
  state: TranscriptStateEnum;
}

class GoogleTranscriptStatus implements ITranscriptStatus {
  constructor(private entity: IGoogleTranscriptStatus) {}

  get id() {
    return this.entity.id;
  }
  get state() {
    return this.entity.state;
  }
  get language() {
    return this.entity.languageCode;
  }
  get createdAt() {
    return new Date(this.entity.created);
  }
}

export class GoogleTranscriptStrategy
  implements
    ITranscriptStrategy,
    ITranscriptTransformStrategy,
    ITranscriptTranslateStrategy
{
  private offlineResource: ApiResource;

  // private providerMap: Record<
  //   ApplicationProviderTypeEnum,
  //   SpeechToTextProviderType
  // > = {
  //   [ApplicationProviderTypeEnum.GOOGLE_CLOUD]: SpeechToTextProviderType.GOOGLE,
  //   [ApplicationProviderTypeEnum.AMAZON_S3]: SpeechToTextProviderType.AMAZON,
  //   [ApplicationProviderTypeEnum.MICROSOFT_AZURE]:
  //     SpeechToTextProviderType.MICROSOFT,
  //   [ApplicationProviderTypeEnum.OPEN_AI]: SpeechToTextProviderType.OPEN_AI,
  //   // not used
  //   [ApplicationProviderTypeEnum.SFTP]: SpeechToTextProviderType.GOOGLE,
  // };

  constructor(private logger: GenericErrorHandler, rtc: AuviousRtcService) {
    rtc.common$.subscribe((c) => {
      this.offlineResource = c.apiResourceFactory("api/asr-offline");
    });
  }

  public createTranscriptRequest(
    conversationId: string,
    organizationLanguageId: string
  ): Promise<{ conversationId: string; id: string }> {
    return this.offlineResource
      .create(
        {
          conversationId,
          organizationLanguageId,
        },
        { urlPostfix: "/request" }
      )
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  async getTranscriptsForConversation(
    conversationId: string
  ): Promise<ITranscriptStatus[]> {
    try {
      const response = await this.getTranscriptStatusForConversation(
        conversationId
      );
      return [response];
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTranscriptStatusForConversation(
    conversationId: string
  ): Promise<ITranscriptStatus> {
    try {
      const response: IGoogleTranscriptStatus = await this.offlineResource.get({
        urlPostfix: "/conversation/" + conversationId,
      });
      return new GoogleTranscriptStatus(response);
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  public removeTranscript(conversationId: string, transcriptId: string) {
    return this.offlineResource
      .delete({
        urlPostfix: "/transcript/" + transcriptId,
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getTranscriptURL(
    conversationId: string,
    transcriptId: string,
    type: "inline" | "attachment"
  ): Promise<{ url: string; validUntil: string }> {
    return this.offlineResource
      .get({
        urlPostfix: `/player/${conversationId}/${transcriptId}/url/${type}`,
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public getTranscript(
    conversationId: string,
    transcriptId: string
  ): Promise<any> {
    return undefined;
  }

  /** languages */

  public async getProvisionedLanguages(
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IOrganizationLanguage>> {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("size", pageSize.toString());

    return new PagedCollection<OrganizationLanguage>(
      await this.offlineResource
        .get({
          params,
          urlPostfix: "/organization/language",
        })
        .catch((ex) => {
          this.logger.handleNotAuthenticatedError(ex);
          throw ex;
        })
    );
    // return this.offlineResource.get({
    //   urlPostfix: "/organization/language",
    // });
  }

  public provisionLanguage(
    language: IProviderLanguage,
    options: IProvisionLanguageOptions
  ): Promise<{ organizationLanguageId: string }> {
    return this.offlineResource
      .create(
        {
          applicationId: options.applicationId,
          languageCode: language.code,
          languageName: language.name,
          model: language.model,
          providerType: language.provider,
        },
        {
          urlPostfix: "/organization/language",
        }
      )
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public removeProvisionedLanguage(
    provider: ApplicationProviderTypeEnum,
    language: ProviderOrganizationLanguage
  ) {
    return this.offlineResource
      .delete({
        urlPostfix: "/language/" + language.id,
      })
      .catch((ex) => {
        this.logger.handleNotAuthenticatedError(ex);
        throw ex;
      });
  }

  public async getProviderLanguages(
    usage: "translations" | "transcriptions",
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IProviderLanguages>> {
    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("size", pageSize.toString());

    return new PagedCollection<IProviderLanguages>(
      await this.offlineResource
        .get({
          params,
          urlPostfix: SpeechToTextProviderType.GOOGLE + "/languages/available",
        })
        .catch((ex) => {
          this.logger.handleNotAuthenticatedError(ex);
          throw ex;
        })
    );
  }

  /** transformations */

  // currently we do not support transform operations
  isFeatureSupported(feature: TranscriptTransformType): boolean {
    return false;
  }

  public transformTranscriptForConversation(
    conversationId: string,
    transcriptId: string,
    options: ITranscriptTransformOptions
  ): Promise<any> {
    return;
  }

  async getTransformsForConversation(
    conversationId: string,
    transcriptId: string
  ): Promise<ITranscriptTransformStatus[]> {
    return [];
  }

  getTransformStatusForConversation(
    conversationId: string,
    transcriptId: string,
    transformId: string
  ): Promise<ITranscriptTransformStatus> {
    return undefined;
  }

  getTransformURL(
    conversationId: string,
    transcriptId: string,
    transfromId: string
  ): Promise<{ url: string; validUntil: string }> {
    return undefined;
  }

  public getTransformContent(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ) {
    return undefined;
  }

  removeTransform(
    conversationId: string,
    transcriptId: string,
    transformId: string
  ): Promise<void> {
    return undefined;
  }

  /** models */

  async getProviderModels(
    providerType: ApplicationProviderTypeEnum
  ): Promise<IProviderModel[]> {
    return [];
  }
  async getProvisionedModel(): Promise<IProviderModel[]> {
    return [];
  }
  provisionModel(
    provider: ApplicationProviderTypeEnum,
    model: IProviderModel
  ): Promise<{ organizationModelId: string }> {
    return;
  }

  removeProvisionedModel(
    provider: ApplicationProviderTypeEnum,
    model: IProviderModel
  ): Promise<void> {
    return;
  }

  translate(
    conversationId: string,
    transcriptId: string,
    options: ITranscriptTranslateOptions
  ): Promise<{ conversationId: string; id: string }> {
    return;
  }
  getTranslations(
    conversationId: string,
    transcriptId: string
  ): Promise<ITranscriptTranslateStatus[]> {
    return;
  }
  getTranslation(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ): Promise<ITranscriptTranslateStatus> {
    return;
  }
  removeTranslation(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ): Promise<void> {
    return;
  }
  getTranslationURL(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ): Promise<{ url: string; validUntil: string }> {
    return;
  }
  getTranslationContent(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ): Promise<ITranscriptMessage[]> {
    return;
  }
}
