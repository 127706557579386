<ng-container [ngSwitch]="state">
    <span
      *ngSwitchCase="stateSubmitted"
      [attr.aria-label]="'Export submitted' | translate"
      class="pill pill-warn"
      translate
      >submitted</span
    >
    <span
      *ngSwitchCase="stateQueued"
      [attr.aria-label]="'Export queued' | translate"
      class="pill pill-warn"
      translate
      >queued</span
    >
    <span
      *ngSwitchCase="stateProcessing"
      [attr.aria-label]="'Export processing' | translate"
      class="pill pill-success"
      translate
      >processing</span
    >
    <span
      *ngSwitchCase="stateCancelled"
      [attr.aria-label]="'Export cancelled' | translate"
      class="pill pill-danger"
      translate
      >cancelled</span
    >
    <span
      *ngSwitchCase="stateFailed"
      [attr.aria-label]="'Export failed' | translate"
      class="pill pill-danger"
      translate
      >failed</span
    >
  </ng-container>