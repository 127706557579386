import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  signal,
} from "@angular/core";
import { ActivityIndicatorService } from "../../services/activity-indicator.service";
import { fadeOut, zoomInOut } from "../../core-ui.animations";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-activity-indicator",
  templateUrl: "./activity-indicator.component.html",
  styleUrls: ["./activity-indicator.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [zoomInOut, fadeOut],
})
export class ActivityIndicatorComponent implements OnInit {
  visible = signal<boolean>(false);
  message = signal<string>(undefined);
  icon = signal<string>(undefined);
  cssClass = signal<string>(undefined);
  backdropVisible = signal<boolean>(false);

  constructor(
    private activity: ActivityIndicatorService,
    private host: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.activity.message$.subscribe((val) => {
      this.message.set(val);
      if (this.message())
        this.renderer.setAttribute(
          this.host.nativeElement,
          "aria-label",
          this.translate.instant(this.message())
        );
    });
    this.activity.isVisible$.subscribe((val) => {
      this.visible.set(val);
      if (this.visible()) {
        this.renderer.setAttribute(this.host.nativeElement, "role", "alert");
      } else {
        this.renderer.removeAttribute(this.host.nativeElement, "role");
        this.renderer.removeAttribute(this.host.nativeElement, "aria-label");
      }
    });
    this.activity.icon$.subscribe((val) => {
      this.icon.set(val.name);
      this.cssClass.set(val.cssClass);
    });
    this.activity.backdrop$.subscribe((val) => this.backdropVisible.set(val));
  }
}
