import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from "@angular/core";

@Component({
  selector: "av-card-body",
  templateUrl: "./card-body.component.html",
  styleUrls: ["./card-body.component.scss"],
})
export class CardBodyComponent implements OnInit {
  @Input() type: "spotlight" | "container" | "card" = "card";
  @Input() size: "md" | "sm" | "xs" = "md";

  classes = {};
  constructor(public host: ElementRef<HTMLDivElement>) {}

  ngOnInit(): void {}

  @HostBinding("class") get class() {
    return {
      "panel-body-spotlight": this.type === "spotlight",
      "panel-body-sm": this.size === "sm",
      "panel-body-xs": this.size == "xs",
      "panel-body-container": this.type === "container",
      "panel-body-card": this.type === "card",
    };
  }

  get nativeElement(): HTMLDivElement {
    return this.host.nativeElement;
  }
}
