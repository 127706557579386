import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild,
  Optional,
  ViewChildren,
  QueryList,
  signal,
  computed,
} from "@angular/core";
import {
  AgentParam,
  AppConfigService,
  IInteraction,
  IQueue,
} from "../../../../core-ui";
import { QueueService } from "../../../services";
import { IShareLinkAction } from "../share-link.component";
import { GenesysInvitationChannelEnum } from "../../../models";
import {
  IMenuItemContainer,
  MENU_ITEM_CONTAINER_TOKEN,
  MenuComponent,
  MenuItemComponent,
} from "../../../../modules/shared/components";

@Component({
  selector: "app-share-link-sms",
  templateUrl: "./sms.component.html",
  styleUrls: ["./sms.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MENU_ITEM_CONTAINER_TOKEN,
      useExisting: ShareLinkSmsComponent,
    },
  ],
})
export class ShareLinkSmsComponent
  implements OnInit, IShareLinkAction, IMenuItemContainer
{
  @Input() interaction: IInteraction;
  @Input() link: string;

  @Output() completed: EventEmitter<void> = new EventEmitter();
  @Output() openChange: EventEmitter<boolean> = new EventEmitter();
  @Output() confirm: EventEmitter<{
    channel: GenesysInvitationChannelEnum;
    queue?: IQueue;
    workflow?: string;
  }> = new EventEmitter();

  @ViewChildren(MenuItemComponent)
  menuItems: QueryList<MenuItemComponent>;

  smsQueues = signal<IQueue[]>([]);
  isSelectingSMSQueue = signal(false);
  SMSQueue: IQueue;
  phoneNumber: string;

  constructor(
    private queueService: QueueService,
    private cdr: ChangeDetectorRef,
    private config: AppConfigService,
    @Optional() private menu: MenuComponent
  ) {}

  async ngOnInit() {
    if (!this.isWorkflowEnabled) {
      this.smsQueues.set(await this.queueService.getSMSQueues());
      this.notifyMenuContainer();
    }
  }

  getMenuItems(): MenuItemComponent[] {
    return this.menuItems.toArray().filter((i) => i.type !== "header");
  }

  async shareLink() {
    this.isWorkflowEnabled ? this.shareUsingWorkflow() : this.shareUsingQueue();
  }

  expand() {
    if (!this.isWorkflowEnabled) {
      this.shareUsingQueue();
      this.notifyMenuContainer();
    }
  }

  collapse() {
    if (!this.isWorkflowEnabled) {
      this.isSelectingSMSQueue.set(false);
      this.notifyMenuContainer();
    }
  }

  private notifyMenuContainer() {
    this.cdr.detectChanges();
    this.menu?.notifyQueryListChanges();
  }

  async shareUsingWorkflow() {
    this.openChange.emit(false);
    this.confirm.emit({
      channel: GenesysInvitationChannelEnum.workflow,
      workflow: this.config.agentParam(AgentParam.SMS_WORKFLOW_ID),
    });
  }

  shareUsingQueue() {
    if (this.isSelectingSMSQueue()) {
      return this.isSelectingSMSQueue.set(false);
    }

    if (this.smsQueues().length > 1) {
      this.isSelectingSMSQueue.set(true);
    } else {
      this.SMSQueue = this.smsQueues()[0];
      // this.shareOpen = false;
      this.openChange.emit(false);

      // this.isPhoneNumberValidation = true;
      this.confirm.emit({
        channel: GenesysInvitationChannelEnum.queue,
        queue: this.SMSQueue,
      });
    }
  }

  smsQueueSelected(queue: IQueue) {
    this.SMSQueue = queue;
    // this.shareOpen = false;
    this.openChange.emit(false);
    this.isSelectingSMSQueue.set(false);

    // this.isPhoneNumberValidation = true;
    this.confirm.emit({
      channel: GenesysInvitationChannelEnum.queue,
      queue: this.SMSQueue,
    });
  }

  get isWorkflowEnabled() {
    return (
      this.config.agentParamEnabled(AgentParam.SMS_WORKFLOW_ENABLED) &&
      !!this.config.agentParam(AgentParam.SMS_WORKFLOW_ID)
    );
  }

  get isSMSAvailable() {
    return computed(() =>
      this.isWorkflowEnabled
        ? true
        : this.interaction.supportsSMS() && this.smsQueues().length > 0
    );
  }
}
