import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import {
  ApplicationService,
  UserService,
  IUser,
  UserRoleEnum,
  IUserDetails,
  ApplicationTypeEnum,
  slideFromLeft,
  ThemeService,
  AgentParam,
  ScheduleTypeEnum,
  CLAIM_OPEN_ID,
  MediaRulesService,
  AuviousRtcService,
  GenericErrorHandler,
  ConversationOriginEnum,
  KeyboardService,
  KeyCodes,
} from "../../../core-ui";
import { AuthState } from "../../../core-ui/models/AuthState";
import { AppConfigService } from "../../../core-ui/services/app.config.service";
import { IntegrationService, MenuService } from "../../services";

@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideFromLeft],
})
export class UserMenuComponent implements OnInit, OnDestroy {
  open = signal(false);
  user: IUser;
  details: IUserDetails;
  isInPageWithoutMenu = true;
  idOn = false;
  avatarError = false;
  isHelpOn = true;
  toggleColorMode: "light" | "dark" = "dark";
  subscription = new Subscription();

  isAgent = false;
  isCustomer = false;
  isSupervisor = false;
  isAdmin = false;
  isConnectionDown = false;
  isActivityEnabled = signal(false);

  @ViewChild("menu", { read: ElementRef }) menuRef: ElementRef<HTMLElement>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private userService: UserService,
    private themeService: ThemeService,
    private cd: ChangeDetectorRef,
    private config: AppConfigService,
    private menu: MenuService,
    private mediaRules: MediaRulesService,
    private rtcService: AuviousRtcService,
    private errorHandler: GenericErrorHandler,
    private integration: IntegrationService,
    private keyboard: KeyboardService
  ) {
    this.userService.userChanged$
      .pipe(filter((data) => !!data?.user))
      .subscribe(({ user, details }) => {
        this.user = user;
        this.details = details;
        if (user) {
          this.isAgent = user.hasRole(UserRoleEnum.agent);
          this.isCustomer = user.hasRole(UserRoleEnum.customer);
          this.isSupervisor = user.hasRole(UserRoleEnum.supervisor);
          this.isAdmin = user.hasRole(UserRoleEnum.admin);
          this.isActivityEnabled.set(this.isSupervisor);
        }
        /*
        this.isHelpOn = !(this.applicationService.getActiveApplication().getType() === ApplicationTypeEnum.Genesys && this.device.isIFrame);
        */
        this.cd.detectChanges();
      });

    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        const path = route.children?.[0]?.snapshot.url?.[0].path;
        // hide menu in case we are in a call
        this.isInPageWithoutMenu = [
          "a",
          "t",
          "thank-you",
          "error",
          "cobrowse",
        ].includes(path);
        this.cd.detectChanges();
      });

    effect(() => {
      if (this.open()) {
        requestAnimationFrame(this.onHomeKey);

        this.keyboard.listen(KeyCodes.Esc, this.onEscKey);
        this.keyboard.listen(KeyCodes.Home, this.onHomeKey);
        this.keyboard.listen(KeyCodes.End, this.onEndKey);
      } else {
        this.keyboard.unlisten(KeyCodes.Esc, this.onEscKey);
        this.keyboard.unlisten(KeyCodes.Home, this.onHomeKey);
        this.keyboard.unlisten(KeyCodes.End, this.onEndKey);
      }
    });
  }

  @HostBinding("class") get class() {
    return {
      open: this.open(),
      "talkdesk-menu": this.applicationService.isTalkdeskApp,
    };
  }

  @HostListener("click")
  click() {
    if (this.open()) {
      this.open.set(false);
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.errorHandler.connectionError$.subscribe((_) => {
        this.isConnectionDown = true;
        this.cd.detectChanges();
      })
    );
    this.subscription.add(
      this.rtcService.connectionRegistered$.subscribe((_) => {
        this.isConnectionDown = false;
        this.cd.detectChanges();
      })
    );
    this.subscription.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        this.toggleColorMode = theme?.darkMode ? "light" : "dark";
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  active(path) {
    return path === this.route.snapshot.children?.[0]?.url?.[0].path;
  }

  private onEscKey = async () => {
    await this.toggle();
    this.cd.detectChanges();
  };

  private onHomeKey = () => {
    (
      this.menuRef.nativeElement?.querySelector(
        "[tabindex]:first-child"
      ) as HTMLElement
    ).focus();
  };

  private onEndKey = () => {
    (
      this.menuRef.nativeElement?.querySelector(
        "[tabindex]:last-child"
      ) as HTMLElement
    ).focus();
  };

  async toggle(e?: Event) {
    e?.stopPropagation();
    this.open.set(!this.open());
  }

  async go(toPath) {
    try {
      await this.menu.checkForUnsavedChanges();

      // check if we have any leftover conversation ids in state (in case we came from a redirect in premise)
      const authState = AuthState.fromSerializedState(
        this.route.snapshot.queryParamMap.get("state")
      );

      const fromPath = this.route.children?.[0]?.snapshot.url?.[0].path;

      let queryParams = this.route.snapshot.queryParams;

      if (fromPath === "recordings" && !!authState?.conversationId) {
        // clear id
        authState.setConversationId(undefined);
        queryParams = {
          ...this.route.snapshot.queryParams,
          state: authState.serialize(),
        };
      } else if (fromPath === "interaction") {
        queryParams = {
          ...this.route.snapshot.queryParams,
          startAt: null,
          endAt: null,
          page: null,
        };
      }
      this.router.navigate(["/", toPath], {
        queryParams,
        queryParamsHandling: "merge",
      });
      this.open.set(false);
    } catch (ex) {
      // decided to stay in page due to unsaved changes
      this.open.set(false);
      this.cd.detectChanges();
    }
  }

  help() {
    window.open("https://docs.auvious.video", "_blank");
  }

  cancel(e: Event) {
    e.stopPropagation();
  }

  toggleId(e: Event) {
    e.stopPropagation();
    if (
      this.applicationService.getActiveApplication().getType() ===
      ApplicationTypeEnum.GenesysCloud
    ) {
      return;
    }
    this.idOn = !this.idOn;
  }

  get isToggleDisabled() {
    return this.isConnectionDown;
  }

  get isVisible() {
    return (
      !!this.user &&
      !this.isCustomer &&
      ((this.isAgent &&
        (!this.isSupervisor || !this.isAdmin) &&
        this.config.agentParamEnabled(AgentParam.MAIN_MENU_ENABLED)) ||
        this.isSupervisor ||
        this.isAdmin) &&
      !this.isInPageWithoutMenu &&
      !!this.applicationService.getActiveApplication()?.getId() &&
      (this.isHelpOn ||
        this.isRecordingEnabled ||
        this.isInteractionsEnabled ||
        this.isConfigEnabled ||
        this.isMetricsEnabled)
    );
  }

  get id() {
    return this.user?.getClaim(CLAIM_OPEN_ID);
  }

  get roles() {
    return this.user?.getRoles();
  }

  get displayName() {
    return this.details?.name;
  }

  get isApplicationConfigured() {
    return !!this.applicationService.getActiveApplication()?.getId();
  }

  get isConfigEnabled() {
    return this.isAdmin;
  }

  get isRecordingEnabled() {
    return (
      this.isSupervisor || (this.isAgent && this.mediaRules.canExportRecording)
    );
  }

  get isInteractionsEnabled() {
    return this.isSupervisor || this.isAgent;
  }

  get isAuditEnabled() {
    return this.isSupervisor || this.isAdmin;
  }

  get isMetricsEnabled() {
    return this.isSupervisor || this.isAdmin;
  }

  get isScheduleEnabled() {
    return (
      (this.isAgent || this.isAdmin || this.isSupervisor) &&
      this.integration.supportsInteractionOrigin(
        ConversationOriginEnum.APPOINTMENT
      ) &&
      this.config.agentParamEnabled(AgentParam.SCHEDULE_ENABLED) &&
      this.config.agentParam(AgentParam.SCHEDULE_CHANNEL) ===
        ScheduleTypeEnum.appointment
    );
  }
  get avatar() {
    return this.details?.avatarUrl;
  }
}
