import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import {
  AgentParam,
  IComposition,
  IRecordingComposition,
} from "../../../../core-ui/models";
import { ActivityIndicatorService } from "../../../../core-ui/services/activity-indicator.service";
import { AuviousRtcService } from "../../../../core-ui/services/rtc.service";
import { CompositionService } from "../../../../core-ui/services/composition.service";
import { debugError } from "../../../../core-ui/services/utils";
import {
  CompositionTypeEnum,
  CompositionStateEnum,
  CompositionAudioFormatEnum,
  CompositionVideoFormatEnum,
} from "../../../../core-ui/core-ui.enums";
import { Event } from "@auvious/common";
import moment from "moment";
import { firstValueFrom, Subscription } from "rxjs";
import { NotificationService } from "../../../../core-ui/services/notification.service";
import {
  AppConfigService,
  MediaRulesService,
} from "../../../../core-ui/services";
import { UserService } from "../../../../core-ui/services/user.service";
import { extractErrorMessage } from "../../../../core-ui/services/utils";

@Component({
  selector: "app-composition-recording",
  templateUrl: "./composition-recording.component.html",
  styleUrls: ["./composition-recording.component.scss"],
})
export class CompositionRecordingComponent implements OnInit, OnDestroy {
  @Input()
  set recording(value: IRecordingComposition) {
    this._recording = value;

    this.compositionType = this.isAlreadyExportedVideo
      ? CompositionTypeEnum.audio
      : CompositionTypeEnum.video;

    // we do not support composition above X number of participants due to cost limitations
    this.isMaxParticipantsReached =
      value.recordingMetadata.totalParticipants >
      this.config.getCompositionMaxParticipants();
  }

  get recording() {
    return this._recording;
  }

  compositionType: CompositionTypeEnum = CompositionTypeEnum.audio;
  compositionName: string;
  compositionVideoFormat: CompositionVideoFormatEnum =
    CompositionVideoFormatEnum.mp4;
  compositionAudioFormat: CompositionAudioFormatEnum =
    CompositionAudioFormatEnum.mp3;
  subscriptions: Subscription = new Subscription();
  lang: string;
  private _recording: IRecordingComposition;

  isMaxParticipantsReached = false;
  translateParam;

  constructor(
    private compositionService: CompositionService,
    private activityService: ActivityIndicatorService,
    private alertService: NotificationService,
    private cd: ChangeDetectorRef,
    private rtc: AuviousRtcService,
    private config: AppConfigService,
    private user: UserService,
    private mediaRules: MediaRulesService
  ) {
    this.lang = config.language;
    firstValueFrom(this.rtc.getEventObservableAvailable()).then(
      (eventObservable) => {
        this.subscriptions.add(
          eventObservable.subscribe(this.handleEvent.bind(this))
        );
      }
    );
  }

  ngOnInit(): void {
    this.proposeName();
    this.translateParam = {
      value: this.config.getCompositionMaxParticipants(),
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async handleEvent(event: Event) {
    if (
      !event.payload ||
      event.payload.type !== "CompositionStateChangedEvent"
    ) {
      return;
    }
    // if we have made a search
    if (!this.recording) {
      return;
    }
    this.getRecording(this.recording.recordingMetadata.conversationId);
    this.cd.detectChanges();
  }

  requestExport(form) {
    if (form.valid) {
      this.export(
        this.recording.recordingMetadata.conversationId,
        this.compositionName,
        this.compositionType,
        this.compositionVideoFormat,
        this.compositionAudioFormat
      );
    }
  }

  private async export(
    conversationId: string,
    name: string,
    type: CompositionTypeEnum,
    videoFormat: CompositionVideoFormatEnum,
    audioFormat: CompositionAudioFormatEnum
  ) {
    try {
      this.activityService.loading(true);
      const request = await this.compositionService.requestComposition(
        conversationId,
        name,
        type,
        videoFormat,
        audioFormat
      );
      // get updated result
      await this.getRecording(request.conversationId);
    } catch (ex) {
      let message = ex.message || ex;
      if (ex.data && ex.data.code === "404") {
        message =
          "Another export request for this recording is already in process.";
      }
      this.alertService.error(message, { ttl: 3000 });
      debugError(ex);
    } finally {
      this.activityService.loading(false);
      this.cd.markForCheck();
    }
  }

  retryExport(composition: IComposition) {
    this.export(
      composition.conversationId,
      composition.name,
      composition.type,
      composition.videoFormat,
      composition.audioFormat
    );
  }

  async cancelExport(composition: IComposition) {
    try {
      this.activityService.loading(true);
      await this.compositionService.cancelComposition(
        composition.conversationId,
        composition.id
      );
      this.getRecording(composition.conversationId);
    } catch (ex) {
      this.alertService.error(ex.message || ex);
    } finally {
      this.activityService.loading(false);
    }
  }

  removed(composition: IComposition) {
    // get updated result
    this.getRecording(composition.conversationId);
  }

  private async getRecording(conversationId) {
    try {
      this.recording = await this.compositionService.findRecording(
        conversationId
      );
      this.proposeName();
      // check if we already have previous exports
      if (this.isAlreadyExported) {
        return;
      }
      this.compositionType = CompositionTypeEnum.video;
      if (this.isAlreadyExportedVideo) {
        this.compositionType = CompositionTypeEnum.audio;
      }
    } catch (ex) {
      if (
        ex.response &&
        (ex.response.status === 401 || ex.response.status === 404)
      ) {
        this.recording = null;
        // no recording found for this conversation
      } else {
        this.alertService.error(ex.message || ex);
      }
      debugError(ex);
    } finally {
      this.activityService.loading(false);
      this.cd.markForCheck();
    }
  }

  getDuration(d) {
    // return moment.duration(d).humanize();
    const duration = moment.duration(d);
    return (
      (duration.hours() > 0 ? `${duration.hours()}h ` : "") +
      `${duration.minutes()}m ${duration.seconds()}s`
    );
  }

  toggleExportType() {
    this.compositionType =
      this.compositionType === CompositionTypeEnum.audio
        ? CompositionTypeEnum.video
        : CompositionTypeEnum.audio;
  }

  trackByFn(index: number, el: IComposition) {
    return el.id + el.state;
  }

  proposeName() {
    const now = moment();
    this.compositionName = this.config
      .agentParam(AgentParam.COMPOSITION_DEFAULT_FILE_NAME)
      .replace(/{{id}}/g, this.recording?.recordingMetadata?.conversationId)
      .replace(/{{mediaType}}/g, this.compositionType?.toLowerCase())
      .replace(/{{date}}/g, now.format("yyyy-MM-DD"))
      .replace(/{{time}}/g, now.format("HHmm"));
  }

  compositionTypeChange() {
    this.proposeName();
  }

  async reload() {
    this.activityService.loading(true);
    try {
      this.recording = await this.compositionService.findRecording(
        this.recording.recordingMetadata.conversationId
      );
    } catch (ex) {
      this.alertService.error(extractErrorMessage(ex), { ttl: 3000 });
    } finally {
      this.activityService.loading(false);
    }
  }

  /* view getters */

  get isExportAudio() {
    return this.compositionType === CompositionTypeEnum.audio;
  }

  get isExportVideo() {
    return this.compositionType === CompositionTypeEnum.video;
  }

  get canExport(): boolean {
    return (
      !!this.recording &&
      (this.recording.recordingMetadata.audio ||
        this.recording.recordingMetadata.video) &&
      this.mediaRules.canExportRecording
    );
  }

  get isUserAgentOnly(): boolean {
    return this.user.isAgent && !this.user.isSupervisor && !this.user.isAdmin;
  }

  get compositionsAvailable(): boolean {
    return (
      !!this.recording &&
      this.recording.compositions &&
      this.recording.compositions.length > 0
    );
  }

  get isAlreadyExportedAudio(): boolean {
    return (
      (this.compositionsAvailable &&
        this.recording.compositions.filter(
          (c) =>
            c.type === CompositionTypeEnum.audio &&
            c.state === CompositionStateEnum.completed
        ).length > 0) ||
      !this.recording.recordingMetadata.canExportAudio
    );
  }

  get isAlreadyExportedVideo(): boolean {
    return (
      (!!this.recording && !this.recording.recordingMetadata.canExportVideo) || // we may have audio only recording
      (this.compositionsAvailable &&
        this.recording.compositions.filter(
          (c) =>
            c.type === CompositionTypeEnum.video &&
            c.state === CompositionStateEnum.completed
        ).length > 0)
    );
  }

  private get hasPendingExports(): boolean {
    return (
      this.compositionsAvailable &&
      this.recording.compositions.filter(
        (c) =>
          c.state === CompositionStateEnum.processing ||
          c.state === CompositionStateEnum.queued
      ).length > 0
    );
  }

  get isAlreadyExported(): boolean {
    return this.isAlreadyExportedVideo && this.isAlreadyExportedAudio;
  }
}
