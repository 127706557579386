import { AbstractBaseInvitationStrategy } from "../AbstractBaseInvitationStrategy";
import {
  PARAM_CONVERSATION_ID,
  PARAM_GENESYS_ENV,
} from "../../../../app/app.enums";

import {
  IApplicationAgentParams,
  IInteraction,
  ITicketRequest,
} from "../../../../core-ui/models";
import { TicketTypeEnum } from "../../../../core-ui/models/ITicket";
import { IGenesysCloudTicketAppointmentOptions } from "../../interfaces";
import {
  IApplicationPublicParams,
  PublicParam,
} from "../../../../core-ui/models/application-options/PublicOptions";

export class GenesysCloudInvitationStrategy extends AbstractBaseInvitationStrategy {
  constructor(
    protected applicationId: string,
    protected publicOptions: IApplicationPublicParams,
    protected agentOptions: IApplicationAgentParams,
    private pcEnvironment: string
  ) {
    super(applicationId, publicOptions, agentOptions);
  }

  /**
   * @deprecated The backend will create the ticket
   */
  prepareAppointmentTicketRequest(
    agentUserId: string,
    options?: IGenesysCloudTicketAppointmentOptions
  ): ITicketRequest {
    return {
      type: TicketTypeEnum.ScheduleTicket,
      properties: {
        organizationId: options.organizationId,
        application_id: this.applicationId,
        conference_id: null,
        customer_id: null,
        theme: this.publicOptions[PublicParam.THEME],
        // genesys cloud specific
        pcEnvironment: this.pcEnvironment,
      },
    };
  }

  customParams(interaction: IInteraction): URLSearchParams {
    const retval = new URLSearchParams();
    retval.set(PARAM_GENESYS_ENV, this.pcEnvironment);
    retval.set(PARAM_CONVERSATION_ID, interaction.getId());
    return retval;
  }
}
