import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

import { ErrorPageCode } from "../../app.enums";
import { ApplicationFactory } from "../../factories/ApplicationFactory";
import { GenericErrorHandler } from "../../services/error-handlers.service";

import {
  ActivityIndicatorService,
  ConversationDestinationEnum,
  DeviceService,
  ThemeService,
} from "../../../core-ui";
import { ApplicationService } from "../../../core-ui/services/application.service";
import { AuthState } from "../../../core-ui/models/AuthState";
import { discoverApplicationId } from "../../app.utils";

@Injectable()
export class AppAgentGuard {
  constructor(
    private applicationService: ApplicationService,
    private applicationFactory: ApplicationFactory,
    private themeService: ThemeService,
    private floatingService: ActivityIndicatorService,
    private errorHandler: GenericErrorHandler,
    private deviceService: DeviceService,
    private router: Router
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (this.applicationService.getActiveApplication()) {
      return true;
    }

    try {
      this.floatingService.loading(true, "", true); // "initializing application"
      const applicationId = discoverApplicationId(route.queryParamMap);

      if (
        !applicationId &&
        route.url[0].path !== "apps" &&
        route.queryParamMap.has("state")
      ) {
        const astate = AuthState.fromSerializedState(
          route.queryParamMap.get("state")
        );
        // redirect to applications page. The agent will select an integration in order to get the
        // application id and the continue with the flow.
        if (
          astate?.conversationDestination ===
          ConversationDestinationEnum.MOBILE_OFFICE
        ) {
          return this.router.createUrlTree(["/apps"], {
            queryParams: route.queryParams,
          });
        }
      }

      const application = await this.applicationFactory.createAgentApplication(
        applicationId,
        route
      );
      this.applicationService.setActiveApplication(application);
      this.themeService.parseApplicationOptions(application);
      this.deviceService.init();

      return true;
    } catch (error) {
      this.errorHandler.handleError(error);
      return this.router.createUrlTree([
        "/error",
        ErrorPageCode.AUTHENTICATION_FAILURE,
      ]);
    } finally {
      this.floatingService.loading(false);
    }
  }
}
