import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRouteSnapshot } from "@angular/router";
import { IJwtTokenResponse } from "@auvious/common";

import { AuviousRtcService } from "../../../services";
import { IAuthenticationStrategy } from "../IAuthenticationStrategy";
import { IUser } from "../../IUser";
import { User } from "../../User";
import { firstValueFrom } from "rxjs";

export class TestAuthenticationStrategy implements IAuthenticationStrategy {
  constructor(
    private rtcService: AuviousRtcService,
    private httpClient: HttpClient,
    private clientId: string
  ) {}

  async authenticate(route: ActivatedRouteSnapshot): Promise<IUser> {
    const common = await this.rtcService.createAuviousCommonClient();
    const userToken = await common.loginTemplate(
      () => {
        const params = new URLSearchParams();
        params.set("client_id", this.clientId);
        params.set("grant_type", "password");
        params.set("username", route.queryParams.agentUser);
        params.set("password", route.queryParams.agentPass);

        const options = {
          headers: new HttpHeaders().set(
            "Content-Type",
            "application/x-www-form-urlencoded"
          ),
        };

        return firstValueFrom(
          this.httpClient.post<IJwtTokenResponse>(
            "/security/oauth/token",
            params.toString(),
            options
          )
        );
      },
      (refreshToken) => {
        const params = new URLSearchParams();
        params.set("client_id", this.clientId);
        params.set("grant_type", "refresh_token");
        params.set("refresh_token", refreshToken);

        const options = {
          headers: new HttpHeaders().set(
            "Content-Type",
            "application/x-www-form-urlencoded"
          ),
        };

        return firstValueFrom(
          this.httpClient.post<IJwtTokenResponse>(
            "/security/oauth/token",
            params.toString(),
            options
          )
        );
      }
    );

    return new User(userToken);
  }
}
