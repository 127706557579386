<av-card-row-responsive [icon]="icon">
  <ng-container card-row-title>{{ title }}</ng-container>
  <ng-container card-row-subtitle>
    {{ transform.createdAt | amDateFormat : "MMM D y, h:mm a" }}
  </ng-container>
  <ng-container card-row-actions>
    <ng-container *ngIf="!isCompleted">
      <!-- <av-spinner size="xs" *ngIf="!isCompleted"></av-spinner> -->
      <app-composition-state
        [state]="transform.state.toUpperCase()"
      ></app-composition-state>
    </ng-container>

    <ng-container *ngIf="isCompleted">
      <ng-container *ngIf="canRequestFormattedTranscript">
        <av-tooltip message="Content">
          <button
            [attr.aria-label]="'Content' | translate"
            avButton
            size="xs"
            ofType="icon"
            color="link"
            (click)="toggleContent()"
            [loading]="isLoadingContent()"
            [disabled]="isLoadingContent()"
          >
            <av-icon
              [class.expanded]="isContentExpanded()"
              size="sm"
              name="chevron-down"
            ></av-icon>
          </button>
        </av-tooltip>
        <div class="separator"></div>
      </ng-container>

      <av-confirm
        [open]="confirmRemove()"
        (confirmed)="removeTransform()"
        (canceled)="confirmRemove.set(false)"
        arrow="bottom-right"
      >
        <button
          avButton
          ofType="icon"
          color="danger"
          size="xxs"
          [loading]="isRemoveLoading()"
          (click)="confirmRemove.set(true)"
        >
          <av-icon size="xs" name="trash"></av-icon>
        </button>
        <span confirmTitle translate>Are you sure?</span>
        <div confirmBody>
          <!-- {{
          "You can only export one language per recording. The existing transcript will be removed."
            | translate
        }} -->
        </div>
      </av-confirm>

      <av-tooltip
        message="Get download link"
        *ngIf="!canRequestFormattedTranscript && !url()"
      >
        <button
          [attr.aria-label]="'Get download link' | translate"
          avButton
          ofType="icon"
          color="link"
          size="xs"
          data-tid="composition/download"
          [loading]="isLoadingURL()"
          (click)="getURL()"
        >
          <av-icon size="sm" name="link"></av-icon>
        </button>
      </av-tooltip>

      <ng-container *ngIf="canRequestFormattedTranscript">
        <av-tooltip message="Download">
          <button
            [attr.aria-label]="'Download' | translate"
            avButton
            size="xs"
            ofType="icon"
            color="link"
            (click)="download()"
          >
            <av-icon size="sm" name="document-download"></av-icon>
          </button>
        </av-tooltip>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!!url()">
      <av-tooltip message="Download">
        <a
          [attr.aria-label]="'Download' | translate"
          avButton
          size="xs"
          ofType="icon"
          color="link"
          [href]="url()"
          download="Sentiment"
          target="_blank"
        >
          <av-icon size="sm" name="document-download"></av-icon>
        </a>
      </av-tooltip>
      <av-copy-clipboard [url]="url()" size="xs"></av-copy-clipboard>
    </ng-container>
  </ng-container>
</av-card-row-responsive>

<av-card-body class="content" *ngIf="isContentExpanded()" type="spotlight">
  <av-copy-clipboard
    size="xs"
    [tooltipLabel]="'Copy to clipboard' | translate"
    [url]="copyData()"
  ></av-copy-clipboard>

  <app-composition-transcript-content
    *ngIf="isTranslate"
    [data]="translateData()"
  ></app-composition-transcript-content>

  <div
    *ngIf="!isTranslate"
    [innerHTML]="transformData()?.result | markdown"
  ></div>
</av-card-body>
