import { IGenesysWebMessagingConfiguration } from "@auvious/integrations";
import {
  SQ_VIDEO_BITRATE_INDEX,
  VIDEO_BITRATE_OPTIONS,
  RoomModeratorEnum,
} from "../../core-ui.enums";
import { IThemeOptions, ThemeOptions } from "./ThemeOptions";
import { ISurvey } from "../IRating";

export interface IApplicationTalkdeskPublicParams {
  "root-url"?: string;
  // contains talkdesk header script url
  "widget-url"?: string;
}

export interface IAutomaticBitrateAdaptationPublisherPublicParams {
  publisher?: boolean;
  minSecondsBetweenDecrements?: number;
  minSecondsBetweenIncrements?: number;
}

export interface IAutomaticBitrateAdaptationViewerPublicParams {
  viewer?: boolean;
  firstReducePublisherVideoBitrate?: number;
  minSecondsBeforeStoppingVideo?: number;
  minSecondsBeforeStartingVideo?: number;
}

export const DEFAULT_ABR_PUBLISHER: IAutomaticBitrateAdaptationPublisherPublicParams =
  {
    publisher: false,
    minSecondsBetweenDecrements: 5,
    minSecondsBetweenIncrements: 15,
  };

export const DEFAULT_ABR_VIEWER: IAutomaticBitrateAdaptationViewerPublicParams =
  {
    viewer: false,
    firstReducePublisherVideoBitrate: 5,
    minSecondsBeforeStoppingVideo: 5,
    minSecondsBeforeStartingVideo: 15,
  };

// eslint-disable-next-line no-shadow
export enum PublicParam {
  THEME = "theme",
  VIDEO_SELFIE_QUALITY = "videoSelfieQuality",
  VIDEO_ENVIRONMENT_QUALITY = "videoEnvironmentQuality",
  VIDEO_SCREEN_SHARE_QUALITY = "videoScreenShareQuality",
  AGENT_PORTRAIT_MODE = "agentPortraitMode",
  ASR_AUTO_START = "asrAutoStart",
  ASR_ENABLED = "asrEnabled",
  ASR_VISIBLE_FOR_AGENT = "asrVisibleForAgent",
  ASR_VISIBLE_FOR_CUSTOMER = "asrVisibleForCustomer",
  ASR_DEFAULT_LANGUAGE = "asrDefaultLanguage",
  ASR_MODERATOR = "asrModerator",
  ASR_PROVIDERS_CONFIGURED = "asrProvidersConfigured",
  TALKDESK = "talkdesk",
  ASR_TRANSLATION_MODERATOR = "asrTranslationModerator",
  ASR_TRANSLATION_PROVIDER_CONFIGURED = "asrTranslationProviderConfigured",
  CUSTOM_DOMAIN_URL = "customDomainURL",
  AUTOMATIC_BITRATE_ADAPTATION_PUBLISHER = "automaticBitrateAdaptationPublisher",
  AUTOMATIC_BITRATE_ADAPTATION_VIEWER = "automaticBitrateAdaptationViewer",
  LAYOUT_SPOTLIGHT_HIDE_OTHER_PARTICIPANTS = "layoutSpotlightHideOtherParticipants",
  RECORDER_ENABLED = "recorderEnabled",
  ACCESS_TOKEN_AGENT_SESSION_STORE_ENABLED = "accessTokenAgentSessionStoreEnabled",
  ACCESS_TOKEN_CUSTOMER_SESSION_STORE_ENABLED = "accessTokenCustomerSessionStoreEnabled",
  HD_VIDEO_ENABLED = "hdVideoEnabled",
  DISCLAIMER_SNAPSHOT_ENABLED = "disclaimerSnapshotEnabled",
  SNAPSHOT_GEOLOCATION_ENABLED = "snapshotGeolocationEnabled",
  AGENT_NAME_ENABLED = "agentNameEnabled",
  AGENT_NAME_MASK = "agentNameMask",
  AGENT_AVATAR_MASK = "agentAvatarMask",
  SOUND_NOTIFICATIONS_PARTICIPANT_ENABLED = "soundNotificationsParticipantEnabled",
  GENESYS_WEB_MESSAGING_CONFIG = "genesysWebMessagingConfig",
  POPUP_AUTHENTICATION_ENABLED = "popupAuthenticationEnabled",
  CUSTOMER_METADATA_ENABLED = "customerMetadataEnabled",
  CUSTOMER_METADATA_BLACKLIST = "customerMetadataBlacklist",
  INTERACTION_DATA_VISIBLE = "interactionDataVisible",
  FILE_TRANSFER_PARTICIPANT_PICKER_ENABLED = "fileTransferParticipantPickerEnabled",
  TICKET_GROUPS_ENABLED = "ticketGroupsEnabled",
  TICKET_LENGTH = "ticketLength",
  ALLOWED_FILE_TYPES = "allowedFileTypes",
  SURVEYS = "surveys",
  SURVEYS_ENABLED = "surveysEnabled",
}

export interface IApplicationPublicParams {
  [PublicParam.THEME]?: IThemeOptions;
  [PublicParam.VIDEO_SELFIE_QUALITY]?: number;
  [PublicParam.VIDEO_ENVIRONMENT_QUALITY]?: number;
  [PublicParam.VIDEO_SCREEN_SHARE_QUALITY]?: number;
  [PublicParam.AGENT_PORTRAIT_MODE]?: boolean;
  [PublicParam.ASR_AUTO_START]?: boolean;
  [PublicParam.ASR_ENABLED]?: boolean;
  [PublicParam.ASR_VISIBLE_FOR_AGENT]?: boolean;
  [PublicParam.ASR_VISIBLE_FOR_CUSTOMER]?: boolean;
  [PublicParam.ASR_DEFAULT_LANGUAGE]?: boolean;
  [PublicParam.ASR_MODERATOR]?: RoomModeratorEnum;
  [PublicParam.ASR_PROVIDERS_CONFIGURED]?: boolean;
  [PublicParam.TALKDESK]?: IApplicationTalkdeskPublicParams;
  [PublicParam.ASR_TRANSLATION_MODERATOR]?: RoomModeratorEnum;
  [PublicParam.ASR_TRANSLATION_PROVIDER_CONFIGURED]?: boolean;
  [PublicParam.CUSTOM_DOMAIN_URL]?: string;
  [PublicParam.AUTOMATIC_BITRATE_ADAPTATION_PUBLISHER]?: IAutomaticBitrateAdaptationPublisherPublicParams;
  [PublicParam.AUTOMATIC_BITRATE_ADAPTATION_VIEWER]?: IAutomaticBitrateAdaptationViewerPublicParams;
  [PublicParam.LAYOUT_SPOTLIGHT_HIDE_OTHER_PARTICIPANTS]?: boolean;
  [PublicParam.RECORDER_ENABLED]?: boolean;
  [PublicParam.ACCESS_TOKEN_AGENT_SESSION_STORE_ENABLED]?: boolean;
  [PublicParam.ACCESS_TOKEN_CUSTOMER_SESSION_STORE_ENABLED]?: boolean;
  [PublicParam.HD_VIDEO_ENABLED]?: boolean;
  [PublicParam.DISCLAIMER_SNAPSHOT_ENABLED]?: boolean;
  [PublicParam.SNAPSHOT_GEOLOCATION_ENABLED]?: boolean;
  [PublicParam.AGENT_NAME_ENABLED]?: boolean;
  [PublicParam.AGENT_NAME_MASK]?: string;
  [PublicParam.AGENT_AVATAR_MASK]?: string;
  [PublicParam.SOUND_NOTIFICATIONS_PARTICIPANT_ENABLED]?: boolean;
  [PublicParam.GENESYS_WEB_MESSAGING_CONFIG]?: IGenesysWebMessagingConfiguration;
  [PublicParam.POPUP_AUTHENTICATION_ENABLED]?: boolean;
  [PublicParam.CUSTOMER_METADATA_ENABLED]?: boolean;
  [PublicParam.CUSTOMER_METADATA_BLACKLIST]?: string[];
  [PublicParam.INTERACTION_DATA_VISIBLE]?: boolean;
  [PublicParam.FILE_TRANSFER_PARTICIPANT_PICKER_ENABLED]?: boolean;
  [PublicParam.TICKET_GROUPS_ENABLED]?: boolean;
  [PublicParam.TICKET_LENGTH]?: number;
  [PublicParam.ALLOWED_FILE_TYPES]?: string[];
  [PublicParam.SURVEYS]?: ISurvey[];
  [PublicParam.SURVEYS_ENABLED]?: boolean;
}

export class PublicOptions implements IApplicationPublicParams {
  public [PublicParam.THEME] = ThemeOptions.create({});
  public [PublicParam.VIDEO_SELFIE_QUALITY] =
    VIDEO_BITRATE_OPTIONS[SQ_VIDEO_BITRATE_INDEX].value;
  public [PublicParam.VIDEO_ENVIRONMENT_QUALITY] =
    VIDEO_BITRATE_OPTIONS[SQ_VIDEO_BITRATE_INDEX].value;
  public [PublicParam.VIDEO_SCREEN_SHARE_QUALITY] =
    VIDEO_BITRATE_OPTIONS[SQ_VIDEO_BITRATE_INDEX].value;
  public [PublicParam.AGENT_PORTRAIT_MODE] = false;
  public [PublicParam.ASR_AUTO_START] = false;
  public [PublicParam.ASR_ENABLED] = false;
  public [PublicParam.ASR_VISIBLE_FOR_AGENT] = false;
  public [PublicParam.ASR_VISIBLE_FOR_CUSTOMER] = false;
  public [PublicParam.ASR_DEFAULT_LANGUAGE] = null;
  public [PublicParam.ASR_MODERATOR] = RoomModeratorEnum.agent;
  public [PublicParam.ASR_PROVIDERS_CONFIGURED] = false;
  public [PublicParam.TALKDESK] = {};
  public [PublicParam.ASR_TRANSLATION_MODERATOR] = RoomModeratorEnum.agent;
  public [PublicParam.ASR_TRANSLATION_PROVIDER_CONFIGURED] = false;
  public [PublicParam.CUSTOM_DOMAIN_URL] = null;
  public [PublicParam.AUTOMATIC_BITRATE_ADAPTATION_PUBLISHER] = {
    ...DEFAULT_ABR_PUBLISHER,
  };
  public [PublicParam.AUTOMATIC_BITRATE_ADAPTATION_VIEWER] = {
    ...DEFAULT_ABR_VIEWER,
  };
  public [PublicParam.LAYOUT_SPOTLIGHT_HIDE_OTHER_PARTICIPANTS] = false;
  public [PublicParam.RECORDER_ENABLED] = true;
  public [PublicParam.ACCESS_TOKEN_AGENT_SESSION_STORE_ENABLED] = false;
  public [PublicParam.ACCESS_TOKEN_CUSTOMER_SESSION_STORE_ENABLED] = true;
  public [PublicParam.HD_VIDEO_ENABLED] = true;
  public [PublicParam.DISCLAIMER_SNAPSHOT_ENABLED] = false;
  public [PublicParam.SNAPSHOT_GEOLOCATION_ENABLED] = false;
  public [PublicParam.AGENT_NAME_ENABLED] = true;
  public [PublicParam.AGENT_NAME_MASK] = null;
  public [PublicParam.AGENT_AVATAR_MASK] = null;
  public [PublicParam.SOUND_NOTIFICATIONS_PARTICIPANT_ENABLED] = false;
  public [PublicParam.GENESYS_WEB_MESSAGING_CONFIG] = null;
  public [PublicParam.POPUP_AUTHENTICATION_ENABLED] = false;
  public [PublicParam.CUSTOMER_METADATA_ENABLED] = true;
  public [PublicParam.CUSTOMER_METADATA_BLACKLIST] = [];
  public [PublicParam.INTERACTION_DATA_VISIBLE] = false;
  public [PublicParam.FILE_TRANSFER_PARTICIPANT_PICKER_ENABLED] = false;
  public [PublicParam.TICKET_GROUPS_ENABLED] = true;
  public [PublicParam.TICKET_LENGTH] = 6;
  public [PublicParam.ALLOWED_FILE_TYPES] = [];
  public [PublicParam.SURVEYS] = [];
  public [PublicParam.SURVEYS_ENABLED] = false;

  static create(options: IApplicationPublicParams) {
    let instance = new PublicOptions();
    instance = {
      ...instance,
      ...options,
      theme: {
        ...ThemeOptions.create(options?.theme),
        ...options?.theme,
      },
    };
    return instance;
  }
}
