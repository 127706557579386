import { Directive, OnDestroy, OnInit, ElementRef } from "@angular/core";
import { Subscription } from "rxjs";
import { ThemeService } from "../../../core-ui/services";

@Directive({
  selector: "[appThemableLogo]",
})
export class ThemableLogoDirective implements OnInit, OnDestroy {
  private subscriptions: Subscription;

  constructor(
    private themeService: ThemeService,
    private host: ElementRef<HTMLDivElement>
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        const logo = !theme
          ? null
          : !theme.logoUrl
          ? "assets/images/logo_text.svg"
          : theme.logoUrl;

        if (!logo) {
          return;
        }

        this.host.nativeElement.style.backgroundImage = `url(${logo})`;

        // clear previous error (if any)
        this.host.nativeElement.innerHTML = null;

        // check for given logos if the URL actually works
        if (!!theme && theme.logoUrl) {
          const img = document.createElement("img");
          // img.onload = (ev) => {
          //   this.host.nativeElement.innerHTML = null;
          // };
          img.onerror = () => {
            this.host.nativeElement.innerHTML =
              '<av-card type="danger"><av-card-body size="xs">Logo failed to load</av-card-body></av-card>';
          };
          img.src = logo;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
