<av-card-row-responsive flex="2" [icon]="icon" [iconTooltip]="iconTooltip">
  <ng-container card-row-title>{{
    composition.name || composition.conversationId
  }}</ng-container>
  <ng-container card-row-subtitle>
    <!-- <ng-container [ngSwitch]="composition.type">
      <span *ngSwitchCase="'AUDIO'" translate>Audio</span>
      <span *ngSwitchCase="'VIDEO'" translate>Video</span>
    </ng-container> -->
    {{ "Interaction" | translate }}:
    <a (click)="go()" class="go">{{ composition.conversationId }}</a>
  </ng-container>
  <ng-container card-row-body>
    <av-card-row-column *ngIf="!!composition.duration">
      <ng-container column-label>Duration</ng-container>
      <ng-container column-value>{{
        getDuration(composition.duration)
      }}</ng-container>
    </av-card-row-column>
    <av-card-row-column>
      <ng-container column-label>File type</ng-container>
      <ng-container column-value>{{ composition.format }}</ng-container>
    </av-card-row-column>
    <av-card-row-column
      tooltip="{{
        composition.created | amLocale : lang | amDateFormat : 'MMM D y, h:mm a'
      }}"
    >
      <ng-container column-label>Created</ng-container>
      <ng-container column-value>{{
        composition.created | amLocale : lang | amTimeAgo
      }}</ng-container>
    </av-card-row-column>
  </ng-container>
  <ng-container card-row-actions>
    <app-composition-state [state]="composition.state"></app-composition-state>
    <ng-container *ngIf="isCancelVisible">
      <av-tooltip message="Cancel">
        <button
          [attr.aria-label]="'Cancel export' | translate"
          avButton
          size="xxs"
          ofType="icon"
          color="link"
          class="btn-last"
          data-tid="composition/cancel"
          (click)="cancel()"
        >
          <av-icon name="close-big"></av-icon>
        </button>
      </av-tooltip>
    </ng-container>
    <ng-container *ngIf="composition.state === stateCompleted">
      <av-confirm
        [open]="isConfirmOpen"
        arrow="top-right"
        (confirmed)="remove()"
        (canceled)="isConfirmOpen = false"
      >
        <av-tooltip *ngIf="isRemoveEnabled" message="Delete">
          <button
            [attr.aria-label]="'Delete export' | translate"
            class="btn-remove"
            avButton
            ofType="icon"
            size="xxs"
            color="danger"
            data-tid="composition/delete"
            [loading]="loadingRemove"
            (click)="isConfirmOpen = !isConfirmOpen"
          >
            <av-icon name="trash"></av-icon>
          </button>
        </av-tooltip>

        <span confirmTitle translate>Are you sure?</span>
        <span confirmBody translate>
          This will be permanently deleted. You can, however, request a new
          export.
        </span>
      </av-confirm>

      <av-tooltip *ngIf="!downloadUrl" message="Get download link">
        <button
          [attr.aria-label]="'Get download link' | translate"
          avButton
          ofType="icon"
          color="link"
          size="xs"
          data-tid="composition/download"
          [loading]="loadingLink"
          (click)="getDownloadLink()"
        >
          <av-icon name="link"></av-icon>
        </button>
      </av-tooltip>

      <av-tooltip message="Download" *ngIf="!!downloadUrl">
        <a
          [attr.aria-label]="'Download' | translate"
          avButton
          size="xs"
          ofType="icon"
          color="link"
          [href]="downloadUrl"
          data-tid="composition/download"
          download="{{ composition.name }}.{{ composition.format }}"
          target="_blank"
        >
          <av-icon name="document-download"></av-icon>
        </a>
      </av-tooltip>

      <av-copy-clipboard
        tid="composition"
        *ngIf="!!downloadUrl"
        [url]="downloadUrl"
        size="xs"
      ></av-copy-clipboard>

      <av-tooltip message="Play" *ngIf="canPlay">
        <button
          [attr.aria-label]="'Play file' | translate"
          avButton
          ofType="icon"
          color="link"
          size="xs"
          data-tid="composition/play"
          (click)="play()"
        >
          <av-icon name="play"></av-icon>
        </button>
      </av-tooltip>
    </ng-container>
  </ng-container>
</av-card-row-responsive>
