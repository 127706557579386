import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Optional,
  HostBinding,
} from "@angular/core";
import { Subscription } from "rxjs";
import { TileComponent } from "../../tile/tile.component";
import { SketchToolEnum } from "../../../core-ui.enums";
import { SketchService, StreamState } from "../../../services";
import { PointerService } from "../../../services/pointer.service";
import { IStream } from "@auvious/rtc";

@Component({
  selector: "app-sketch-controls",
  templateUrl: "./sketch-controls.component.html",
  styleUrls: ["./sketch-controls.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SketchControlsComponent implements OnInit {
  @Input() room: string;
  @Input() stream: StreamState;
  @Input() column: boolean;
  @Input() disabled: boolean;

  @Output() controlStateChanged: EventEmitter<boolean> = new EventEmitter();

  subscriptions: Subscription = new Subscription();
  activeTool: SketchToolEnum;
  confirmClear = false;
  confirmPosition = "top-left";

  constructor(
    private sketch: SketchService,
    private point: PointerService,
    private cd: ChangeDetectorRef,
    @Optional() tile: TileComponent
  ) {
    this.confirmPosition = !!tile ? "top-right" : "top-left";
  }

  @HostBinding("class") get class() {
    return {
      column: this.column,
    };
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.sketch.toolSelected$.subscribe((tool) => {
        this.activeTool = tool;
        this.cd.detectChanges();
      })
    );
    this.subscriptions.add(
      this.point.zoomFrameChange$.subscribe((data) => {
        if (data.on && this.activeTool) {
          switch (this.activeTool) {
            case SketchToolEnum.arrow:
              this.arrow();
              break;
            case SketchToolEnum.marker:
              this.marker();
              break;
            case SketchToolEnum.eraser:
              this.eraser();
              break;
          }
        }
        this.cd.detectChanges();
      })
    );
  }

  marker() {
    this.tryStopZoom();
    this.sketch.marker(!this.markerOn, this.room);
    this.controlStateChanged.emit(this.markerOn);
  }

  arrow() {
    this.tryStopZoom();
    this.sketch.arrow(!this.arrowOn, this.room);
    this.controlStateChanged.emit(this.arrowOn);
  }

  eraser() {
    this.tryStopZoom();
    this.sketch.eraser(!this.eraserOn, this.room);
    this.controlStateChanged.emit(this.eraserOn);
  }

  clear() {
    this.sketch.clear(this.room);
    this.confirmClear = false;
  }

  tryStopZoom() {
    if (this.zoomOn && !!this.stream) {
      this.point.zoomFrame(this.stream.originator, this.stream.correlationId);
    }
  }

  get tooltipPosition() {
    return this.column ? "left" : "bottom";
  }

  get isDisabled() {
    return !this.room;
  }

  get markerOn() {
    return this.activeTool === SketchToolEnum.marker;
  }

  get eraserOn() {
    return this.activeTool === SketchToolEnum.eraser;
  }

  get arrowOn() {
    return this.activeTool === SketchToolEnum.arrow;
  }

  get zoomOn() {
    return this.activeTool === SketchToolEnum.magnify;
  }
}
