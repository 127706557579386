import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { ISurveyOption } from "../../../models";

@Component({
  selector: "app-survey-option",
  templateUrl: "./option.component.html",
  styleUrls: ["./option.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyOptionComponent {
  @Input() option: ISurveyOption;
  @Input() active: boolean;

  @HostBinding("class") get class() {
    return {
      active: this.active,
    };
  }
}
