<av-tooltip [message]="tooltipLabel" [position]="tooltipPosition">
  <button
    [attr.aria-label]="'Copy link to clipboard' | translate"
    avButton
    ofType="icon"
    color="link"
    [size]="size"
    (click)="copy()"
    [attr.data-tid]="
      !!tid ? [tid, 'copy-to-clipboard'].join('/') : 'copy-to-clipboard'
    "
    #btnRef
  >
    <av-icon name="copy-document-thin"></av-icon>
  </button>
</av-tooltip>
