<av-card [backdrop]="false">
  <av-card-body type="container" size="xs">
    <form #f="ngForm">
      <av-card-row class="info-row">
        <av-card-row-responsive class="fx-flex-1" [flex]="1">
          <ng-container card-row-title>
            <av-card-row-column>
              <ng-container column-label>{{
                "Interaction id" | translate
              }}</ng-container>
              <h3 column-value>
                {{ recording.recordingMetadata.conversationId }}
              </h3>
            </av-card-row-column>
          </ng-container>

          <ng-container card-row-body>
            <av-card-row-column>
              <table column-value>
                <tr>
                  <td><small class="text-muted" translate>Created</small></td>
                  <td>
                    {{
                      recording.recordingMetadata.started
                        | amLocale : lang
                        | amDateFormat : "M/D/yy, h:mm a"
                    }}
                  </td>
                </tr>
                <tr>
                  <td><small class="text-muted" translate>Duration</small></td>
                  <td>
                    {{ getDuration(recording.recordingMetadata.duration) }}
                  </td>
                  <!-- <td>{{recording.recordingMetadata.duration | amDuration:'seconds'}}</td> -->
                </tr>
              </table>
            </av-card-row-column>
          </ng-container>
        </av-card-row-responsive>
      </av-card-row>

      <av-card-row class="info-row" *ngIf="!isAlreadyExported && canExport">
        <av-card-row-responsive class="fx-flex-1" [flex]="1">
          <ng-container card-row-title>
            <av-card-row-column>
              <ng-container column-value>
                <div
                  class="fx-flex-1 fx-column fx-align-start fx-justify-stretch"
                  [class.has-error]="cname.invalid && cname.touched"
                >
                  <label translate>Export file name</label>
                  <input
                    avInput
                    type="text"
                    #cname="ngModel"
                    name="composition-name"
                    pattern="[a-zA-Z0-9@_\-]*"
                    [(ngModel)]="compositionName"
                    required
                    placeholder="{{
                      'Name to export the file with...' | translate
                    }}"
                  />
                  <div
                    class="form-error"
                    *ngIf="cname.hasError('pattern')"
                    translate
                  >
                    Invalid characters. Accepted characters are alphanumeric,
                    '-', '_' and '@'
                  </div>
                  <div
                    class="form-error"
                    *ngIf="cname.touched && cname.hasError('required')"
                    translate
                  >
                    Required
                  </div>
                </div>
              </ng-container>
            </av-card-row-column>
          </ng-container>
          <ng-container card-row-body>
            <av-card-row-column>
              <ng-container column-value>
                <div class="fx-row fx-flex-1 fx-align-center fx-justify-end">
                  <div
                    class="fx-row fx-align-center"
                    *ngIf="!isAlreadyExportedAudio && !isAlreadyExportedVideo"
                  >
                    <div class="form-checkbox">
                      <label
                        ><input
                          type="radio"
                          required
                          name="composition-type"
                          value="AUDIO"
                          [(ngModel)]="compositionType"
                          (ngModelChange)="compositionTypeChange()"
                        />
                        <span translate>Audio</span>
                      </label>
                    </div>
                    <div class="form-checkbox">
                      <label
                        ><input
                          type="radio"
                          required
                          name="composition-type"
                          value="VIDEO"
                          [(ngModel)]="compositionType"
                          (ngModelChange)="compositionTypeChange()"
                        />
                        <span translate>Video</span>
                      </label>
                    </div>
                  </div>

                  <!-- <div class="fx-row fx-align-center" *ngIf="!isAlreadyExportedAudio && !isAlreadyExportedVideo">
                      <label translate>Video</label>
                      <div class="switch" [class.switch-active]="isExportVideo" (click)="toggleExportType()">
                        <div class="switch-control">
                          <av-icon *ngIf="isExportVideo" name="cam-dark.svg" alt="cam">
                          <av-icon *ngIf="isExportAudio" name="mic-dark.svg" alt="mic">
                        </div>
                      </div>
                      <label translate>Audio (only)</label>
                    </div> -->

                  <!-- <div>
                          <h4>Video format</h4>
                          <div class="form-checkbox">
                            <label><input type="radio" name="composition-video-format" value="WEBM"
                                [(ngModel)]="compositionVideoFormat">
                              <span translate>webm</span>
                            </label>
                          </div>
                          <div class="form-checkbox">
                            <label><input type="radio" name="composition-video-format" value="MP4"
                                [(ngModel)]="compositionVideoFormat">
                              <span translate>mp4</span>
                            </label>
                          </div>
                        </div> -->

                  <!-- <div>
                          <h4>audio format</h4>
                          <div class="form-checkbox">
                            <label><input type="radio" name="composition-audio-format" value="WAV"
                                [(ngModel)]="compositionAudioFormat">
                              <span translate>wav</span>
                            </label>
                          </div>
                          <div class="form-checkbox">
                            <label><input type="radio" name="composition-audio-format" value="MP3"
                                [(ngModel)]="compositionAudioFormat">
                              <span translate>mp3</span>
                            </label>
                          </div>
                        </div> -->

                  <div class="margin-left-20">
                    <button
                      avButton
                      color="accent"
                      *ngIf="!isAlreadyExported"
                      [disabled]="
                        !f.form.valid ||
                        (isExportVideo && isMaxParticipantsReached)
                      "
                      (click)="requestExport(f.form)"
                    >
                      <span
                        translate
                        *ngIf="
                          !isAlreadyExportedAudio && !isAlreadyExportedVideo
                        "
                        >Export</span
                      >
                      <span translate *ngIf="isAlreadyExportedVideo"
                        >Export Audio</span
                      >
                      <span translate *ngIf="isAlreadyExportedAudio"
                        >Export Video</span
                      >
                    </button>
                  </div>
                </div>
              </ng-container>
            </av-card-row-column>
          </ng-container>
        </av-card-row-responsive>
      </av-card-row>

      <av-card type="warning" *ngIf="isMaxParticipantsReached && isExportVideo">
        <av-card-body size="sm" class="text-center" type="container">
          <span translate [translateParams]="translateParam"
            >Composition is not supported for this amount of participants.</span
          >
        </av-card-body>
      </av-card>
    </form>
  </av-card-body>
</av-card>

<av-card [backdrop]="false" *ngIf="compositionsAvailable">
  <av-card-header>
    <div class="fx-row fx-align-center fx-justify-space-between">
      <span translate>Exports</span>
      <div>
        <av-tooltip
          message="{{ 'Refresh exports' | translate }}"
          position="left"
        >
          <button
            avButton
            size="xs"
            ofType="icon"
            color="link"
            class="composition-reload"
            id="btn-composition-recording-refresh"
            (click)="reload()"
          >
            <av-icon name="reload"></av-icon>
          </button>
        </av-tooltip>
      </div>
    </div>
  </av-card-header>
  <av-card-row
    *ngFor="let composition of recording.compositions; trackBy: trackByFn"
  >
    <app-composition-row
      [composition]="composition"
      (cancelRequested)="cancelExport($event)"
      (composeRequested)="retryExport($event)"
      (removed)="removed($event)"
    ></app-composition-row>
  </av-card-row>
</av-card>
