<ng-container *ngIf="data.length > 0">
  <av-card-row
    *ngFor="let message of data; trackBy: trackByFn; index as i"
    class="fx-row fx-align-center fx-flex-1 content"
  >
    <div>
      <div *ngIf="isNewSender(message, i)">
        <small
          ><b>{{ message.name }}</b></small
        >
      </div>
      <div class="message">{{ message.translation || message.transcript }}</div>
    </div>
  </av-card-row>
</ng-container>
<div class="container-pad-md text-muted" *ngIf="data.length === 0" translate>
  No data found
</div>
