import { IMetricDashboard, ITag } from "../interfaces";
import {
  DEFAULT_APPOINTMENT_CHAT_MODE,
  DEFAULT_CALLBACK_NOTIFICATIONS,
  DEFAULT_COMPOSITION_FILE_NAME,
  DEFAULT_AI_VISION_PROMPT,
  ScheduleTypeEnum,
} from "../../core-ui.enums";
import {
  AppointmentRoutingTypeEnum,
  IAppointmentNotification,
} from "../Appointment";
import { ChatModeEnum } from "@auvious/integrations";
import { TicketCharacterModeEnum } from "../ITicket";

// eslint-disable-next-line no-shadow
export enum AgentParam {
  RECORDER_CONFIGURED = "recorderConfigured",
  RECORDER_AUTO_START = "recorderAutoStart",
  RECORDER_AUDIO_ONLY = "recorderAudioOnly",
  RECORDER_LOCK = "recorderLock",
  RATING_REQUIRED = "ratingRequired",
  SCHEDULE_ENABLED = "callbackEnabled",
  CALLBACK_INTERACTION_PREFIX = "callbackInteractionPrefix",
  SCREEN_SHARE_ENABLED = "screenShareEnabled",
  SCREEN_SHARE_WIDGET_ENABLED = "screenShareWidgetEnabled",
  AUTO_JOIN_CALL = "autoJoinCall",
  CUSTOMER_LANGUAGE = "customerLanguage",
  AUTO_HOLD_ON_BLUR = "autoHoldOnBlur",
  COBROWSE_ENABLED = "cobrowseEnabled",
  COBROWSE_RECORD = "cobrowseRecord",
  SNAPSHOT_TYPES = "snapshotTypes",
  SNAPSHOT_ENABLED = "snapshotEnabled",
  SNAPSHOT_SWITCH_CUSTOMER_CAM_ENABLED = "snapshotSwitchCustomerCamEnabled",
  SNAPSHOT_SEGMENT_ENABLED = "snapshotSegmentEnabled",
  AUTO_START_AR = "autoStartAR",
  STREAM_CONTROLS_ENABLED = "streamControlsEnabled",
  FILE_TRANSFER_ENABLED = "fileTransferEnabled",
  DEVICE_SETUP_ENABLED = "deviceSetupEnabled",
  REPORTING_DASHBOARDS = "reportingDashboards",
  REPORTING_CARBON_METRICS_ENABLED = "reportingCarbonMetricsEnabled",
  VIDEO_CALL_ENABLED = "videoCallEnabled",
  AUTO_ANSWER = "autoAnswer",
  POP_OUT_CALL = "popOutCall",
  COMPOSITION_DELETE_ENABLED = "compositionDeleteEnabled",
  VIDEO_CALL_REQUIRES_INTERACTION = "videoCallRequiresInteraction",
  MICROPHONE_ENABLED = "microphoneEnabled",
  CAMERA_ENABLED = "cameraEnabled",
  MICROPHONE_DEFAULT_ON = "microphoneDefaultOn",
  CAMERA_DEFAULT_ON = "cameraDefaultOn",
  DEFAULT_BACKGROUND_EFFECTS_ENABLED = "defaultBackgroundEffectsEnabled",
  SINGLE_USE_TICKET_ENABLED = "singleUseTicketEnabled",
  SINGLE_USE_SCHEDULE_TICKET_ENABLED = "singleUseScheduleTicketEnabled",
  TICKET_LENGTH = "ticketLength",
  TICKET_CHARACTER_MODE = "ticketCharacterMode",
  COMPOSITION_EXPORT_ENABLED = "compositionExportEnabled",
  // wait for X seconds for customer to join the call, if not, it is probably a ghost call
  DISCONNECTED_CUSTOMER_TIMEOUT = "disconnectedCustomerTimeout",
  SCHEDULE_CHANNEL = "scheduleChannel",
  /**
   * @deprecated moved to service params, no need to send them in a new appointment
   */
  APPOINTMENT_NOTIFICATIONS = "appointmentNotifications",
  //----
  APPOINTMENT_NOTIFICATIONS_ENABLED = "appointmentNotificationsEnabled",
  APPOINTMENT_NOTIFICATION_HEADERS_ENABLED = "appointmentNotificationHeadersEnabled",
  APPOINTMENT_METADATA_ENABLED = "appointmentMetadataEnabled",
  DEFAULT_BACKGROUND_EFFECT_ID = "defaultBackroundEffectId",
  COBROWSE_SCREEN_SHARE_ENABLED = "cobrowseScreenShareEnabled",
  COBROWSE_AGENT_SCREEN_SHARE_ENABLED = "cobrowseAgentScreenShareEnabled",
  SNAPSHOT_DEFAULT_TYPES_APPENDED = "snashotDefaultTypesAppended",
  COBROWSE_CONTROL_PERMISSION_ENABLED = "cobrowseControlPermissionEnabled",
  APPOINTMENT_NOTIFICATION_CHANNEL_SMS_ENABLED = "appointmentNotificationChannelSMSEnabled",
  APPOINTMENT_NOTIFICATION_CHANNEL_EMAIL_ENABLED = "appointmentNotificationChannelEmailEnabled",
  CALLBACK_DEFAULT_QUEUE = "callbackDefaultQueue",
  TRANSCRIPT_PROVIDER_CONFIGURED = "asrOfflineProviderConfigured",
  TRANSCRIPT_PROVIDER = "transcriptProvider",
  SMS_WORKFLOW_ENABLED = "smsWorkflowEnabled",
  SMS_WORKFLOW_ID = "smsWorkflowId",
  EMAIL_WORKFLOW_ENABLED = "emailWorkflowEnabled",
  EMAIL_WORKFLOW_ID = "emailWorkflowId",
  DIGITAL_CONNECT_ENABLED = "digitalConnectEnabled",
  LEAVE_CONTROL_ENABLED = "leaveControlEnabled",
  APPOINTMENT_CHAT_MODE = "appointmentChatMode",
  BACKGROUND_EFFECT_PER_QUEUE_ENABLED = "backgroundEffectPerQueueEnabled",
  BACKGROUND_EFFECT_PER_QUEUE = "backgroundEffectPerQueue",
  AUTO_INVITE_CUSTOMER = "autoInviteCustomer",
  QR_INVITATION_ENABLED = "qrInvitationEnabled",
  CUSTOMER_INVITATION_ENABLED = "customerInvitationEnabled",
  AGENT_INVITATION_ENABLED = "agentInvitationEnabled",
  CALLBACK_NOTIFICATIONS = "callbackNotifications",
  APPOINTMENT_ROUTING_TYPE = "appointmentRoutingType",
  COMPOSITION_DEFAULT_FILE_NAME = "compositionDefaultFileName",
  CUSTOMER_SCREEN_SHARE_REQUEST_ENABLED = "customerScreenShareRequestEnabled",
  // show a button in cobrowse-launcher to auto-generate a string
  // that is used to invite a customer to cobrowse via a chat interaction in auvious widget
  COBROWSE_MANUAL_CHAT_INVITATION_ENABLED = "cobrowseManualChatInvitationEnabled",
  CUSTOMER_CAMERAS_CONTROL_ENABLED = "customerCamerasControlEnabled",
  COBROWSE_CUSTOMER_NAME_VISIBLE = "cobrowseCustomerNameVisible",
  APPOINTMENT_DURATION_OPTIONS = "appointmentDurationOptions",
  MAIN_MENU_ENABLED = "mainMenuEnabled",
  AI_VISION_ENABLED = "aiVisionEnabled",
  AI_VISION_PROMPT = "aiVisionPrompt",
  TRANSCRIPT_CUSTOM_PROMPT = "transcriptCustomPrompt",
  TRANSCRIPT_CUSTOM_PROMPT_ENABLED = "transcriptCustomPromptEnabled",
}

export interface IApplicationAgentParams {
  [AgentParam.RECORDER_CONFIGURED]?: boolean;
  [AgentParam.RECORDER_AUTO_START]?: boolean;
  [AgentParam.RECORDER_AUDIO_ONLY]?: boolean;
  [AgentParam.RECORDER_LOCK]?: boolean;
  [AgentParam.RATING_REQUIRED]?: boolean;
  [AgentParam.SCHEDULE_ENABLED]?: boolean;
  [AgentParam.CALLBACK_INTERACTION_PREFIX]?: string;
  [AgentParam.SCREEN_SHARE_ENABLED]?: boolean;
  [AgentParam.SCREEN_SHARE_WIDGET_ENABLED]?: boolean;
  [AgentParam.AUTO_JOIN_CALL]?: boolean;
  [AgentParam.CUSTOMER_LANGUAGE]?: boolean;
  [AgentParam.AUTO_HOLD_ON_BLUR]?: boolean;
  [AgentParam.COBROWSE_ENABLED]?: boolean;
  [AgentParam.COBROWSE_RECORD]?: boolean;
  [AgentParam.SNAPSHOT_TYPES]?: ITag[];
  [AgentParam.SNAPSHOT_ENABLED]?: boolean;
  [AgentParam.SNAPSHOT_SWITCH_CUSTOMER_CAM_ENABLED]?: boolean;
  [AgentParam.SNAPSHOT_SEGMENT_ENABLED]?: boolean;
  [AgentParam.AUTO_START_AR]?: boolean;
  [AgentParam.STREAM_CONTROLS_ENABLED]?: boolean;
  [AgentParam.FILE_TRANSFER_ENABLED]?: boolean;
  [AgentParam.DEVICE_SETUP_ENABLED]?: boolean;
  [AgentParam.REPORTING_DASHBOARDS]?: IMetricDashboard[];
  [AgentParam.REPORTING_CARBON_METRICS_ENABLED]?: boolean;
  [AgentParam.VIDEO_CALL_ENABLED]?: boolean;
  [AgentParam.AUTO_ANSWER]?: boolean;
  [AgentParam.POP_OUT_CALL]?: boolean;
  [AgentParam.COMPOSITION_DELETE_ENABLED]?: boolean;
  [AgentParam.VIDEO_CALL_REQUIRES_INTERACTION]?: boolean;
  [AgentParam.CAMERA_ENABLED]?: boolean;
  [AgentParam.MICROPHONE_ENABLED]?: boolean;
  [AgentParam.CAMERA_DEFAULT_ON]?: boolean;
  [AgentParam.MICROPHONE_DEFAULT_ON]?: boolean;
  [AgentParam.DEFAULT_BACKGROUND_EFFECTS_ENABLED]?: boolean;
  [AgentParam.SINGLE_USE_TICKET_ENABLED]?: boolean;
  [AgentParam.SINGLE_USE_SCHEDULE_TICKET_ENABLED]?: boolean;
  [AgentParam.TICKET_LENGTH]?: number;
  [AgentParam.TICKET_CHARACTER_MODE]?: TicketCharacterModeEnum;
  [AgentParam.COMPOSITION_EXPORT_ENABLED]?: boolean;
  [AgentParam.DISCONNECTED_CUSTOMER_TIMEOUT]?: number;
  [AgentParam.SCHEDULE_CHANNEL]?: ScheduleTypeEnum;
  /**
   * @deprecated  moved to service params, no need to send them in a new appointment
   */
  [AgentParam.APPOINTMENT_NOTIFICATIONS]?: IAppointmentNotification[];
  // a simple agent does not have access to serviceParams, we need a copy of that value to agentParams
  [AgentParam.APPOINTMENT_NOTIFICATIONS_ENABLED]?: boolean;
  [AgentParam.APPOINTMENT_NOTIFICATION_HEADERS_ENABLED]?: boolean;
  [AgentParam.APPOINTMENT_METADATA_ENABLED]?: boolean;
  [AgentParam.DEFAULT_BACKGROUND_EFFECT_ID]?: string;
  [AgentParam.COBROWSE_SCREEN_SHARE_ENABLED]?: boolean;
  [AgentParam.COBROWSE_AGENT_SCREEN_SHARE_ENABLED]?: boolean;
  [AgentParam.SNAPSHOT_DEFAULT_TYPES_APPENDED]?: boolean;
  [AgentParam.COBROWSE_CONTROL_PERMISSION_ENABLED]?: boolean;
  [AgentParam.APPOINTMENT_NOTIFICATION_CHANNEL_EMAIL_ENABLED]?: boolean;
  [AgentParam.APPOINTMENT_NOTIFICATION_CHANNEL_SMS_ENABLED]?: boolean;
  [AgentParam.CALLBACK_DEFAULT_QUEUE]?: string;
  [AgentParam.TRANSCRIPT_PROVIDER_CONFIGURED]?: boolean;
  [AgentParam.TRANSCRIPT_PROVIDER]?: string;
  [AgentParam.SMS_WORKFLOW_ENABLED]?: boolean;
  [AgentParam.SMS_WORKFLOW_ID]?: string;
  [AgentParam.EMAIL_WORKFLOW_ENABLED]?: boolean;
  [AgentParam.EMAIL_WORKFLOW_ID]?: string;
  [AgentParam.DIGITAL_CONNECT_ENABLED]?: boolean;
  [AgentParam.LEAVE_CONTROL_ENABLED]?: boolean;
  [AgentParam.APPOINTMENT_CHAT_MODE]?: ChatModeEnum;
  [AgentParam.BACKGROUND_EFFECT_PER_QUEUE_ENABLED]?: boolean;
  [AgentParam.BACKGROUND_EFFECT_PER_QUEUE]?: { [assetId: string]: string };
  [AgentParam.AUTO_INVITE_CUSTOMER]?: boolean;
  [AgentParam.QR_INVITATION_ENABLED]?: boolean;
  [AgentParam.CUSTOMER_INVITATION_ENABLED]?: boolean;
  [AgentParam.AGENT_INVITATION_ENABLED]?: boolean;
  [AgentParam.CALLBACK_NOTIFICATIONS]?: IAppointmentNotification[];
  [AgentParam.APPOINTMENT_ROUTING_TYPE]?: AppointmentRoutingTypeEnum;
  [AgentParam.COMPOSITION_DEFAULT_FILE_NAME]?: string;
  [AgentParam.CUSTOMER_SCREEN_SHARE_REQUEST_ENABLED]?: boolean;
  [AgentParam.COBROWSE_MANUAL_CHAT_INVITATION_ENABLED]?: boolean;
  [AgentParam.CUSTOMER_CAMERAS_CONTROL_ENABLED]?: boolean;
  [AgentParam.COBROWSE_CUSTOMER_NAME_VISIBLE]?: boolean;
  [AgentParam.APPOINTMENT_DURATION_OPTIONS]?: number[];
  [AgentParam.MAIN_MENU_ENABLED]?: boolean;
  [AgentParam.AI_VISION_ENABLED]?: boolean;
  [AgentParam.AI_VISION_PROMPT]?: string;
  [AgentParam.TRANSCRIPT_CUSTOM_PROMPT]?: string;
  [AgentParam.TRANSCRIPT_CUSTOM_PROMPT_ENABLED]?: boolean;
}

export class AgentOptions implements IApplicationAgentParams {
  public [AgentParam.RECORDER_CONFIGURED] = false;
  public [AgentParam.RECORDER_AUTO_START] = false;
  public [AgentParam.RECORDER_AUDIO_ONLY] = false;
  public [AgentParam.RECORDER_LOCK] = false;
  public [AgentParam.RATING_REQUIRED] = false;
  public [AgentParam.SCHEDULE_ENABLED] = true;
  public [AgentParam.CALLBACK_INTERACTION_PREFIX] = null;
  public [AgentParam.SCREEN_SHARE_ENABLED] = true;
  public [AgentParam.SCREEN_SHARE_WIDGET_ENABLED] = false;
  public [AgentParam.AUTO_JOIN_CALL] = false;
  public [AgentParam.CUSTOMER_LANGUAGE] = false;
  public [AgentParam.AUTO_HOLD_ON_BLUR] = false;
  public [AgentParam.COBROWSE_ENABLED] = true;
  public [AgentParam.COBROWSE_RECORD] = false;
  public [AgentParam.SNAPSHOT_TYPES] = [];
  public [AgentParam.SNAPSHOT_ENABLED] = false;
  public [AgentParam.SNAPSHOT_SWITCH_CUSTOMER_CAM_ENABLED] = true;
  public [AgentParam.SNAPSHOT_SEGMENT_ENABLED] = true;
  public [AgentParam.AUTO_START_AR] = false;
  public [AgentParam.STREAM_CONTROLS_ENABLED] = true;
  public [AgentParam.FILE_TRANSFER_ENABLED] = false;
  public [AgentParam.DEVICE_SETUP_ENABLED] = true;
  public [AgentParam.REPORTING_DASHBOARDS] = [];
  public [AgentParam.REPORTING_CARBON_METRICS_ENABLED] = true;
  public [AgentParam.VIDEO_CALL_ENABLED] = true;
  public [AgentParam.AUTO_ANSWER] = false;
  public [AgentParam.POP_OUT_CALL] = false;
  public [AgentParam.COMPOSITION_DELETE_ENABLED] = false;
  public [AgentParam.VIDEO_CALL_REQUIRES_INTERACTION] = true;
  public [AgentParam.MICROPHONE_ENABLED] = true;
  public [AgentParam.CAMERA_ENABLED] = true;
  public [AgentParam.MICROPHONE_DEFAULT_ON] = true;
  public [AgentParam.CAMERA_DEFAULT_ON] = true;
  public [AgentParam.DEFAULT_BACKGROUND_EFFECTS_ENABLED] = true;
  public [AgentParam.SINGLE_USE_TICKET_ENABLED] = false;
  public [AgentParam.SINGLE_USE_SCHEDULE_TICKET_ENABLED] = false;
  public [AgentParam.TICKET_LENGTH] = 6;
  public [AgentParam.TICKET_CHARACTER_MODE] = TicketCharacterModeEnum.letters;
  public [AgentParam.COMPOSITION_EXPORT_ENABLED] = true;
  public [AgentParam.DISCONNECTED_CUSTOMER_TIMEOUT] = 0;
  public [AgentParam.SCHEDULE_CHANNEL] = ScheduleTypeEnum.genesysCallback;
  // a simple agent does not have access to serviceParams, we need a copy of that value to agentParams
  public [AgentParam.APPOINTMENT_NOTIFICATIONS_ENABLED] = true;
  public [AgentParam.APPOINTMENT_NOTIFICATION_HEADERS_ENABLED] = false;
  public [AgentParam.APPOINTMENT_METADATA_ENABLED] = false;
  public [AgentParam.DEFAULT_BACKGROUND_EFFECT_ID] = null;
  public [AgentParam.COBROWSE_SCREEN_SHARE_ENABLED] = false;
  public [AgentParam.COBROWSE_AGENT_SCREEN_SHARE_ENABLED] = false;
  public [AgentParam.SNAPSHOT_DEFAULT_TYPES_APPENDED] = true;
  public [AgentParam.COBROWSE_CONTROL_PERMISSION_ENABLED] = true;
  public [AgentParam.APPOINTMENT_NOTIFICATION_CHANNEL_EMAIL_ENABLED] = false;
  public [AgentParam.APPOINTMENT_NOTIFICATION_CHANNEL_SMS_ENABLED] = false;
  public [AgentParam.CALLBACK_DEFAULT_QUEUE] = "";
  public [AgentParam.DIGITAL_CONNECT_ENABLED] = false;
  public [AgentParam.APPOINTMENT_NOTIFICATIONS]: IAppointmentNotification[] =
    [];
  public [AgentParam.TRANSCRIPT_PROVIDER_CONFIGURED] = false;
  public [AgentParam.TRANSCRIPT_PROVIDER] = null;
  public [AgentParam.SMS_WORKFLOW_ENABLED] = false;
  public [AgentParam.SMS_WORKFLOW_ID] = null;
  public [AgentParam.EMAIL_WORKFLOW_ENABLED] = false;
  public [AgentParam.EMAIL_WORKFLOW_ID] = null;
  public [AgentParam.LEAVE_CONTROL_ENABLED] = true;
  public [AgentParam.APPOINTMENT_CHAT_MODE] = DEFAULT_APPOINTMENT_CHAT_MODE;
  public [AgentParam.BACKGROUND_EFFECT_PER_QUEUE_ENABLED] = false;
  public [AgentParam.BACKGROUND_EFFECT_PER_QUEUE] = {};
  public [AgentParam.AUTO_INVITE_CUSTOMER] = false;
  public [AgentParam.QR_INVITATION_ENABLED] = true;
  public [AgentParam.CUSTOMER_INVITATION_ENABLED] = true;
  public [AgentParam.AGENT_INVITATION_ENABLED] = true;
  public [AgentParam.CALLBACK_NOTIFICATIONS] = [
    ...DEFAULT_CALLBACK_NOTIFICATIONS,
  ];
  public [AgentParam.APPOINTMENT_ROUTING_TYPE] =
    AppointmentRoutingTypeEnum.GENESYS_CLOUD_FLOW;
  public [AgentParam.COMPOSITION_DEFAULT_FILE_NAME] =
    DEFAULT_COMPOSITION_FILE_NAME;
  public [AgentParam.CUSTOMER_SCREEN_SHARE_REQUEST_ENABLED] = false;
  public [AgentParam.COBROWSE_MANUAL_CHAT_INVITATION_ENABLED] = false;
  public [AgentParam.CUSTOMER_CAMERAS_CONTROL_ENABLED] = false;
  public [AgentParam.COBROWSE_CUSTOMER_NAME_VISIBLE] = true;
  public [AgentParam.APPOINTMENT_DURATION_OPTIONS] = [5, 15, 30];
  public [AgentParam.MAIN_MENU_ENABLED] = true;
  public [AgentParam.AI_VISION_ENABLED] = false;
  public [AgentParam.AI_VISION_PROMPT] = DEFAULT_AI_VISION_PROMPT;
  public [AgentParam.TRANSCRIPT_CUSTOM_PROMPT] = undefined;
  public [AgentParam.TRANSCRIPT_CUSTOM_PROMPT_ENABLED] = false;

  static create(options: IApplicationAgentParams) {
    const instance = new AgentOptions();
    let notifications: IAppointmentNotification[] =
      instance.appointmentNotifications;

    // we already have existing notifications so try update those
    if (options?.appointmentNotifications?.length > 0) {
      notifications = options.appointmentNotifications.map((n) => {
        // get the new properties and update the existing ones
        let exists: IAppointmentNotification =
          instance.appointmentNotifications.find((o) => o.id === n.id);
        if (exists) {
          // get old properties and also merge old and new properties
          exists = {
            ...exists,
            ...n,
            textBody: n.message && !n.textBody ? n.message : n.textBody,
          };
          return exists.enabled === undefined
            ? {
                ...exists,
                enabled: true,
              }
            : exists;
        }
        return n;
      });

      if (notifications.filter((n) => n.channel === "email").length === 0) {
        notifications = [
          ...notifications,
          ...instance.appointmentNotifications.filter(
            (n) => n.channel === "email"
          ),
        ];
      }
    }
    return {
      ...instance,
      ...options,
      [AgentParam.APPOINTMENT_NOTIFICATIONS]: notifications,
    };
  }
}
