import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../../../../app/services/locale.service";
import { ILocaleEntity } from "../../../../core-ui/models/interfaces";

@Component({
  selector: "av-locale-picker",
  templateUrl: "./locale-picker.component.html",
  styleUrls: ["./locale-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalePickerComponent implements OnInit {
  @Input() locales: ILocaleEntity[];

  @Output() changed: EventEmitter<string> = new EventEmitter();

  data: ILocaleEntity[];
  currentLocale: ILocaleEntity;
  notFoundText;
  placeholderText;

  constructor(
    translate: TranslateService,
    private localeService: LocaleService,
    private cd: ChangeDetectorRef
  ) {
    this.notFoundText = translate.instant("Not found");
    this.placeholderText = translate.instant("Select time zone");
  }

  ngOnInit() {
    if (this.locales) {
      this.data = this.locales;
      const lang = this.localeService.getLanguage();
      this.currentLocale = this.data.find((l) => l.language === lang);
      if (!this.currentLocale) {
        this.currentLocale = this.data[0];
      }
    } else {
      this.data = this.localeService.getLocales();
      this.currentLocale = this.localeService.getLocale();
    }
    this.data = this.locales || this.localeService.getLocales();
    this.cd.detectChanges();
  }

  selected(item: ILocaleEntity) {
    this.changed.emit(item.locale);
  }

  inputChanged(query) {}
}
