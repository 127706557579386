<av-card
  class="info"
  [class.info-no-asset]="!isVideoAvailable && !isImageAvailable"
>
  <av-card-header>
    <h1 translate>Please wait</h1>
  </av-card-header>
  <av-card-body>
    <h2 class="text-muted">
      <span translate>The call starts:</span>
      {{ scheduledDate | amCalendar }}.
      <small>{{ timezoneOffset }}</small>
    </h2>
    <p></p>
  </av-card-body>

  <!-- <iframe width="100%" [class.hidden]="!isEmbeddedVideo" height="415" #frame frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe> -->

  <ng-container *ngIf="isImageAvailable">
    <img
      class="image"
      [src]="imageSrc"
      alt="{{'Waiting for next available agent' | translate}}"
      (error)="imgLoadFailed($event)"
    />
  </ng-container>

  <div class="player" *ngIf="isVideoAvailable">
    <vg-player (onPlayerReady)="onPlayerReady($event)">
      <vg-overlay-play *ngIf="isNotPlaying"></vg-overlay-play>
      <vg-buffering></vg-buffering>
      <vg-controls>
        <vg-scrub-bar></vg-scrub-bar>
        <vg-mute vgFor="media-player-video"></vg-mute>
      </vg-controls>
      <video
        [vgMedia]="media"
        #media
        id="media-player-video"
        preload="auto"
        autoplay
        playsinline
        webkit-playsinline="true"
        loop
      >
        <source [src]="videoSrc" />
      </video>
    </vg-player>
  </div>
</av-card>
