<av-card class="survey" [backdrop]="false">
  <av-card-header (close)="skip()"></av-card-header>
  <av-card-body class="survey-body">
    <av-card-row
      class="fx-column fx-gap"
      *ngFor="let q of questions() | orderBy : 'order'; index as i"
    >
      <div class="question-header fx-row fx-align-center fx-gap">
        <div>
          <div class="question-index">{{ i + 1 }}</div>
        </div>
        <span>{{ q.question }}</span>
      </div>

      <av-card-body [ngSwitch]="q.type">
        <app-survey-text
          *ngSwitchCase="typeShortText"
          #questionRef
          [index]="i + 1"
          [question]="q"
          (changed)="answerChanged($event)"
        >
        </app-survey-text>

        <app-survey-text
          *ngSwitchCase="typeLongText"
          #questionRef
          [index]="i + 1"
          [question]="q"
          (changed)="answerChanged($event)"
        >
        </app-survey-text>

        <app-survey-score
          *ngSwitchCase="typeLongScore"
          #questionRef
          [index]="i + 1"
          [question]="q"
          (changed)="answerChanged($event)"
        >
        </app-survey-score>

        <app-survey-score
          *ngSwitchCase="typeShortScore"
          #questionRef
          [index]="i + 1"
          [question]="q"
          (changed)="answerChanged($event)"
        >
        </app-survey-score>

        <app-survey-option-single
          *ngSwitchCase="typeSingleChoice"
          #questionRef
          [index]="i + 1"
          [question]="q"
          (changed)="answerChanged($event)"
        >
        </app-survey-option-single>

        <app-survey-option-multi
          *ngSwitchCase="typeMultipleChoice"
          #questionRef
          [index]="i + 1"
          [question]="q"
          (changed)="answerChanged($event)"
        >
        </app-survey-option-multi>
      </av-card-body>
    </av-card-row>
  </av-card-body>

  <av-card-footer class="fx-column fx-gap">
    <av-card type="info" *ngIf="!isFormValid()">
      <av-card-body size="sm" type="container">{{
        "Please fill in all required fields." | translate
      }}</av-card-body>
    </av-card>
    <div class="fx-row fx-gal">
      <button
        class="fx-flex-1"
        avButton
        ofType="basic"
        (click)="skip()"
        translate
        data-tid="survey/skip"
      >
        skip
      </button>
      <button
        class="fx-flex-1"
        avButton
        color="accent"
        (click)="send()"
        translate
        data-tid="survey/send"
      >
        Send feedback
      </button>
    </div>
  </av-card-footer>
</av-card>
