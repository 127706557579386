import { Injectable } from "@angular/core";
import {
  IVisionStrategy,
  OpenAITranscriptStrategy,
} from "../models/strategies";
import { AppConfigService } from "./app.config.service";
import { UserService } from "./user.service";
import {
  AgentParam,
  IVisionSnapshotState,
  VisionMetadata,
  VisionState,
} from "../models";
import {
  AIVisionEvent,
  ApplicationProviderTypeEnum,
  SNAPSHOT_TYPE_VISION,
  UserRoleEnum,
} from "../core-ui.enums";
import { GenericErrorHandler } from "./error-handlers.service";
import { AuviousRtcService } from "./rtc.service";
import { ApplicationService } from "./application.service";
import { IEndpoint } from "@auvious/rtc";
import { SnapshotService } from "./snapshot.service";
import { BehaviorSubject, filter, firstValueFrom, map, Subject } from "rxjs";
import { ISnapshot } from "@auvious/snapshot";
import { blobToBase64, debugError } from "./utils";
import { HttpClient } from "@angular/common/http";
import { ConferenceService } from "./conference.service";

@Injectable()
export class VisionService {
  private impl: IVisionStrategy;

  private snapshotState: Map<
    string,
    Pick<IVisionSnapshotState, "result" | "state">
  > = new Map();
  private visionRequestState: Map<string, string> = new Map(); // vision request id : snapshot id

  private _imageStateChange = new Subject<IVisionSnapshotState>();
  public imageStateChange$ = this._imageStateChange.asObservable();

  private _imageChange = new Subject<ISnapshot>();
  public imageChange$ = this._imageChange.asObservable();

  private _imagesAvailable = new Subject<ISnapshot[]>();
  public imagesAvailable$ = this._imagesAvailable.asObservable();

  private _resultChange = new BehaviorSubject<{
    snapshotId: string;
    result: string;
  }>(undefined);
  public resultChange$ = this._resultChange.asObservable();

  private _conferenceMetadata: VisionMetadata;

  constructor(
    private config: AppConfigService,
    private user: UserService,
    private rtc: AuviousRtcService,
    private logger: GenericErrorHandler,
    private application: ApplicationService,
    private snapshotService: SnapshotService,
    private conference: ConferenceService,
    private http: HttpClient
  ) {
    this.config.configChanged$.subscribe((c) => {
      let provider;
      if (this.user.isAdmin) {
        provider = c.serviceParameters?.transcriptProvider;
      } else if (this.user.isAgent) {
        if (c.agentParameters?.[AgentParam.TRANSCRIPT_PROVIDER]) {
          provider = c.agentParameters[AgentParam.TRANSCRIPT_PROVIDER];
        }
      }
      if (provider) this.providerChanged(provider);
    });

    this.snapshotService.snapshotStateChanged$
      .pipe(filter((s) => s.type === SNAPSHOT_TYPE_VISION))
      .subscribe((snapshot) => {
        if (snapshot.signedUrl) {
          // we have the actual snapshot
          this.setSnapshotState(snapshot.id, "snapshotReady");
          this.loadImageAndRecognize(snapshot);
        } else {
          // this is the temp snapshot until we get the new one
          this.setSnapshotState(snapshot.id, "snapshotRequested");
          // this.requestState.set(snapshot., )
        }
        this._imageChange.next(snapshot);
      });

    this.conference.conferenceMetadataSet$
      .pipe(filter((m) => m instanceof VisionMetadata))
      .subscribe((m: VisionMetadata) => {
        this.setConferenceMetadata(m as VisionMetadata);
        Object.keys(m.state).forEach((key) => {
          this.snapshotState.set(key, {
            result: m.state[key].result,
            state: m.state[key].state,
          });
        });
      });

    this.snapshotService.snapshotsAvailable$
      .pipe(map((a) => a.filter((s) => s.type === SNAPSHOT_TYPE_VISION)))
      .subscribe((snapshots) => {
        this._imagesAvailable.next(snapshots.reverse());
      });
  }

  private getConferenceMetadata(): VisionMetadata {
    if (!this._conferenceMetadata) {
      this._conferenceMetadata = new VisionMetadata(this.rtc.myself);
    }
    return this._conferenceMetadata;
  }

  private setConferenceMetadata(value: VisionMetadata) {
    this._conferenceMetadata = value;
  }

  private setSnapshotState(id: string, state: VisionState, result?: string) {
    this.snapshotState.set(id, { state, result });

    // update conference metadata
    const meta = this.getConferenceMetadata();
    meta.visionStateChanged({ id, state, result });
    this.setConferenceMetadata(meta);
    this.conference.setConferenceMetadata(meta);

    // notify others
    this._imageStateChange.next({ id, state, result });
  }

  public getStateForSnapshot(id: string): IVisionSnapshotState {
    return { id, ...this.snapshotState.get(id) };
  }

  private async loadImageAndRecognize(snapshot: ISnapshot) {
    try {
      const url = await firstValueFrom(snapshot.signedUrl);
      const imageBase64 = await this.convertImageToBase64(url);
      const response = await this.recognize(imageBase64);
      //
      this.setSnapshotState(snapshot.id, "analysisRequested");
      this.visionRequestState.set(response.id, snapshot.id);
    } catch (ex) {
      debugError(ex);
    }
  }

  public supported(endpoint: IEndpoint): boolean {
    const roles = endpoint?.metadata?.roles || [];
    // const capabilities = endpoint?.metadata?.capabilities || [];
    const hasCustomerRole = roles.includes(UserRoleEnum.customer);
    return hasCustomerRole;
  }

  public providerChanged(provider: ApplicationProviderTypeEnum) {
    switch (provider) {
      case ApplicationProviderTypeEnum.OPEN_AI:
        this.impl = new OpenAITranscriptStrategy(
          this.logger,
          this.rtc,
          this.application
        );
        break;
    }
  }

  private async convertImageToBase64(imageUrl: string): Promise<string> {
    try {
      const blob = await firstValueFrom(
        this.http.get(imageUrl, { responseType: "blob" })
      );
      const base64 = await blobToBase64(blob);
      return base64;
    } catch (ex) {
      debugError(ex);
      throw ex;
    }
  }

  public propagateEvent(event: {
    id: string;
    result: string;
    timestamp: string;
    type: AIVisionEvent;
  }) {
    let snapshotId;
    switch (event.type) {
      case "AIRecognitionCreatedEvent":
        snapshotId = this.visionRequestState.get(event.id);
        if (snapshotId) {
          this.setSnapshotState(snapshotId, "analysisReady", event.result);
        }
        break;
      case "AIRecognitionFailedEvent":
        snapshotId = this.visionRequestState.get(event.id);
        if (snapshotId) {
          this.setSnapshotState(snapshotId, "analysisError");
        }
    }
  }

  recognize(imageBase64: string, model?: string): Promise<{ id: string }> {
    return this.impl.recognize(
      this.config.agentParam(AgentParam.AI_VISION_PROMPT),
      imageBase64,
      model
    );
  }
}
