<ng-container *ngIf="isSMSAvailable()">
  <av-menu-item
    [attr.aria-label]="'send link via SMS' | translate"
    icon="sms"
    [arrow]="smsQueues().length > 1"
    [expanded]="isSelectingSMSQueue()"
    (select)="shareLink()"
    (keyArrowRight)="expand()"
    (keyArrowLeft)="collapse()"
    data-tid="conference/send-via-sms"
  >
    <span translate>Send via SMS</span>
  </av-menu-item>

  <div class="sub-item" *ngIf="isSelectingSMSQueue()">
    <av-menu sub-menu>
      <av-menu-item type="header"
        ><span translate>Please select a queue</span></av-menu-item
      >
      <av-menu-item
        [attr.aria-label]="('sms queue ' | translate) + q.name"
        (select)="smsQueueSelected(q)"
        *ngFor="let q of smsQueues()"
        data-tid="conference/select-sms-queue"
      >
        {{ q.name }}
      </av-menu-item>
    </av-menu>
  </div>
</ng-container>
