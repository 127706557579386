import {
  PARAM_APPLICATION_ID,
  PARAM_GUEST,
  PARAM_ROOM_ID,
  PARAM_WHISPER_MODE,
} from "../../../app/app.enums";

import {
  AgentParam,
  IApplicationAgentParams,
  IInteraction,
  ITicketRequest,
  TicketTypeEnum,
} from "../../../core-ui/models";
import { IInvitationStrategy } from "../../../core-ui/models/strategies";
import { ITicketAppointmentOptions } from "../../../core-ui/models/interfaces";
import { ScheduleTicketTypeEnum } from "../../../core-ui/models/ITicket";
import {
  IApplicationPublicParams,
  PublicParam,
} from "../../../core-ui/models/application-options/PublicOptions";
import { WhisperModeEnum } from "../../../core-ui";

export abstract class AbstractBaseInvitationStrategy
  implements IInvitationStrategy
{
  constructor(
    protected applicationId: string,
    protected publicParams: IApplicationPublicParams,
    protected agentParams: IApplicationAgentParams
  ) {}

  prepareScheduleTicketRequest(
    type: ScheduleTicketTypeEnum,
    length: number,
    conferenceId: string,
    customerId: string,
    interactionId: string,
    scheduledAt: string,
    customerName?: string,
    timezone?: string,
    queueId?: string
  ): ITicketRequest {
    return {
      type,
      length,
      mode: this.agentParams[AgentParam.TICKET_CHARACTER_MODE],
      grouping: this.publicParams[PublicParam.TICKET_GROUPS_ENABLED],
      properties: {
        application_id: this.applicationId,
        conference_id: conferenceId,
        customer_id: customerId,
        customer_name: customerName,
        ttl: 28800,
        scheduled_date: scheduledAt,
        join_base_url: `${window.location.origin}/t/`,
        theme: this.publicParams?.[PublicParam.THEME],
        interaction_id: interactionId,
        timezone,
        queueId,
      },
    };
  }

  prepareTicketRequest(
    type: TicketTypeEnum,
    length: number,
    conferenceId: string,
    customerId: string,
    interactionId: string,
    customerName?: string
  ): ITicketRequest {
    return {
      type,
      length,
      mode: this.agentParams[AgentParam.TICKET_CHARACTER_MODE],
      grouping: this.publicParams[PublicParam.TICKET_GROUPS_ENABLED],
      properties: {
        application_id: this.applicationId,
        conference_id: conferenceId,
        customer_id: customerId,
        customer_name: customerName,
        ttl: 14400,
        theme: this.publicParams?.[PublicParam.THEME],
        interaction_id: interactionId,
      },
    };
  }

  /**
   * @deprecated The backend will create the ticket
   */
  prepareAppointmentTicketRequest(
    agentUserId: string,
    options?: ITicketAppointmentOptions
  ): ITicketRequest {
    return {
      type: TicketTypeEnum.AppointmentScheduleTicket,
      properties: {
        application_id: this.applicationId,
        conference_id: null,
        customer_id: null,
        theme: this.publicParams?.[PublicParam.THEME],
        // agentId: agentUserId,
      },
    };
  }

  prepareAgentInvitation(interaction: IInteraction): string {
    const url = new URL("a", window.location.origin);

    url.searchParams.set(PARAM_ROOM_ID, interaction.getRoom());
    url.searchParams.set(PARAM_APPLICATION_ID, this.applicationId);
    url.searchParams.set(PARAM_GUEST, "true");

    const customParams = this.customParams(interaction);
    if (!!customParams) {
      customParams.forEach((value, key) => url.searchParams.append(key, value));
    }

    return url.toString();
  }

  prepareSupervisorInvitation(
    interaction: IInteraction,
    whisperMode: WhisperModeEnum
  ): string {
    const url = new URL("a", window.location.origin);

    url.searchParams.set(PARAM_ROOM_ID, interaction.getRoom());
    url.searchParams.set(PARAM_APPLICATION_ID, this.applicationId);
    url.searchParams.set(PARAM_GUEST, "true");
    url.searchParams.set(PARAM_WHISPER_MODE, whisperMode);
    const customParams = this.customParams(interaction);
    if (!!customParams) {
      customParams.forEach((value, key) => url.searchParams.append(key, value));
    }
    return url.toString();
  }

  abstract customParams(interaction: IInteraction): URLSearchParams;
}
