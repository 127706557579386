<av-card [backdrop]="false" type="snapshot" class="snapshot-session">
  <div class="snapshot-toolbar fx-row fx-align-center fx-justify-space-between">
    <!-- image manipulation controls -->
    <div class="snapshot-toolbar-group fx-row">
      <!-- rotate -->
      <div class="button-group button-container">
        <av-tooltip position="bottom" message="Rotate left">
          <button
            [attr.aria-label]="'Rotate snapshot counter clockwise' | translate"
            [disabled]="!activeSnapshot()"
            (click)="rotateLeft()"
            avButton
            ofType="icon"
            size="xs"
            color="basic"
            data-tid="snapshot-session/rotate-left"
          >
            <av-icon name="rotate-left"></av-icon>
          </button>
        </av-tooltip>
        <av-tooltip position="bottom" message="Rotate right">
          <button
            [attr.aria-label]="'Rotate snapshot clockwise' | translate"
            [disabled]="!activeSnapshot()"
            (click)="rotateRight()"
            avButton
            ofType="icon"
            size="xs"
            color="basic"
            data-tid="snapshot-session/rotate-right"
          >
            <av-icon name="rotate-right"></av-icon>
          </button>
        </av-tooltip>
      </div>

      <!-- zoom -->
      <div class="button-group button-container">
        <av-tooltip position="bottom" message="Zoom in">
          <button
            [attr.aria-label]="'Zoom in snapshot' | translate"
            [disabled]="!activeSnapshot()"
            (click)="zoomIn()"
            avButton
            ofType="icon"
            size="xs"
            color="basic"
            data-tid="snapshot-session/zoom-in"
          >
            <av-icon name="zoom-in"></av-icon>
          </button>
        </av-tooltip>

        <av-tooltip position="bottom">
          <button
            [attr.aria-label]="
              (isExpanded ? 'Fit to view' : 'Fill view') | translate
            "
            [disabled]="!activeSnapshot()"
            (click)="fillFit()"
            avButton
            ofType="icon"
            size="xs"
            color="basic"
            data-tid="snapshot-session/toggle-fill-fit"
          >
            <av-icon *ngIf="!isExpanded" name="expand"></av-icon>
            <av-icon *ngIf="isExpanded" name="collapse"></av-icon>
          </button>
          <ng-container tooltipMessage>
            <ng-container *ngIf="!isExpanded">{{
              "Actual size" | translate
            }}</ng-container>
            <ng-container *ngIf="isExpanded">{{
              "Fit to view" | translate
            }}</ng-container>
          </ng-container>
        </av-tooltip>

        <av-tooltip position="bottom" message="Zoom Out">
          <button
            [attr.aria-label]="'Zoom out snapshot' | translate"
            [disabled]="!activeSnapshot()"
            (click)="zoomOut()"
            avButton
            ofType="icon"
            size="xs"
            color="basic"
            data-tid="snapshot-session/zoom-out"
          >
            <av-icon name="zoom-out"></av-icon>
          </button>
        </av-tooltip>
      </div>

      <div
        class="button-group button-container segment-confirm-wrap"
        *ngIf="!review && isSegmentAvailable"
      >
        <av-tooltip position="bottom" message="Remove background">
          <button
            [attr.aria-label]="'Remove background' | translate"
            [disabled]="
              !activeSnapshot() || !isImageReady() || isSegmentLoading()
            "
            (click)="segment()"
            [loading]="isSegmentLoading()"
            avButton
            ofType="icon"
            size="xs"
            color="basic"
            data-tid="snapshot-session/segment"
          >
            <av-icon name="document-magic"></av-icon>
          </button>
        </av-tooltip>
        <av-card
          *ngIf="isSegmentSet()"
          class="segment-confirm"
          [popup]="true"
          arrow="top-right"
        >
          <av-card-body size="xs">
            <div class="fx-row fx-align-center fx-justify-center fx-gap-10">
              <button
                avButton
                type="flat"
                color="accent"
                size="sm"
                translate
                (click)="saveSegment()"
              >
                Save changes
              </button>
              <button
                avButton
                type="basic"
                color="basic"
                size="sm"
                translate
                (click)="cancelSegment()"
              >
                Cancel
              </button>
            </div>
          </av-card-body>
        </av-card>
      </div>

      <!-- crop
            <div class="button-container">
                <button [disabled]="!activeSnapshot" (click)="crop()" avButton ofType="icon" size="xs" color="basic">
                    <av-icon name="crop"></av-icon>
                </button>
            </div> -->
    </div>

    <!-- snapshot -->
    <div>
      <av-tooltip position="bottom" *ngIf="!review">
        <button
          [attr.aria-label]="
            (!!activeSnapshot() ? 'Resume' : 'Take') + ' snapshot' | translate
          "
          (click)="takeSnapshot()"
          avButton
          ofType="icon"
          size="sm"
          [color]="!!activeSnapshot() ? 'basic' : 'danger'"
          data-tid="snapshot-session/take-snapshot"
        >
          <av-icon name="camera"></av-icon>
        </button>
        <ng-container tooltipMessage>
          <ng-container *ngIf="!activeSnapshot()">{{
            "Take snapshot" | translate
          }}</ng-container>
          <ng-container *ngIf="activeSnapshot()">{{
            "Resume snapshot" | translate
          }}</ng-container>
        </ng-container>
      </av-tooltip>
    </div>

    <!-- snapshot controls -->
    <div
      class="snapshot-toolbar-group fx-justify-end fx-align-center fx-row"
      *ngIf="!review"
    >
      <!-- switch cam -->
      <av-tooltip position="left" *ngIf="isCameraSwitchAvailable">
        <button
          [attr.aria-label]="
            (hasMultipleCameras
              ? 'Switch Camera'
              : 'Participant does not have multiple cameras'
            ) | translate
          "
          [disabled]="isCameraSwitchDisabled()"
          [loading]="isCameraSwitchInProgress()"
          (click)="switchCam()"
          avButton
          ofType="icon"
          size="xs"
          color="basic"
          data-tid="snapshot-session/switch-camera"
        >
          <av-icon name="camera-reverse"></av-icon>
        </button>
        <ng-container tooltipMessage>
          <ng-container *ngIf="hasMultipleCameras">{{
            "Switch Camera" | translate
          }}</ng-container>
          <ng-container *ngIf="!hasMultipleCameras"
            >{{ "Participant does not have multiple cameras" | translate }}
          </ng-container>
        </ng-container>
      </av-tooltip>

      <!-- torch -->
      <av-tooltip position="left">
        <button
          [attr.aria-label]="
            (isTorchAvailable
              ? isTorchOn
                ? 'Close torch'
                : 'Open torch'
              : 'Torch not supported on this video'
            ) | translate
          "
          [disabled]="isTorchDisabled()"
          (click)="toggleTorch()"
          avButton
          ofType="icon"
          size="xs"
          [color]="isTorchOn ? 'accent' : 'basic'"
          data-tid="snapshot-session/toggle-torch"
        >
          <av-icon name="flash"></av-icon>
        </button>
        <ng-container tooltipMessage>
          <ng-container *ngIf="isTorchAvailable">{{
            "Torch" | translate
          }}</ng-container>
          <ng-container *ngIf="!isTorchAvailable">{{
            "Torch not supported on this video" | translate
          }}</ng-container>
        </ng-container>
      </av-tooltip>

      <!-- end -->
      <av-tooltip position="bottom" message="Close" class="exit">
        <button
          [attr.aria-label]="'Stop snapshot session' | translate"
          avButton
          ofType="icon"
          size="xs"
          color="basic"
          (click)="end()"
          data-tid="snapshot-session/end"
        >
          <av-icon name="close-big"></av-icon>
        </button>
      </av-tooltip>
    </div>

    <!-- review controls -->
    <div class="snapshot-toolbar-group fx-row fx-justify-end" *ngIf="review">
      <ng-container *ngIf="endpointSnapshots.length === 0">
        <button
          [attr.aria-label]="'Approve all snapshots' | translate"
          avButton
          ofType="stroked"
          [themable]="false"
          (click)="approveAll()"
          translate
          data-tid="snapshot-session/close"
        >
          Close
        </button>
      </ng-container>
      <ng-container *ngIf="endpointSnapshots.length > 0">
        <button
          *ngIf="isSkipVisible()"
          avButton
          [attr.aria-label]="'Skip approval of snapshots' | translate"
          [themable]="false"
          (click)="skip()"
          translate
          data-tid="snapshot-session/skip"
        >
          skip
        </button>
        <button
          *ngIf="!isHealthySnapshotMissing()"
          avButton
          [attr.aria-label]="'Approve all snapshots and close' | translate"
          [loading]="isLoading()"
          ofType="stroked"
          [themable]="false"
          (click)="approveAll()"
          translate
          data-tid="snapshot-session/approve-all-close"
        >
          Approve all & close
        </button>
      </ng-container>
    </div>
  </div>

  <div class="snapshot-body">
    <div class="snapshot-view">
      <ng-container *ngIf="!!activeSnapshot()">
        <av-image
          #snapshotImage
          (ready)="imageReady()"
          [src]="activeSnapshot().signedUrl | async"
          [metadata]="activeSnapshotMetadata"
          (metadataChanged)="metadataChanged($event)"
          (segmentStateChanged)="segmentStateChanged($event)"
        >
        </av-image>
      </ng-container>

      <app-stream-view
        *ngIf="isStreamVisible()"
        [@fadeInOut]="true"
        [id]="stream.id"
        [muted]="true"
        [stream]="mediaStream"
        [type]="stream.type"
        (mediaElementReady)="mediaElementReady($event)"
      ></app-stream-view>

      <div *ngIf="isLoadingSnapshots()">
        <av-spinner mode="light" size="md"></av-spinner>
      </div>

      <h3 *ngIf="isEmptySnapshots()" translate>No snapshots taken...</h3>

      <h3 *ngIf="isHealthySnapshotMissing()" translate>
        Could not load any snapshots...
      </h3>
    </div>

    <div class="snapshot-tags">
      <div
        class="snapshot-tags-strip"
        #tagsStrip
        [class.hidden]="!activeSnapshot()"
      >
        <div #tags *ngFor="let t of tags; trackBy: trackByTag">
          <av-tag
            [tag]="t"
            (remove)="removeTag()"
            (add)="addTag($event)"
            [active]="activeSnapshot()?.type === t.value"
          >
          </av-tag>
        </div>
      </div>

      <!-- <div
        class="snapshot-tag-spinner fx-align-center fx-justify-center"
        *ngIf="isSegmentLoading()"
      >
        <av-spinner mode="light" size="xs"></av-spinner>
      </div> -->

      <!-- <div
        *ngIf="isSegmentSet()"
        class="fx-row fx-align-center fx-justify-center fx-gap-20"
      >
        <button
          avButton
          type="flat"
          color="accent"
          size="sm"
          translate
          (click)="saveSegment()"
        >
          Save changes
        </button>
        <button
          avButton
          type="basic"
          color="basic"
          size="sm"
          translate
          (click)="cancelSegment()"
        >
          Cancel
        </button>
      </div> -->
    </div>
  </div>

  <div class="snapshot-previews">
    <div class="snapshot-previews-strip" #snapshotStrip>
      <app-snapshot
        #snapshotThumbnails
        [@snapshotSlide]="empty(s)"
        [size]="videoSize"
        [snapshot]="s"
        [active]="s.id === activeSnapshot()?.id"
        (click)="selectSnapshot(s)"
        (ready)="snapshotReady($event)"
        (error)="snapshotError($event)"
        (removed)="removeSnapshot($event, i)"
        *ngFor="let s of endpointSnapshots; trackBy: trackByFn; index as i"
      ></app-snapshot>
    </div>
  </div>
</av-card>
