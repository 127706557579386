<av-card
  role="alert"
  [attr.aria-label]="'QR code' | translate"
  [attr.aria-live]="'polite'"
  [attr.tabindex]="0"
  avTrapFocus
  #cardRef
>
  <av-card-header
    subtitle="Scan with your phone's camera or QR code app to join the call."
    (close)="cancel()"
    [autofocus]="false"
  >
    <span translate>Join the call</span>
  </av-card-header>
  <av-card-body type="spotlight">
    <div class="laser" *ngIf="animate"></div>
    <qrcode [qrdata]="data" [width]="160"></qrcode>
  </av-card-body>
  <av-card-footer>
    <div class="fx-row">
      <div class="fx-column qr-link">
        <small class="text-muted" translate>Video link</small>
        <b aria-hidden="true">{{ data }}</b>
      </div>
      <av-copy-clipboard
        [url]="data"
        tooltipLabel="Copy to clipboard"
        size="sm"
        tid="qr"
        #copyRef
      ></av-copy-clipboard>
    </div>
  </av-card-footer>
</av-card>
