import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
  QueryList,
} from "@angular/core";
import {
  IMenuItemContainer,
  MENU_ITEM_CONTAINER_TOKEN,
  MenuItemComponent,
} from "../menu-item/menu-item.component";
import { CardComponent } from "../card/card.component";

@Component({
  selector: "av-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, AfterContentInit {
  @Input() responsive = false;
  @Input() submenu = false;

  @ContentChildren(MenuItemComponent)
  menuItems!: QueryList<MenuItemComponent>;

  @ContentChildren(MENU_ITEM_CONTAINER_TOKEN)
  customContainers!: QueryList<IMenuItemContainer>;

  @HostBinding("class") get class() {
    return {
      "menu-responsive": this.responsive,
    };
  }

  private allMenuItems: MenuItemComponent[] = [];
  private parentMenuItem: MenuItemComponent;

  constructor(
    public host: ElementRef<HTMLDivElement>,
    @Optional() public parentCard: CardComponent
  ) {}

  ngOnInit() {
    this.host.nativeElement.setAttribute("role", "menu");
  }

  ngAfterContentInit(): void {
    this.collectMenuItems();
    this.menuItems.changes.subscribe(() => this.collectMenuItems());
    this.customContainers.changes.subscribe(() => this.collectMenuItems());
  }

  notifyQueryListChanges() {
    this.customContainers.notifyOnChanges();
  }

  private collectMenuItems(): void {
    // Get direct `av-menu-item` components
    const directItems = this.menuItems
      .toArray()
      .filter(
        (item) =>
          item.type !== "header" && item.type !== "separator" && !item.disabled
      );

    // Get `av-menu-item` components inside onther components
    const customItems = this.customContainers
      .map((container) => container.getMenuItems())
      .flat();

    // Combine all `av-menu-item` components into one array
    const allItems = [...directItems, ...customItems];

    // Sort components based on DOM order using compareDocumentPosition
    this.allMenuItems = allItems.sort((a, b) => {
      const elementA = a.getNativeElement();
      const elementB = b.getNativeElement();

      return elementA.compareDocumentPosition(elementB) &
        Node.DOCUMENT_POSITION_PRECEDING
        ? 1
        : -1;
    });
  }

  public navigateIn(item: MenuItemComponent) {
    this.parentMenuItem = item;
    this.allMenuItems?.[0].focus();
  }
  public navigateOut() {
    this.parentMenuItem?.focus();
    this.parentMenuItem = undefined;
  }

  public navigate(direction: "next" | "prev", currentItem: MenuItemComponent) {
    const items = this.allMenuItems;
    const currentIndex = items.indexOf(currentItem);

    if (currentIndex === -1) return;

    const nextIndex =
      direction === "next"
        ? (currentIndex + 1) % items.length
        : (currentIndex - 1 + items.length) % items.length;

    items[nextIndex].focus();
  }
}
