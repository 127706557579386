import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

import { ApplicationFactory } from "../../factories/ApplicationFactory";
import { GenericErrorHandler } from "../../services/error-handlers.service";
import { ErrorPageCode } from "../../app.enums";

import {
  ActivityIndicatorService,
  PublicTicketService,
  ThemeService,
} from "../../../core-ui";
import { ApplicationService } from "../../../core-ui/services/application.service";
import { PARAM_TICKET_ID } from "../../../core-ui/core-ui.enums";

@Injectable()
export class AppCustomerGuard {
  constructor(
    private applicationService: ApplicationService,
    private applicationFactory: ApplicationFactory,
    private floatingService: ActivityIndicatorService,
    private publicTicketService: PublicTicketService,
    private errorHandler: GenericErrorHandler,
    private router: Router,
    private themeService: ThemeService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!!this.applicationService.getActiveApplication()) {
      return true;
    }

    try {
      this.floatingService.loading(true, "", true); // "initializing application"
      const applicationId = await this.discoverApplicationId(route);
      const application =
        await this.applicationFactory.createCustomerApplication(applicationId);
      this.applicationService.setActiveApplication(application);
      this.themeService.parseApplicationOptions(application);
      return true;
    } catch (error) {
      this.errorHandler.handleError(error);
      return this.router.createUrlTree([
        "/error",
        ErrorPageCode.APPLICATION_NOT_FOUND,
      ]);
    } finally {
      this.floatingService.loading(false);
    }
  }

  private async discoverApplicationId(
    route: ActivatedRouteSnapshot
  ): Promise<string> {
    const ticketId = route.paramMap.get(PARAM_TICKET_ID);

    const ticket = await this.publicTicketService.getTicket(ticketId);

    if (!ticket.properties.application_id && !ticket.properties.applicationId) {
      throw new Error("no applicationId for ticket");
    }

    return ticket.properties.application_id || ticket.properties.applicationId;
  }
}
