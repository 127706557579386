<div class="floating-panel" *ngIf="visible()" [@zoomInOut]="true">
  <av-spinner size="lg" *ngIf="!icon()"></av-spinner>
  <av-icon
    *ngIf="!!icon()"
    mode="light"
    [class]="cssClass()"
    [name]="icon()"
  ></av-icon>
  <div class="floating-panel-message" aria-hidden="true" *ngIf="!!message()">
    {{ message() | translate }}
  </div>
</div>

<div
  class="floating-backdrop"
  [@fadeOut]="true"
  *ngIf="backdropVisible()"
></div>
