<av-autocomplete
  #auto
  [data]="timezones"
  [initialValue]="query"
  [searchKeyword]="'name'"
  [notFoundText]="notFoundText"
  [placeholder]="placeholderText"
  [itemTemplate]="itemTemplate"
  [notFoundTemplate]="notFoundTemplate"
  (selected)="timezoneChange($event)"
  (inputChanged)="onChangeSearch($event)"
>
</av-autocomplete>

<ng-template #itemTemplate let-item>
  <av-menu-item class="menu-item" [innerHTML]="item.name"></av-menu-item>
</ng-template>
<ng-template #notFoundTemplate let-notFound>
  <av-menu-item class="menu-item" [innerHTML]="notFoundText"></av-menu-item>
</ng-template>

<!-- <input
  avInput
  type="search"
  (ngModelChange)="search($event)"
  [(ngModel)]="query"
  (focus)="searchFocus()"
/>

<av-card [popup]="true" *ngIf="isOpen">
  <av-card-body type="container" size="xs">
    <av-menu>
      <av-menu-item
        (select)="timezoneChange(zone)"
        *ngFor="let zone of timezonesFiltered"
      >
        {{ zone.name }}
      </av-menu-item>
    </av-menu>
  </av-card-body>
</av-card>

<div class="backdrop" *ngIf="isOpen" (click)="searchBlur()"></div> -->

<!-- <ng-moment-timezone-picker
  [class.tz-picker-dark]="isDarkMode"
  [getUserZone]="true"
  (ngModelChange)="timezoneChange($event)"
  [ngModel]="timezonePicker"
  name="tz"
>
</ng-moment-timezone-picker> -->
<!-- <div [formGroup]="form">
 <ng-moment-timezone-picker
    [class.tz-picker-dark]="isDarkMode"
    [getUserZone]="true"
    ngDefaultControl
    [formControlName]="'tz'"
  >
  </ng-moment-timezone-picker> 
</div> -->
