import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from "@angular/core";
import { IEndpoint } from "@auvious/rtc";
import { merge, Subscription } from "rxjs";
import { slideIn } from "../../core-ui.animations";
import { SizeEnum } from "../../core-ui.enums";
import { DEFAULT_CALL_HOLD_STATE, ICallHoldState } from "../../models";
import {
  ArPointerActivatedEvent,
  AuviousRtcService,
  ConferenceService,
  ConferenceStore,
  DeviceCaptureErrorAction,
  LocalMediaService,
  PointerService,
  SnapshotService,
  StreamState,
  windowActionType,
  WindowEventService,
} from "../../services";
import { CobrowseService } from "../../services/cobrowse.service";
import { MediaRulesService } from "../../services/media.rules.service";
import { RoomComponent } from "../room/room.component";

@Component({
  selector: "app-display-capture",
  templateUrl: "./display-capture.component.html",
  styleUrls: ["./display-capture.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideIn],
})
export class DisplayCaptureComponent implements OnInit, OnDestroy {
  @Input() size: SizeEnum;
  @Input() screenStream: StreamState;

  subscriptions: Subscription;
  callHoldState: ICallHoldState;
  isPointerActive = false;
  isCobrowseOn = false;
  isDisabledFromWidget = false;
  snapshotStream: StreamState;

  constructor(
    @Optional() private room: RoomComponent,
    private mediaRules: MediaRulesService,
    private conferenceService: ConferenceService,
    private windowEventService: WindowEventService,
    private cdr: ChangeDetectorRef,
    private pointerService: PointerService,
    private cobrowseService: CobrowseService,
    private local: LocalMediaService,
    private snapshotService: SnapshotService,
    private store: ConferenceStore
  ) {
    this.subscriptions = new Subscription();
    this.callHoldState = { ...DEFAULT_CALL_HOLD_STATE };
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.conferenceService.callHoldStateChange$.subscribe((state) => {
        this.callHoldState = state;
        this.cdr.markForCheck();
      })
    );
    this.subscriptions.add(
      this.pointerService.pointAreaChange$.subscribe((d) => {
        this.isPointerActive = d.type === ArPointerActivatedEvent.type;
        this.cdr.detectChanges();
      })
    );
    this.subscriptions.add(
      this.cobrowseService.started$.subscribe((_) => {
        this.isCobrowseOn = true;
        this.cdr.detectChanges();
      })
    );
    this.subscriptions.add(
      this.cobrowseService.ended$.subscribe((_) => {
        this.isCobrowseOn = false;
        this.cdr.detectChanges();
      })
    );

    this.subscriptions.add(
      merge(
        // snapshot started for us as customers
        this.snapshotService.started$,
        this.snapshotService.ended$
      ).subscribe((e) => {
        this.cdr.detectChanges();
      })
    );

    this.subscriptions.add(
      // snapshot started for us as agents
      this.store.updated$.subscribe(() => {
        if (this.snapshotStream !== this.store.snapshotStream) {
          this.snapshotStream = this.store.snapshotStream;
          this.cdr.detectChanges();
        }
      })
    );

    if (this.room.isChildWindow) {
      this.subscriptions.add(
        this.windowEventService
          .init(this.room.interaction.getParentFrameUrl())
          .subscribe((action) => {
            if (!action) {
              return;
            }
            switch (action.type) {
              case windowActionType.SCREEN_SHARE_AVAILABILITY_CHANGED:
                this.isDisabledFromWidget = !action.payload;
                this.cdr.detectChanges();
                break;
              case windowActionType.DEVICE_CAPTURE:
                if (!this.isScreenShareAvailable) {
                  const error = new Error("screen-share-unvailable");
                  error.name = "BadStateError";
                  return this.windowEventService.sendMessage(
                    new DeviceCaptureErrorAction(error)
                  );
                }
                if (this.isOnHold) {
                  const error = new Error("on-hold");
                  error.name = "BadStateError";
                  return this.windowEventService.sendMessage(
                    new DeviceCaptureErrorAction(error)
                  );
                }
                // if (this.isScreenShareLocal) {
                //     // already sharing, stop
                // }
                this.toggleScreenShare();
                break;
              case windowActionType.DEVICE_CAPTURE_STOP:
                this.toggleScreenShare();
                break;
            }
          })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /* actions */

  async toggleScreenShare() {
    if (this.isScreenShareActive) {
      this.local.closeScreenStream();
    } else {
      this.mediaRules.dismissPendingConfirmations();
      this.local.shareScreen();
    }
  }

  /* view getters */

  get isDisabled() {
    return (
      this.isScreenShareActive ||
      this.isOnHold ||
      this.isPointerActive ||
      this.isCobrowseOn ||
      this.isSnapshotActive ||
      this.isDisabledFromWidget
    );
  }

  get isSnapshotActive() {
    return this.snapshotService.isStarted;
  }

  get isScreenShareFullScreen() {
    return this.room.isAudioCall && this.room.isWidget;
  }

  get isOnHold(): boolean {
    return this.callHoldState.isOnHold;
  }
  get isHoldLocal(): boolean {
    return this.callHoldState.username === this.myself.username;
  }

  get isScreenShareActive(): boolean {
    return !!this.screenStream;
  }

  get isScreenShareAvailable(): boolean {
    return this.mediaRules.isDisplayCaptureAvailable;
  }

  get isScreenShareLocal(): boolean {
    return this.screenStream?.originator.endpoint === this.myself.endpoint;
  }

  private get myself(): IEndpoint {
    return {
      username: this.store.username,
      endpoint: this.store.endpoint,
    };
  }
}
