import {
  Component,
  signal,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  createFileAndDownload,
  extractErrorMessage,
  IOpenAITranslationMessage,
  ITranscriptTransformStatus,
  ITransformContent,
  NotificationService,
  TranscriptStateEnum,
  TranscriptTransformType,
} from "../../../../core-ui";
import { TranscriptService } from "../../../../core-ui/services/transcript.service";
import moment from "moment";

@Component({
  selector: "app-composition-transcript-transform",
  templateUrl: "./composition-transcript-transform.component.html",
  styleUrls: ["./composition-transcript-transform.component.scss"],
})
export class CompositionTranscriptTransformComponent implements OnInit {
  @Input() conversationId: string;
  @Input() transcriptId: string;
  @Input() transform: ITranscriptTransformStatus;
  @Input() transformType: TranscriptTransformType;

  @Output() removed = new EventEmitter<void>();

  url = signal<string>(null);
  confirmRemove = signal(false);
  title: string;
  subtitle: string;
  icon: string;
  isLoadingURL = signal(false);
  isLoadingContent = signal(false);
  isRemoveLoading = signal(false);
  isContentExpanded = signal(false);
  canRequestFormattedTranscript = false;
  isTranslate = false;
  translateData = signal<IOpenAITranslationMessage[]>([]);
  transformData = signal<ITransformContent>(undefined);
  copyData = signal(undefined);

  constructor(
    private transcript: TranscriptService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    switch (this.transformType) {
      case "sentiment":
        this.icon = "file-sentiment";
        this.title = "Sentiment Analysis";
        this.subtitle = "Positive, negative or neutral";
        break;
      case "summary":
        this.icon = "file-summary";
        this.title = "Summary";
        this.subtitle = "Basic bullet points of the conversation";
        break;
      case "translation":
        this.icon = "file-translation";
        this.title = "Translation";
        this.subtitle = "English";
        break;
      case "prompt":
        this.icon = "file-prompt";
        this.title = "Custom";
        this.subtitle = "Custom prompt";
    }
    this.canRequestFormattedTranscript =
      this.transcript.isDownloadFormattedTranscriptSupported(
        this.transformType
      );
    this.isTranslate = this.transformType === "translation";
  }

  async getURL() {
    try {
      this.isLoadingURL.set(true);
      const data =
        this.transformType === "translation"
          ? await this.transcript.getTranslationURL(
              this.conversationId,
              this.transcriptId,
              this.transform.id
            )
          : await this.transcript.getTransformURL(
              this.conversationId,
              this.transcriptId,
              this.transform.id
            );
      this.url.set(data.url);
    } catch (ex) {
      this.notification.error("Could not retrieve URL", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isLoadingURL.set(false);
    }
  }

  async removeTransform() {
    try {
      this.isRemoveLoading.set(true);
      this.confirmRemove.set(false);
      this.transformType === "translation"
        ? await this.transcript.removeTranslation(
            this.conversationId,
            this.transcriptId,
            this.transform.id
          )
        : await this.transcript.removeTransform(
            this.conversationId,
            this.transcriptId,
            this.transform.id
          );
      this.removed.emit();
    } catch (ex) {
      this.notification.error("Could not remove file", {
        body: extractErrorMessage(ex),
      });
    } finally {
      this.isRemoveLoading.set(false);
    }
  }

  async toggleContent() {
    if (this.isLoadingContent()) return;
    if (this.isContentExpanded()) {
      this.isContentExpanded.set(false);
    } else {
      if (
        (this.isTranslate && this.translateData().length === 0) ||
        (!this.isTranslate && !this.transformData())
      ) {
        await this.getContent();
      }
      this.isContentExpanded.set(true);
    }
  }

  async getContent() {
    try {
      if (this.transformType === "translation") {
        if (this.translateData().length > 0) return this.translateData();
        //
        this.isLoadingContent.set(true);
        const resp = (await this.transcript.getTranslationContent(
          this.conversationId,
          this.transcriptId,
          this.transform.id
        )) as IOpenAITranslationMessage[];
        this.translateData.set(resp);
        this.copyData.set(JSON.stringify(resp));
      } else {
        if (this.transformData()) return this.transformData();
        //
        this.isLoadingContent.set(true);
        const resp = await this.transcript.getTransformContent(
          this.conversationId,
          this.transcriptId,
          this.transform.id
        );
        this.transformData.set(resp);
        this.copyData.set(resp.result);
      }
    } catch (ex) {
      this.notification.error("Could not retrieve content", {
        body: extractErrorMessage(ex),
      });
      throw ex;
    } finally {
      this.isLoadingContent.set(false);
    }
  }

  async download() {
    const response = await this.getContent();
    createFileAndDownload(
      JSON.stringify(response),
      `${this.transformType}.json`,
      "text/plain"
    );
  }

  get isCompleted() {
    return (
      this.transform.state.toLowerCase() ===
      TranscriptStateEnum.completed.toLowerCase()
    );
  }

  get isProcessing() {
    return (
      this.transform.state.toLowerCase() ===
      TranscriptStateEnum.processing.toLowerCase()
    );
  }
}
