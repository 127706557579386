import {
  Component,
  computed,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { SnapshotService } from "../../../../core-ui/services";
import { filter, map, mergeMap, Subscription } from "rxjs";
import { ISnapshot } from "@auvious/snapshot";
import { IArea, IVisionSnapshotState } from "../../../../core-ui/models";
import { VisionService } from "../../../../core-ui/services/vision.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "av-vision",
  templateUrl: "./vision.component.html",
  styleUrls: ["./vision.component.scss"],
})
export class AIAssistantVisionComponent implements OnInit, OnDestroy {
  @Input() snapshot: ISnapshot;

  private subscription = new Subscription();

  videoSize = signal<IArea>({ width: 0, height: 0 });
  result = signal<string>(undefined);
  state = signal<IVisionSnapshotState>(undefined);

  isWaiting = computed(() =>
    ["snapshotRequested", "snapshotReady"].includes(this.state().state)
  );

  isAnalyzing = computed(() => this.state().state === "analysisRequested");

  constructor(
    private visionService: VisionService,
    private translate: TranslateService,
    private snapshotService: SnapshotService
  ) {}

  ngOnInit(): void {
    this.hydrate(this.visionService.getStateForSnapshot(this.snapshot.id));
    //
    this.subscription.add(
      this.visionService.imageStateChange$
        .pipe(filter((s) => s.id === this.snapshot.id))
        .subscribe((s) => this.hydrate(s))
    );
  }

  private hydrate(s: IVisionSnapshotState) {
    this.state.set(s);
    const meta = this.snapshotService.getSnapshotMetadata(s.id);
    if (meta.height && meta.width) {
      this.videoSize.set({ width: meta.width, height: meta.height });
    }
    if (s.state === "analysisReady") {
      this.result.set(s.result);
    } else if (s.state === "analysisError") {
      this.result.set(
        this.translate.instant("Could not analyze image. Please try again.")
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  snapshotReady(s: ISnapshot) {}

  snapshotError(s: ISnapshot) {}
}
