import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  IMenuItemContainer,
  MENU_ITEM_CONTAINER_TOKEN,
  MenuItemComponent,
} from "../../../../modules/shared/components";

@Component({
  selector: "app-share-link-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
  providers: [
    {
      provide: MENU_ITEM_CONTAINER_TOKEN,
      useExisting: ShareLinkScheduleComponent,
    },
  ],
})
export class ShareLinkScheduleComponent implements OnInit, IMenuItemContainer {
  @Output() request: EventEmitter<void> = new EventEmitter();

  @ViewChildren(MenuItemComponent) menuItems: QueryList<MenuItemComponent>;

  constructor() {}

  ngOnInit(): void {}

  getMenuItems(): MenuItemComponent[] {
    return this.menuItems.toArray();
  }

  callbackRequest() {
    this.request.emit();
  }
}
