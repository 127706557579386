import { Injectable } from "@angular/core";
import { filter, firstValueFrom, Observable, Subject } from "rxjs";
import {
  ActivityIndicatorService,
  AuviousRtcService,
  INotificationOptions,
  debugError,
} from "..";
import { MediaDevices } from "@auvious/media-tools";
import { IApplication } from "../models/IApplication";
import { INotificationStrategy } from "../models/strategies";
import { ApplicationService } from "./application.service";
import { NotificationSoundEnum, NotificationTypeEnum } from "../core-ui.enums";
import {
  FileTransferNotification,
  IEndpointMetadata,
  INotificationEvent,
  ToastNotification,
} from "../models";
import { BaseEvent } from "../models/IEvent";
import { IEndpoint } from "@auvious/rtc";
import { Event as AVEvent } from "@auvious/common";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

export class NudgeEvent extends BaseEvent {
  public static type = "NudgeSentEvent";
  constructor(public senderName: string) {
    super("NudgeSentEvent");
  }
}

@Injectable()
export class NotificationService implements INotificationStrategy {
  private notificationSoundsMap = {
    [NotificationSoundEnum.speakerTest]: "assets/sounds/notif_test.mp3",
    [NotificationSoundEnum.participantJoin]: "assets/sounds/notif_in.mp3",
    [NotificationSoundEnum.participantLeave]: "assets/sounds/notif_out.mp3",
    [NotificationSoundEnum.nudge]: "assets/sounds/nudge.mp3",
  };

  notificationReadySubject: Subject<INotificationEvent> = new Subject();
  notificationClearSubject: Subject<NotificationTypeEnum> = new Subject();
  notificationDismissSubject: Subject<INotificationEvent> = new Subject();

  constructor(
    private applicationService: ApplicationService,
    private rtc: AuviousRtcService,
    private activity: ActivityIndicatorService,
    private page: Title,
    private translate: TranslateService
  ) {
    firstValueFrom(this.rtc.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable
          .pipe(
            filter(
              ({ payload: ev }: AVEvent<NudgeEvent>) =>
                ev.type === "NudgeSentEvent"
            )
          )
          .subscribe(({ payload: ev }: AVEvent<NudgeEvent>) => {
            this.nudgeParticipant(ev.senderName);
          });
      }
    );
  }

  public get notificationReady$(): Observable<INotificationEvent> {
    return this.notificationReadySubject.asObservable();
  }

  public get notificationClearAll$(): Observable<NotificationTypeEnum> {
    return this.notificationClearSubject.asObservable();
  }

  public get notificationDismiss$(): Observable<INotificationEvent> {
    return this.notificationDismissSubject.asObservable();
  }

  private get application(): IApplication {
    return this.applicationService.getActiveApplication();
  }

  private get impl(): INotificationStrategy {
    return this.application.notificationStrategy();
  }

  show(title: string, options?: INotificationOptions) {
    this.impl.show(title, options);
  }

  public error(
    title: string,
    options?: INotificationOptions
  ): INotificationEvent {
    return this.sendNotification("danger", title, options);
  }

  public warn(
    title: string,
    options?: INotificationOptions
  ): INotificationEvent {
    return this.sendNotification("warning", title, options);
  }

  public info(
    title: string,
    options?: INotificationOptions
  ): INotificationEvent {
    return this.sendNotification("container", title, options);
  }

  public success(
    title: string,
    options?: INotificationOptions
  ): INotificationEvent {
    return this.sendNotification("success", title, options);
  }

  public nudge(senderName: string, target: IEndpoint<IEndpointMetadata>) {
    this.rtc.sendEventMessage(
      target.username,
      target.endpoint,
      new NudgeEvent(senderName)
    );
  }

  private nudgeParticipant(name) {
    this.activity.showIconMessage("hand-wave", name, 3, "wave");
    this.show("auvious", {
      body: this.translate.instant("A participant requires your attention"),
    });
    this.playSound(NotificationSoundEnum.nudge);
    const times = 3;
    let index = 0;
    const title = this.page.getTitle();
    const alert = this.translate.instant("!! Attention !!");
    this.page.setTitle(alert);
    const interval = setInterval(() => {
      if (index === times) {
        return clearInterval(interval);
      }
      this.page.setTitle(index % 2 === 0 ? title : alert);
      index++;
    }, 1000);
  }

  public fileReceived(notification: FileTransferNotification) {
    this.notificationReadySubject.next(notification);
  }

  public notify(notification: INotificationEvent) {
    this.notificationReadySubject.next(notification);
  }

  public dismiss(notification: INotificationEvent) {
    this.notificationDismissSubject.next(notification);
  }

  public dismissAll(type?: NotificationTypeEnum) {
    this.notificationClearSubject.next(type);
  }

  public async playSound(type: NotificationSoundEnum) {
    const url = this.notificationSoundsMap[type];
    if (!url) {
      return;
    }

    const sound = new Audio(url);
    // @ts-expect-error
    MediaDevices.syncSpeaker(sound);

    sound.onended = this.releaseSpeaker;
    sound.onerror = this.releaseSpeaker;

    try {
      await sound.play();
    } catch (ex) {
      debugError(ex);
    }
  }

  private releaseSpeaker = (ev: Event) => {
    (ev.target as HTMLAudioElement).onended = undefined;
    (ev.target as HTMLAudioElement).onerror = undefined;
    // @ts-expect-error
    MediaDevices.desyncSpeaker(ev.target as HTMLAudioElement);
  };

  private sendNotification(
    type: "danger" | "warning" | "info" | "success" | "container",
    title,
    options?: INotificationOptions
  ): INotificationEvent {
    options = {
      ...options,
      ttl: options?.ttl ?? 5000,
      color: type,
    };

    const notif = new ToastNotification(title, options);
    this.notificationReadySubject.next(notif);
    return notif;
  }
}
