import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { Subscription } from "rxjs";
import { IInteraction } from "../../models";
import { MediaRulesService, RatingService, ThemeService } from "../../services";
import { slideInOut } from "../../core-ui.animations";
import { RatingItem, RatingRequest } from "@auvious/rating";
@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"],
  animations: [slideInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent implements OnInit, OnDestroy {
  @Input()
  set interaction(value: IInteraction) {
    this._interaction = value;
    if (value.getRoom()) {
      // room is removed from interaction in conference component
      this.conferenceId = value.getRoom();
    }
  }

  get interaction(): IInteraction {
    return this._interaction;
  }

  feedback: RatingRequest;
  hoverRating = 0;
  points = [1, 2, 3, 4, 5];
  maxChars = 500;
  conferenceId: string;
  _interaction: IInteraction;

  isRated = signal(false);
  isDarkMode = signal(false);
  isSurveyOpen = signal(false);
  visible = signal(false);
  subscriptions: Subscription;

  constructor(
    private rateService: RatingService,
    private themeService: ThemeService,
    private mediaRules: MediaRulesService
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.rateService.popupVisibility$.subscribe((visible) => {
        this.reset();
        this.visible.set(visible);
        if (visible) {
          if (this.isFeedbackAvailable()) {
            (
              document.querySelector(
                "[data-tid='rating/skip']"
              ) as HTMLButtonElement
            )?.focus();
          } else if (this.isSurveyAvailable()) {
            this.isSurveyOpen.set(true);
          }
        }
      })
    );
    this.subscriptions.add(
      this.themeService.themeChanged$().subscribe((theme) => {
        this.isDarkMode.set(theme.darkMode);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  reset() {
    this.feedback = {
      sessionId: this.conferenceId,
      interactionId: this.interaction.getId(),
      sessionType: "CONFERENCE",
      rating: 0,
      comment: "",
      feedback: [],
    };
    this.isRated.set(false);
  }

  get isLowRating() {
    return this.feedback.rating < 5 && this.feedback.rating > 0;
  }

  isActive(point) {
    return this.hoverRating >= point || this.feedback.rating >= point;
  }

  rate(point) {
    this.feedback.rating = point;
    if (point === 5) {
      this.next();
    }
  }

  empty() {
    this.hoverRating = 0;
  }

  fill(point) {
    this.hoverRating = point;
  }

  next() {
    if (this.isSurveyAvailable()) {
      this.isSurveyOpen.set(true);
    } else {
      this.send();
    }
  }

  send() {
    this.rateService.rate(this.feedback);
    this.isRated.set(true);

    setTimeout(() => {
      this.rateService.hidePopup();
    }, 1500);
  }

  surveyCompleted(answers: RatingItem[]) {
    this.feedback.feedback = answers;
    this.send();
  }

  surveySkipped() {
    if (
      this.feedback.feedback?.length > 0 ||
      this.feedback.comment ||
      this.feedback.rating
    ) {
      this.send();
    } else {
      this.rateService.hidePopup();
    }
  }

  cancel() {
    if (this.isSurveyAvailable()) {
      this.isSurveyOpen.set(true);
    } else {
      this.rateService.hidePopup();
    }
  }

  isFeedbackAvailable() {
    return this.mediaRules.isFeedbackAvailable();
  }

  isSurveyAvailable() {
    return this.mediaRules.isSurveyAvailableForInteraction(this.interaction);
  }
}
