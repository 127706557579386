<div *ngIf="isTypeStar" class="stars" role="radiogroup">
  <div *ngFor="let point of points">
    <button
      role="radio"
      (mouseenter)="fill(point)"
      (mouseleave)="empty()"
      (click)="rate(point)"
      [class.star-rate]="isActive(point)"
      [class.star-dark]="isDarkMode()"
      [attr.aria-checked]="item().rating === point"
      class="star"
      [attr.data-tid]="'survey/answer/score/' + point"
    ></button>
  </div>
</div>

<div class="fx-column fx-gap-xs" *ngIf="!isTypeStar">
  <div class="fx-row fx-align-center fx-justify-space-between">
    <small class="text-muted" translate>Not likely</small>
    <small class="text-muted" translate>Very likely</small>
  </div>
  <div class="radios" role="radiogroup">
    <label
      [class.active]="item().rating === point"
      *ngFor="let point of points"
    >
      <input
        required
        type="radio"
        name="{{ question.id }}"
        [value]="i"
        (change)="rate(point)"
        [attr.data-tid]="'survey/answer/score/' + point"
      />
      <span>{{ point }}</span>
    </label>
  </div>
</div>

<div class="form-error" *ngIf="isError()" translate>Required</div>
