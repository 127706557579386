<button
  [attr.aria-label]="(open() ? 'Close' : 'Open') + ' menu' | translate"
  *ngIf="isVisible"
  [@slideFromLeft]="true"
  class="trigger"
  avButton
  color="link"
  ofType="icon"
  size="sm"
  [disabled]="isToggleDisabled"
  (click)="toggle($event)"
>
  <av-icon *ngIf="open()" [mode]="toggleColorMode" name="close-big"></av-icon>
  <av-icon *ngIf="!open()" name="menu"></av-icon>
</button>

<av-card
  (click)="cancel($event)"
  *ngIf="isVisible"
  class="user-menu-card"
  [class.open]="open()"
  [attr.inert]="!open() || null"
>
  <av-card-body
    type="container"
    class="fx-column fx-align-stretch fx-justify-space-between"
  >
    <av-menu class="user-menu" #menu>
      <av-menu-item
        [attr.aria-label]="'Go home' | translate"
        [active]="active('welcome')"
        icon="home"
        (select)="go('welcome')"
      >
        {{ "Home" | translate }}
      </av-menu-item>
      <av-menu-item
        [attr.aria-label]="'Go to appointments' | translate"
        *ngIf="isScheduleEnabled"
        [active]="active('schedule')"
        icon="calendar-time-thin"
        (select)="go('schedule')"
      >
        {{ "Appointments" | translate }}
      </av-menu-item>
      <av-menu-item
        [attr.aria-label]="'Go to interactions' | translate"
        *ngIf="isInteractionsEnabled"
        [active]="active('interaction')"
        icon="archive"
        (select)="go('interaction')"
      >
        {{ "Interactions" | translate }}
      </av-menu-item>
      <av-menu-item
        [aria-label]="'Go to activity' | translate"
        *ngIf="isActivityEnabled()"
        [active]="active('activity')"
        icon="binoculars"
        (select)="go('activity')"
      >
        {{ "Activity" | translate }}
      </av-menu-item>
      <av-menu-item
        *ngIf="isMetricsEnabled"
        [active]="active('reports')"
        icon="bar-chart"
        (select)="go('reports')"
        [attr.aria-label]="'Go to reports' | translate"
      >
        {{ "Reports" | translate }}
      </av-menu-item>
      <av-menu-item
        *ngIf="isRecordingEnabled"
        [active]="active('exports')"
        icon="record"
        (select)="go('exports')"
        [attr.aria-label]="'Go to exported recordings' | translate"
      >
        {{ "My exports" | translate }}
      </av-menu-item>
      <av-menu-item
        *ngIf="isAuditEnabled"
        [active]="active('audit')"
        icon="notepad"
        (select)="go('audit')"
        [attr.aria-label]="'Go to audit logs' | translate"
      >
        {{ "Audit" | translate }}
      </av-menu-item>
      <av-menu-item
        *ngIf="isConfigEnabled"
        [active]="active('settings')"
        icon="settings"
        (select)="go('settings')"
        [attr.aria-label]="'Go to settings' | translate"
      >
        {{ "Settings" | translate }}
      </av-menu-item>
      <av-menu-item
        [attr.aria-label]="'Go to help center' | translate"
        *ngIf="isHelpOn"
        icon="help"
        (select)="help()"
      >
        {{ "Help Center" | translate }}
      </av-menu-item>
    </av-menu>

    <div>
      <av-menu-item type="separator"></av-menu-item>
      <div class="user fx-row fx-align-center">
        <div class="user-avatar">
          <img
            aria-hidden="true"
            *ngIf="!avatarError"
            (error)="avatarError = true"
            [src]="avatar"
          />
          <av-icon *ngIf="avatarError" mode="light" name="user"></av-icon>
        </div>
        <div class="fx-column">
          <b *ngIf="displayName">{{ displayName }}</b>
          <div
            class="fx-row fx-wrap fx-align-center"
            (click)="toggleId($event)"
          >
            <ng-container *ngIf="roles">
              <small
                [attr.aria-label]="('User role ' | translate) + r"
                class="text-muted"
                *ngFor="let r of roles; index as i"
              >
                <span role="presentation" translate>{{ r }}</span
                ><span role="presentation" *ngIf="i < roles.length - 1"
                  >,&nbsp;</span
                >
              </small>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="idOn">
        <small class="text-muted user-id">{{ id }}</small>
      </ng-container>
    </div>
  </av-card-body>
</av-card>
