import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Subscription } from "rxjs";

import { IMediaFilter } from "../../models";
import { debugError, MediaEffectsService } from "../../services";
import { MediaFilterTypeEnum } from "../../../core-ui/core-ui.enums";
import { BackgroundEffectComponent } from "../background-effect/background-effect.component";

@Component({
  selector: "app-background-effects",
  templateUrl: "./background-effects.component.html",
  styleUrls: ["./background-effects.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundEffectsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  filters: IMediaFilter[] = [];

  activeFilter: IMediaFilter;
  subscription: Subscription = new Subscription();

  @ViewChildren("effectRef") effectsRef!: QueryList<BackgroundEffectComponent>;

  @Output() keyArrowLeft = new EventEmitter();

  columns = 4;

  constructor(
    private effects: MediaEffectsService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private host: ElementRef
  ) {}

  async ngOnInit() {
    this.subscription.add(
      this.effects.filterTypeChanged$.subscribe((filter) => {
        this.activeFilter = filter;
        this.cd.detectChanges();
      })
    );

    this.subscription.add(
      this.effects.filtersUpdated$.subscribe((filters) => {
        this.filters = filters;
        this.cd.detectChanges();
      })
    );
  }

  ngAfterViewInit(): void {
    try {
      // if the tile is too small, the volume indicator oveflows. Listen for resizes and change class accordingly
      this.zone.runOutsideAngular(() => {
        const ro = new ResizeObserver((entries) => {
          const entry = entries?.pop();
          const containerSize = entry?.contentRect;
          const effectSize: DOMRect = this.effectsRef
            ?.toArray()?.[0]
            .host.nativeElement.getBoundingClientRect();

          if (effectSize.width)
            this.columns = Math.floor(containerSize.width / effectSize.width);
        });
        ro.observe(this.host.nativeElement);
      });
    } catch (ex) {
      debugError(ex);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    const currentElement = document.activeElement as HTMLElement;
    const currentIndex = +currentElement.getAttribute("data-index")!;
    const totalItems = this.effectsRef.length;

    let nextIndex: number | null = null;
    switch (event.key) {
      case "ArrowRight":
        nextIndex = (currentIndex + 1) % totalItems;
        break;
      case "ArrowLeft":
        if (currentIndex === 0 && this.keyArrowLeft.observed) {
          // navigate out of effects
          this.keyArrowLeft.emit();
        } else {
          nextIndex = (currentIndex - 1 + totalItems) % totalItems;
        }
        break;
      case "ArrowDown":
        nextIndex = (currentIndex + this.columns) % totalItems;
        break;
      case "ArrowUp":
        nextIndex = (currentIndex - this.columns + totalItems) % totalItems;
        break;
      default:
        break;
    }

    if (nextIndex !== null) {
      event.preventDefault(); // Prevent default scrolling
      event.stopPropagation();
      this.effectsRef.toArray()[nextIndex]?.host.nativeElement.focus();
    }
  }

  navigateIn() {
    this.effectsRef?.toArray?.()[0]?.host.nativeElement.focus();
  }

  isActive(filter: IMediaFilter) {
    return (
      filter.id === this.activeFilter?.id ||
      (!this.activeFilter && filter.type === MediaFilterTypeEnum.none)
    );
  }

  effectError(effect: IMediaFilter) {
    // if the effect didn't load, remove it from the list
    this.filters = this.filters.filter((f) => f.id !== effect.id);

    this.cd.detectChanges();
  }

  trackByFn(index: number, el: IMediaFilter) {
    return el.id;
  }
}
