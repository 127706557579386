import { Pipe, PipeTransform } from "@angular/core";
import { marked } from "marked";

@Pipe({
  name: "markdown",
})
export class MarkdownPipe implements PipeTransform {
  transform(markdown: string) {
    return marked.parse(markdown); // Convert Markdown to HTML
  }
}
