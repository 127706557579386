<ng-container *ngFor="let filter of filters; let i = index; trackBy: trackByFn">
  <app-background-effect
    tabindex="0"
    role="button"
    [effect]="filter"
    [active]="isActive(filter)"
    [activeEffect]="activeFilter"
    (error)="effectError($event)"
    [attr.data-index]="i"
    #effectRef
    data-tid="background-effects/select-effect"
  ></app-background-effect>
</ng-container>
<av-spinner *ngIf="filters.length === 0" size="md"></av-spinner>
