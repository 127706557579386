<av-autocomplete
  [data]="data"
  [initialValue]="currentLocale"
  [searchKeyword]="'name'"
  [notFoundText]="notFoundText"
  [placeholder]="placeholderText"
  [itemTemplate]="itemTemplate"
  [notFoundTemplate]="notFoundTemplate"
  (selected)="selected($event)"
  (inputChanged)="inputChanged($event)"
>
</av-autocomplete>

<ng-template #itemTemplate let-item>
  <av-menu-item class="menu-item" [innerHTML]="item.name"></av-menu-item>
</ng-template>
<ng-template #notFoundTemplate let-notFound>
  <av-menu-item class="menu-item" [innerHTML]="notFoundText"></av-menu-item>
</ng-template>
