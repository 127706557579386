<ng-container *ngIf="!!snapshot">
  <app-snapshot
    [size]="videoSize()"
    [snapshot]="snapshot"
    [class.snapshot-loading]="isWaiting() || isAnalyzing()"
    (ready)="snapshotReady($event)"
    (error)="snapshotError($event)"
  ></app-snapshot>
</ng-container>

<div
  class="fx-row fx-gap=10 vision-loading"
  *ngIf="isWaiting() || isAnalyzing()"
>
  <av-spinner size="xs"></av-spinner>
  <div *ngIf="isWaiting()" translate>Acquiring snapshot...</div>
  <div *ngIf="isAnalyzing()" translate>Analyzing...</div>
</div>

<ng-container *ngIf="!!result()">
  <div class="vision-message" [innerHTML]="result() | markdown"></div>
  <div class="vision-disclaimer">
    &centerdot; <span translate>Check important info for mistakes</span>
  </div>
</ng-container>
