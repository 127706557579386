import {
  Directive,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ElementRef,
} from "@angular/core";
import { validateFileExtensions } from "../../../core-ui/services/utils";

@Directive({
  selector: "[avFileDragDrop]",
})
export class FileDragDropDirective {
  @Input() avFileDragDrop: { enabled: boolean };
  @Input() avFileAllowedExtensions: string[];

  @Output() filesDropped: EventEmitter<File[]> = new EventEmitter();

  constructor(private host: ElementRef<HTMLDivElement>) {}

  @HostListener("dragover", ["$event"]) onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.avFileDragDrop.enabled) {
      this.host.nativeElement.classList.add("av-drag-over");
    }
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.avFileDragDrop.enabled) {
      this.host.nativeElement.classList.remove("av-drag-over");
    }
  }

  @HostListener("drop", ["$event"]) onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.avFileDragDrop.enabled) {
      this.host.nativeElement.classList.remove("av-drag-over");

      let files: FileList = event.dataTransfer.files;
      if (
        files.length > 0 &&
        validateFileExtensions(files, this.avFileAllowedExtensions)
      ) {
        // add any validation required
        this.filesDropped.emit(Array.from(files));
      }
    }
  }
}
