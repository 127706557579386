<app-survey-option
  *ngFor="let o of question.options"
  [option]="o"
  [active]="isActive(o)"
>
  <input
    required
    type="radio"
    name="{{ question.id }}"
    [ngModel]="item().choice"
    [value]="o.score || o.body"
    (ngModelChange)="optionChange($event)"
  />
</app-survey-option>
<div class="form-error" *ngIf="isError()" translate>Required</div>
