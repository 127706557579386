<av-tooltip
  message="Chat"
  position="right"
  [enabled]="!isChatOpen"
  *ngIf="isStarted"
  [@slideFromLeft]="true"
>
  <button
    avButton
    ofType="icon"
    size="sm"
    [color]="isChatOpen ? 'basic' : 'primary'"
    id="btn-chat-toggle"
    (click)="toggleChat()"
    data-tid="conversation-launcher/toggle-chat"
    [attr.aria-label]="'Chat' | translate"
  >
    <av-icon name="chat-bubble" *ngIf="!isChatOpen || isWidget"></av-icon>
    <av-icon name="close-big" *ngIf="!isWidget && isChatOpen"></av-icon>
  </button>
</av-tooltip>

<app-conversation
  [@slideFromLeft]="true"
  *ngIf="!isWidget && isChatOpen"
  class="conversation"
></app-conversation>
