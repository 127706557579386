import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  signal,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ISurveyOption, ISurveyQuestion } from "../../../models";
import { RatingSingleChoiceItem } from "@auvious/rating";
import { ISurveyQuestionForm } from "../survey.component";

@Component({
  selector: "app-survey-option-single",
  templateUrl: "./option-single.component.html",
  styleUrls: ["./option-single.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyOptionSingleComponent
  implements OnInit, ISurveyQuestionForm
{
  @Input() question: ISurveyQuestion;

  @Output() changed = new EventEmitter<RatingSingleChoiceItem>();

  item = signal<RatingSingleChoiceItem>(undefined);

  isError = signal(false);

  ngOnInit(): void {
    this.item.set({
      ...this.question,
      choice: "",
    });
  }

  optionChange(value) {
    this.isError.set(false);
    this.item.update((item) => ({ ...item, choice: String(value) }));
    this.changed.emit(this.item());
  }

  isActive(option: ISurveyOption) {
    return !!option.score
      ? this.item().choice === option.score
      : this.item().choice === option.body;
  }

  isValid() {
    return !!this.item().choice;
  }

  markAsTouched() {
    this.isError.set(true);
  }
}
