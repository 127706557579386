import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  signal,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ISurveyOption, ISurveyQuestion } from "../../../models";
import { RatingMultiChoiceItem } from "@auvious/rating";
import { ISurveyQuestionForm } from "../survey.component";

interface ISurveyMultiOption extends ISurveyOption {
  value: string;
}

@Component({
  selector: "app-survey-option-multi",
  templateUrl: "./option-multi.component.html",
  styleUrls: ["./option-multi.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyOptionMultiComponent implements OnInit, ISurveyQuestionForm {
  @Input() question: ISurveyQuestion;

  @Output() changed = new EventEmitter<RatingMultiChoiceItem>();

  item = signal<RatingMultiChoiceItem>(undefined);
  options: ISurveyMultiOption[];
  isError = signal(false);

  ngOnInit(): void {
    this.item.set({
      ...this.question,
      choices: [],
    });
    this.options = this.question.options.map((o) => ({ ...o, value: null }));
  }

  optionChange(checked: string, option: ISurveyMultiOption) {
    const value = !!option.score ? String(option.score) : option.body;
    this.isError.set(false);
    if (checked) {
      this.item.update((item) => ({
        ...item,
        choices: [...item.choices, value],
      }));
    } else {
      this.item.update((item) => ({
        ...item,
        choices: item.choices.filter((c) => c !== value),
      }));
    }

    this.changed.emit(this.item());
  }

  isActive(option: ISurveyOption) {
    return !!option.score
      ? this.item().choices.includes(option.score)
      : this.item().choices.includes(option.body);
  }

  isValid() {
    return this.item().choices.length > 0;
  }

  markAsTouched() {
    this.isError.set(true);
  }
}
