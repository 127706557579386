import { AbstractBaseInvitationStrategy } from "../AbstractBaseInvitationStrategy";
import {
  IApplicationAgentParams,
  IInteraction,
} from "../../../../core-ui/models";
import { IApplicationPublicParams } from "../../../../core-ui/models/application-options/PublicOptions";
import {
  PARAM_CONVERSATION_ID,
  PARAM_CONVERSATION_ORIGIN,
} from "../../../../app/app.enums";

export class NiceInvitationStrategy extends AbstractBaseInvitationStrategy {
  constructor(
    protected applicationId: string,
    protected publicParams: IApplicationPublicParams,
    protected agentOptions: IApplicationAgentParams
  ) {
    super(applicationId, publicParams, agentOptions);
  }

  customParams(interaction: IInteraction): URLSearchParams {
    const params = new URLSearchParams();
    params.set(PARAM_CONVERSATION_ID, interaction.getId());
    params.set(PARAM_CONVERSATION_ORIGIN, interaction.getOrigin());
    return params;
  }
}
