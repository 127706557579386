import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  AppConfigService,
  WindowEventService,
  CustomerParam,
  DeviceService,
  ConsentRequestAction,
  ConsentResponseAction,
  IInteraction,
} from "../../../core-ui";

@Component({
  selector: "app-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["./disclaimer.component.scss"],
})
export class DisclaimerComponent implements OnInit, AfterViewInit {
  @Input() interaction: IInteraction;

  text: string;

  @Output() accepted: EventEmitter<void> = new EventEmitter();
  @Output() rejected: EventEmitter<void> = new EventEmitter();

  @ViewChild("acceptRef", { read: ElementRef })
  acceptRef: ElementRef<HTMLButtonElement>;

  constructor(
    private config: AppConfigService,
    private windowService: WindowEventService
  ) {}

  ngOnInit(): void {
    this.text = this.config.customerParam(CustomerParam.DISCLAIMER_TEXT);
    this.windowService.init(this.interaction.getParentFrameUrl());
    if (DeviceService.isIFrame) {
      this.windowService.sendMessage(new ConsentRequestAction());
    }
  }
  ngAfterViewInit(): void {
    this.acceptRef.nativeElement.focus();
  }

  exit() {
    if (DeviceService.isIFrame) {
      this.windowService.sendMessage(new ConsentResponseAction(false));
    }
    this.rejected.emit();
  }

  accept() {
    if (DeviceService.isIFrame) {
      this.windowService.sendMessage(new ConsentResponseAction(true));
    }
    this.accepted.emit();
  }
}
