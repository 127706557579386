import { AbstractBaseInvitationStrategy } from "./AbstractBaseInvitationStrategy";

import { IApplicationAgentParams, IInteraction } from "../../../core-ui/models";
import { IApplicationPublicParams } from "../../../core-ui/models/application-options/PublicOptions";

export class StandaloneInvitationStrategy extends AbstractBaseInvitationStrategy {
  constructor(
    protected applicationId: string,
    protected publicParams: IApplicationPublicParams,
    protected agentParams: IApplicationAgentParams
  ) {
    super(applicationId, publicParams, agentParams);
  }

  customParams(interaction: IInteraction): URLSearchParams {
    return new URLSearchParams();
  }
}
