import {
  Component,
  OnInit,
  Optional,
  HostBinding,
  ElementRef,
} from "@angular/core";
import { CardComponent } from "../card/card.component";
import { CardFooterComponent } from "../card-footer/card-footer.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[avInput]",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnInit {
  constructor(
    public host: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
    @Optional() private card?: CardComponent,
    @Optional() private cardFooter?: CardFooterComponent
  ) {}

  @HostBinding("class") get class() {
    return {
      "av-input-card": this.isInCard
    };
  }

  ngOnInit(): void {}

  get nativeElement(): HTMLInputElement | HTMLTextAreaElement {
    return this.host.nativeElement;
  }

  get isInCard() {
    return (
      (this.card && this.card?.type === "container") ||
      (this.cardFooter && this.cardFooter?.type === "container")
    );
  }
}
