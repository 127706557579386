import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  signal,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { ISurveyQuestion } from "../../../models";
import { RatingTextItem } from "@auvious/rating";
import { ISurveyQuestionForm } from "../survey.component";
import { NgForm } from "@angular/forms";
import { markFormFields } from "../../../../app/app.utils";

@Component({
  selector: "app-survey-text",
  templateUrl: "./text.component.html",
  styleUrls: ["./text.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyTextComponent implements OnInit, ISurveyQuestionForm {
  @Input() question: ISurveyQuestion;

  @Output() changed = new EventEmitter<RatingTextItem>();

  @ViewChild("f") formRef: NgForm;

  item = signal<RatingTextItem>(undefined);
  maxLength: number;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.maxLength = this.question.type === "TEXT_300" ? 300 : 1000;
    this.item.set({
      ...this.question,
      answer: "",
    });
  }

  update(value: string) {
    this.item.update((item) => ({ ...item, answer: value }));
    this.changed.emit(this.item());
  }

  isValid() {
    return this.formRef.form.valid;
  }

  markAsTouched() {
    markFormFields(this.formRef.form);
    this.cd.detectChanges();
  }
}
