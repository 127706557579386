import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterContentInit,
  AfterViewInit,
} from "@angular/core";
import { NotificationService } from "../../../../core-ui/services/notification.service";
import { copyTextToClipboard } from "../../../../core-ui/services";

@Component({
  selector: "av-copy-clipboard",
  templateUrl: "./copy-clipboard.component.html",
  styleUrls: ["./copy-clipboard.component.scss"],
})
export class CopyClipboardComponent implements OnInit {
  @Input() url: string;
  @Input() tooltipLabel = "Copy download link";
  @Input() tooltipPosition: "top" | "bottom" | "left" | "right" = "top";
  @Input() size: "xs" | "sm" | "xxs" | "md" = "sm";
  @Input() tid: string;

  @ViewChild("btnRef", { read: ElementRef })
  buttonRef: ElementRef<HTMLButtonElement>;

  constructor(private notification: NotificationService) {}

  shouldFocus = false;

  ngOnInit(): void {}

  copy() {
    copyTextToClipboard(this.url);
    this.notification.info("Copied to clipboard");
  }

  focus() {
    this.buttonRef.nativeElement.focus();
  }
}
