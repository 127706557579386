<ng-container *ngIf="!!url">
  <av-tooltip
    class="tag-tooltip"
    position="right"
    [message]="tagLabel"
    *ngIf="hasTag"
  >
    <div
      class="tag"
      data-tid="snapshot/tag"
      [ngStyle]="{ 'background-color': tagColor }"
    ></div>
  </av-tooltip>

  <img
    #snap
    [src]="url"
    [class.hidden]="failed()"
    (error)="imageError()"
    (load)="imageLoaded()"
    alt="{{ 'snapshot' | translate }}"
    crossOrigin="anonymous"
  />

  <button
    [attr.aria-label]="'Remove snapshot' | translate"
    class="remove"
    *ngIf="active"
    avButton
    color="basic"
    size="xxs"
    ofType="icon"
    (click)="remove()"
    data-tid="snapshot/remove"
  >
    <av-icon class="trash" name="trash"></av-icon>
  </button>
</ng-container>

<av-spinner class="loader" *ngIf="isLoading()" size="xs"></av-spinner>

<div
  [attr.aria-label]="'Approved snapshot' | translate"
  class="notification notification-success hidden"
  *ngIf="isApproved"
>
  <av-icon data-tid="snapshot/approved" name="tick-round"></av-icon>
</div>

<div
  [attr.aria-label]="'Snapshot failed to load' | translate"
  class="notification notification-error"
  *ngIf="failed()"
>
  <av-tooltip message="Snapshot failed to load">
    <av-icon mode="light" name="alert" size="xs"></av-icon>
  </av-tooltip>
</div>
