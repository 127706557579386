import {
  ChangeDetectionStrategy,
  Component,
  computed,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { AssistantService } from "../../../core-ui/services";
import { filter, Subscription } from "rxjs";
import { slideFromRight } from "../../../core-ui/core-ui.animations";
import { VisionService } from "../../../core-ui/services/vision.service";
import { ISnapshot, SnapshotService } from "@auvious/snapshot";

@Component({
  selector: "av-ai-assistant",
  templateUrl: "./ai-assistant.component.html",
  styleUrls: ["./ai-assistant.component.scss"],
  animations: [slideFromRight],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIAssistantComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  isAssistantOpen = signal(false);

  snapshots = signal<ISnapshot[]>([]);

  constructor(
    private assistant: AssistantService,
    private vision: VisionService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.vision.imageChange$.subscribe((i) =>
        this.snapshots.update((s) => s.map((m) => (m.id === i.id ? i : m)))
      )
    );
    this.subscriptions.add(
      this.vision.imagesAvailable$.subscribe((s) => this.snapshots.set(s))
    );
    this.subscriptions.add(
      this.assistant.openChange$.subscribe((o) => this.isAssistantOpen.set(o))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  dismiss() {
    this.assistant.toggleOpen(false);
  }

  trackByFn(index: number, el: ISnapshot) {
    return el.id;
  }
}
