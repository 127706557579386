import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
  ContentChild,
  ElementRef,
  AfterContentInit,
  OnDestroy,
  signal,
} from "@angular/core";

@Component({
  selector: "av-control-row",
  templateUrl: "./control-row.component.html",
  styleUrls: ["./control-row.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlRowComponent implements AfterContentInit, OnDestroy {
  @Input() type: "switch" | "text" | "button" | "card" | "radio";
  @Input() label: string;
  @Input() help: string;
  @Input() inline: boolean;
  @Input() inlineFlex: number;

  @HostBinding("class") get class() {
    return {
      ["type-" + this.type]: true,
      inline: this.inline,
      ["inline-flex-" + this.inlineFlex]: this.inline && this.inlineFlex,
    };
  }

  @ContentChild("[row-label]", { read: ElementRef }) rowLabel?: ElementRef;

  private observer?: MutationObserver;
  protected hasLabelProjected = signal(false);

  constructor() {}

  ngAfterContentInit(): void {
    // Observe content changes
    if (!this.rowLabel) return;
    this.observer = new MutationObserver(() => this.checkContent());
    this.observer.observe(this.rowLabel.nativeElement, {
      childList: true,
      subtree: true,
      characterData: true,
    });
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }

  private checkContent() {
    this.hasLabelProjected.set(
      this.rowLabel?.nativeElement.innerHTML.trim().length > 0
    );
  }

  get isHelpProjected() {
    return !["card", "button"].includes(this.type);
  }
}
