<!-- <av-tooltip [message]="tooltip" [enabled]="!isOpen" [position]="tooltipPosition"> -->
<!-- <div class="bitrate-trigger" (click)="isOpen=!isOpen">
        <span>{{activeQuality.short}}</span>
    </div> -->
<av-menu-item
  tabindex="0"
  icon="settings"
  [disabled]="isDisabled"
  [autoSubMenuPosition]="true"
  [arrow]="true"
  #menuItemRef
>
  <span translate>{{ tooltip }}</span>
  <!-- *ngIf="isOpen"  arrow="top" [hAlign]="position" -->
  <av-card sub-menu [backdrop]="false" class="bitrate-options" [menu]="true">
    <av-card-body size="xs">
      <av-menu [submenu]="true">
        <av-menu-item
          tabindex="0"
          *ngFor="let q of qualities"
          (select)="bitrateChange(q)"
          [postfix]="q.label"
          type="radio"
          [checked]="activeQuality.value === q.value"
          data-tid="tile-controls/change-bitrate"
        >
          <span translate>{{ q.long }}</span>
        </av-menu-item>
      </av-menu>
    </av-card-body>
  </av-card>
</av-menu-item>
<!-- </av-tooltip> -->
