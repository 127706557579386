import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { captureURLImage } from "@auvious/media-tools";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import {
  ConferenceService,
  debug,
  debugError,
  StreamState,
} from "../../services";
import { PointerService } from "../../services/pointer.service";

@Component({
  selector: "app-freeze-frame",
  templateUrl: "./freeze-frame.component.html",
  styleUrls: ["./freeze-frame.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreezeFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("streamImg", { static: true })
  streamFrame: ElementRef<HTMLImageElement>;
  @ViewChild("testCanvas", { static: true })
  blackTestCanvas: ElementRef<HTMLCanvasElement>;

  @Input() stream: StreamState;
  @Input() mirror: boolean;
  @Input() blackCheck = false;
  @Input() blur = true;
  @Input()
  set render(value: boolean) {
    if (value) {
      this.extractFrame();
    }
  }

  // imageRef: HTMLImageElement;
  isZoomOn = false;
  subscription: Subscription = new Subscription();

  private autoExtractEnabled = false;

  constructor(
    private conferenceService: ConferenceService,
    private point: PointerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.point.zoomFrameChange$
        .pipe(
          filter(
            (f) =>
              f.target.endpoint === this.stream.originator.endpoint &&
              f.correlationId === this.stream.correlationId
          )
        )
        .subscribe((f) => {
          this.isZoomOn = f.on;
          this.cdr.detectChanges();
        })
    );
  }

  ngAfterViewInit() {
    this.autoExtractFrame();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  extractFrame() {
    try {
      const videoElm = this.conferenceService.getElementById(
        this.stream?.id
      ) as HTMLVideoElement;

      if (
        videoElm &&
        videoElm.videoWidth &&
        videoElm.videoHeight &&
        !(this.blackCheck && this.isBlack(videoElm))
      ) {
        this.streamFrame.nativeElement.setAttribute(
          "src",
          captureURLImage(videoElm, { mirror: this.mirror })
        );

        this.cdr.markForCheck();
        debug("frame extracted");
      }
    } catch (ex) {
      debugError(ex);
    }
  }

  autoExtractFrame() {
    const videoElm = this.conferenceService.getElementById(
      this.stream?.id
    ) as HTMLVideoElement;

    if (!this.autoExtractEnabled && videoElm) {
      videoElm.addEventListener("playing", () => {
        this.extractFrame();
      });

      this.autoExtractEnabled = true;
    }
  }

  /**
   * Draw the frame in a 1x1 canvas and check the rgb value.
   */
  isBlack(img) {
    try {
      const ctx = this.blackTestCanvas.nativeElement.getContext("2d");
      this.blackTestCanvas.nativeElement.width =
        this.blackTestCanvas.nativeElement.height = 1;
      ctx.drawImage(img, 0, 0, 1, 1);
      const result = ctx.getImageData(0, 0, 1, 1);
      return result.data[0] + result.data[1] + result.data[2] < 30;
    } catch (ex) {
      debugError(ex);
    }
  }
}
