import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import {
  RatingService as AVRatingService,
  RatingRequest,
  Rating,
} from "@auvious/rating";

@Injectable()
export class RatingService {
  private sessionId: string;
  private popupSubject: Subject<boolean>;
  private resolver: any;

  constructor(private avRating: AVRatingService) {
    this.popupSubject = new Subject<boolean>();
  }

  public get popupVisibility$(): Observable<boolean> {
    return this.popupSubject.asObservable();
  }

  public showPopup(conferenceId): Promise<void> {
    return new Promise((resolve) => {
      this.resolver = resolve;
      this.sessionId = conferenceId;
      this.popupSubject.next(true);
    });
  }

  public hidePopup() {
    if (!!this.resolver) {
      this.resolver();
    }
    this.popupSubject.next(false);
  }

  public rate(request: RatingRequest): Promise<string> {
    return this.avRating.create({
      ...request,
      comment: request.comment === "" ? undefined : request.comment,
    });
  }

  public getRatingForInteractionId(id: string): Promise<Rating[]> {
    return this.avRating.findByInteractionId(id);
  }
  public getRatingForConferenceId(id: string): Promise<Rating[]> {
    return this.avRating.findByConferenceId(id);
  }
}
