import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
  IOpenAITranscriptMessage,
  IOpenAITranslationMessage,
  ITranscriptMessage,
} from "../../../../core-ui";

@Component({
  selector: "app-composition-transcript-content",
  templateUrl: "./composition-transcript-content.component.html",
  styleUrls: ["./composition-transcript-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositionTranscriptContentComponent {
  @Input() data:
    | ITranscriptMessage[]
    | IOpenAITranslationMessage[]
    | IOpenAITranscriptMessage[];

  trackByFn(index, el: IOpenAITranslationMessage) {
    return el.startedAt;
  }

  isNewSender(message: IOpenAITranslationMessage, index: number) {
    if (index === 0) {
      return true;
    }
    const prevMsg = this.data[index - 1] as IOpenAITranslationMessage;
    return prevMsg.name !== message.name;
  }
}
