<av-card
  class="callback"
  [class.callback-call]="inCall"
  [backdrop]="hover"
  [popup]="hover"
  vAlign="top"
>
  <av-card-header (close)="cancel()">{{
    "Schedule a video call" | translate
  }}</av-card-header>

  <form #f="ngForm">
    <av-card-body>
      <ng-container *ngIf="step === 1">
        <div class="panel-body-row">
          <div><small class="text-muted" translate>Suggestions</small></div>
          <div class="time-slots">
            <div class="fx-row fx-align-center fx-justify-space-between">
              <a (click)="setTime(timeHalfHour)" translate>In 30 minutes</a>
              <small class="text-muted">{{
                timeHalfHour | amLocale : lang | amDateFormat : "hh:mm A"
              }}</small>
            </div>
            <div class="fx-row fx-align-center fx-justify-space-between">
              <a (click)="setTime(timeOneHour)" translate>In 1 Hour</a>
              <small class="text-muted">{{
                timeOneHour | amLocale : lang | amDateFormat : "hh:mm A"
              }}</small>
            </div>
            <div class="fx-row fx-align-center fx-justify-space-between">
              <a (click)="setTime(timeTwoHours)" translate>In 2 Hours</a>
              <small class="text-muted">{{
                timeTwoHours | amLocale : lang | amDateFormat : "hh:mm A"
              }}</small>
            </div>
            <div class="fx-row fx-align-center fx-justify-space-between">
              <a (click)="setTime(timeTomorrow)" translate>Tomorrow</a>
              <small class="text-muted">{{
                timeTomorrow | amLocale : lang | amDateFormat : "ddd hh:mm A"
              }}</small>
            </div>
          </div>
        </div>
        <div class="panel-body-row">
          <div><small class="text-muted" translate>Date & Time</small></div>
          <div class="fx-row fx-align-center fx-justify-start">
            <div class="date-picker">
              <dp-date-picker
                name="callbackDay"
                [mode]="'day'"
                id="date-picker"
                [(ngModel)]="dpDay"
                [config]="dateConfig"
                placeholder="{{ 'callback date' | translate }}"
                theme="av-picker"
                (onChange)="dayChange($event)"
              >
              </dp-date-picker>
              <div class="form-error" *ngIf="!callbackDay" translate>
                Required
              </div>
            </div>
            <div class="time-picker">
              <dp-date-picker
                name="callbackTime"
                [mode]="'time'"
                id="time-picker"
                [(ngModel)]="dpTime"
                [config]="timeConfig"
                placeholder="{{ 'callback time' | translate }}"
                theme="av-picker"
                (onChange)="timeChange($event)"
              >
              </dp-date-picker>
              <div class="form-error" *ngIf="!callbackTime" translate>
                Required
              </div>
            </div>
            <div class="timezone">
              <small class="text-muted">{{ getTimezone() }}</small>
            </div>
          </div>
        </div>

        <div class="panel-body-row">
          <div><small class="text-muted" translate>Time zone</small></div>
          <app-timezone-picker
            [timezone]="timezone"
            (changed)="timezoneChange($event)"
          ></app-timezone-picker>
        </div>

        <div class="panel-body-row">
          <div>
            <small class="text-muted" translate>Customer full name *</small>
          </div>
          <input
            avInput
            type="text"
            name="callbackName"
            required
            data-tid="callback/customer/name"
            #formName="ngModel"
            [(ngModel)]="callbackName"
            placeholder="{{ 'appears as callback display name' | translate }}"
          />
          <!-- prefixed by '{{callbackNamePrefix}}'... -->
          <div
            class="form-error"
            *ngIf="formName.touched && formName.hasError('required')"
            translate
          >
            Required
          </div>
        </div>

        <div class="panel-body-row">
          <div>
            <small class="text-muted" translate>Customer phone number</small>
          </div>
          <div class="fx-row">
            <div avInput class="phone-prefix" *ngIf="countryCodes.length > 0">
              {{ callbackPhonePrefix }}
              <select
                name="callbackPhonePrefix"
                id="callbackPhonePrefix"
                [(ngModel)]="callbackPhonePrefix"
              >
                <option *ngFor="let c of countryCodes" [value]="c.dial_code">
                  {{ c.name }} ({{ c.dial_code }})
                </option>
              </select>
            </div>
            <input
              type="text"
              name="callbackPhone"
              #formPhone="ngModel"
              [(ngModel)]="callbackPhone"
              avInput
              data-tid="callback/customer/phone"
              [pattern]="phonePattern"
              placeholder="{{
                'appears on the callback interaction' | translate
              }}"
            />
          </div>
          <div
            class="form-error"
            *ngIf="formPhone.touched && formPhone.hasError('pattern')"
            translate
          >
            Not a valid phone number
          </div>
        </div>

        <div class="panel-body-row" *ngIf="callbackAgentId">
          <label for="callbackToMe">
            <input
              [(ngModel)]="callbackToMe"
              id="callbackToMe"
              name="callbackToMe"
              type="checkbox"
            />
            <span class="checkbox-label" translate
              >Route callback to me if possible</span
            >
          </label>
        </div>
      </ng-container>

      <ng-container *ngIf="step === 2">
        <div class="panel-body-row">
          <b><span translate>Video call with</span> {{ callbackName }}</b>
        </div>
        <div class="panel-body-row">
          {{ callbackDate | amDateFormat : "dddd MMM. D, hh:mm A" }}
          <small class="text-muted timezone">{{ getTimezone() }}</small>
        </div>
        <div class="panel-body-row">
          <div>
            <small class="text-muted" translate>Routing queue</small>
          </div>
          <select
            required
            name="callbackQueue"
            #formQueue="ngModel"
            [(ngModel)]="callbackQueueId"
          >
            <option *ngFor="let queue of queues" [ngValue]="queue.id">
              {{ queue.name }}
            </option>
          </select>
          <div
            class="form-error"
            *ngIf="formQueue.touched && formQueue.hasError('required')"
            translate
          >
            Required
          </div>
        </div>

        <ng-container *ngIf="hasSMSQueues || hasEmailQueues">
          <div class="panel-body-row" class="panel-body-row-air-bottom">
            <b translate>Notify customer</b>
          </div>
        </ng-container>

        <!-- show sms reminder only if we have sms queues-->
        <ng-container *ngIf="hasSMSQueues">
          <div
            class="panel-body-row panel-body-row-bkg-cold panel-body-row-pad"
          >
            <div class="panel-body-row">
              <label for="cbx-callback-sms">
                <input
                  id="cbx-callback-sms"
                  type="checkbox"
                  name="callback-sms"
                  [(ngModel)]="notifySMS"
                />
                <small>Send SMS</small>
              </label>
            </div>

            <ng-container *ngIf="notifySMS">
              <div class="panel-body-row">
                <div class="fx-row">
                  <small class="text-muted" translate
                    >Customer phone number</small
                  >
                  <small class="text-muted">&nbsp;*</small>
                </div>
                <input
                  type="text"
                  required
                  name="callbackPhone"
                  #formPhone="ngModel"
                  [(ngModel)]="callbackPhone"
                  avInput
                  data-tid="callback/notify/phone"
                  [pattern]="phonePattern"
                  placeholder="{{ 'ex: +306953312000' | translate }}"
                />
                <div
                  class="form-error"
                  *ngIf="formPhone.touched && formPhone.hasError('required')"
                  translate
                >
                  Required
                </div>
                <div
                  class="form-error"
                  *ngIf="formPhone.touched && formPhone.hasError('pattern')"
                  translate
                >
                  Not a valid phone number
                </div>
              </div>

              <div class="panel-body-row" *ngIf="smsQueues.length > 1">
                <div>
                  <small class="text-muted" translate>SMS routing queue</small>
                </div>
                <select
                  name="callbackSMSQueue"
                  [(ngModel)]="callbackSMSQueueId"
                >
                  <option *ngFor="let queue of smsQueues" [ngValue]="queue.id">
                    {{ queue.name }}
                  </option>
                </select>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- show email reminder only if we have email queues -->
        <ng-container *ngIf="hasEmailQueues">
          <div
            class="panel-body-row panel-body-row-bkg-cold panel-body-row-pad"
          >
            <div class="panel-body-row">
              <label for="cbx-callback-email">
                <input
                  id="cbx-callback-email"
                  type="checkbox"
                  name="callback-email"
                  [(ngModel)]="notifyEmail"
                />
                <small translate>Send Email</small>
              </label>
            </div>

            <ng-container *ngIf="notifyEmail">
              <div class="panel-body-row">
                <div>
                  <small class="text-muted" translate>Customer email *</small>
                </div>
                <input
                  type="email"
                  required
                  name="callbackEmail"
                  #formEmail="ngModel"
                  email
                  [(ngModel)]="callbackEmail"
                  avInput
                  data-tid="callback/notify/email"
                  placeholder="{{ 'Configures email interaction' | translate }}"
                />
                <div
                  class="form-error"
                  *ngIf="formEmail.touched && formEmail.hasError('required')"
                  translate
                >
                  Required
                </div>
                <div
                  class="form-error"
                  *ngIf="formEmail.touched && formEmail.hasError('email')"
                  translate
                >
                  Invalid email
                </div>
              </div>

              <div class="panel-body-row" *ngIf="emailQueues.length > 1">
                <div>
                  <small class="text-muted" translate
                    >Email routing queue</small
                  >
                </div>
                <select
                  name="callbackEmailQueue"
                  [(ngModel)]="callbackEmailQueueId"
                >
                  <option
                    *ngFor="let queue of emailQueues"
                    [ngValue]="queue.id"
                  >
                    {{ queue.name }}
                  </option>
                </select>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="step === 3">
        <div class="panel-body-row">
          <div><small translate>Video call scheduled for</small></div>
          <b>{{ callbackDate | amDateFormat : "dddd MMMM Do, hh:mm A" }}</b>
          <small class="text-muted">{{ getTimezone() }}</small>
        </div>
        <div class="panel-body-row panel-body-row-bkg-cold">
          <div class="fx-row fx-align-center fx-justify-space-between">
            <div class="textarea-container fx-flex-1">
              <small class="text-muted" translate>Customer URL</small>
              <textarea disabled>{{ callbackTicket }}</textarea>
            </div>
            <div>
              <av-tooltip message="Copy to clipboard">
                <button
                  avButton
                  ofType="icon"
                  color="link"
                  size="xs"
                  data-tid="callback/copy-link"
                  (click)="copy()"
                >
                  <av-icon name="copy-document"></av-icon>
                </button>
              </av-tooltip>
            </div>
          </div>
        </div>

        <av-card
          class="panel-body-row"
          type="success"
          *ngIf="notifySMS && isSMSSuccess"
        >
          <av-card-body size="xs" class="fx-row fx-align-start">
            <av-icon class="info-icon" name="tick"></av-icon>
            <div class="fx-flex-1">
              <small translate
                >An SMS interaction containing the customer URL has been
                created.</small
              >
            </div>
          </av-card-body>
        </av-card>

        <av-card
          class="panel-body-row"
          type="success"
          *ngIf="notifyEmail && isEmailSuccess"
        >
          <av-card-body size="xs" class="fx-row fx-align-start">
            <div class="fx-flex-1">
              <small translate
                >An email interaction containing the customer URL has been
                created.</small
              >
              <div class="info-air-top">
                <small translate
                  >This needs to be forwarded to the customer.</small
                >
              </div>
            </div>
          </av-card-body>
        </av-card>

        <ng-container *ngIf="isLinkNotShared">
          <av-card class="panel-body-row" type="info">
            <av-card-body type="container">
              <b translate>! important !</b>
              <h3 class="no-margin" translate>
                Please share the above URL with the customer!
              </h3>
            </av-card-body>
          </av-card>
        </ng-container>
      </ng-container>

      <div class="panel-body-row" *ngIf="!!error">
        <av-card type="danger">
          <av-card-body size="xs">
            <small>{{ error }}</small>
          </av-card-body>
        </av-card>
      </div>
    </av-card-body>

    <av-card-footer>
      <div class="text-right" *ngIf="step === 1">
        <button
          avButton
          color="accent"
          data-tid="callback/confirm"
          (click)="confirm(f.form)"
          translate
        >
          Confirm
        </button>
      </div>

      <div
        class="fx-row fx-align-center fx-justify-space-between"
        *ngIf="step === 2"
      >
        <div>
          <button data-tid="callback/back" avButton (click)="step = 1">
            &larr; <span translate>Back</span>
          </button>
        </div>
        <div>
          <button
            avButton
            data-tid="callback/schedule"
            color="accent"
            (click)="schedule(f.form)"
            [disabled]="loading"
            translate
          >
            Schedule
          </button>
        </div>
      </div>

      <div class="text-right" *ngIf="step === 3">
        <button
          avButton
          data-tid="callback/dismiss"
          (click)="cancel($event)"
          translate
        >
          Dismiss
        </button>
      </div>
    </av-card-footer>
  </form>
</av-card>
