import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { VisionService } from "./vision.service";

@Injectable()
export class AssistantService {
  private _openChange: Subject<boolean> = new Subject();
  public openChange$ = this._openChange.asObservable();

  constructor(private vision: VisionService) {
    vision.imageStateChange$.subscribe((state) => {
      this._openChange.next(true);
    });
  }

  public toggleOpen(open: boolean) {
    this._openChange.next(open);
  }
}
