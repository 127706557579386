<ng-container *ngIf="isActionable">
  <av-tooltip position="bottom">
    <button
      aria-labelledby="recorder-tooltip-message"
      avButton
      size="xxs"
      ofType="basic"
      id="btn-recorder-start"
      [class.recorder-on]="isRecorderActive || isRecordingRemotely()"
      (click)="toggleRecorder()"
      [disabled]="isRecorderButtonDisabled || isRecordingRemotely()"
      class="recorder"
      data-tid="recorder/toggle-recorder"
    >
      <div class="recorder-indicator"></div>
      <span>REC</span>
    </button>


      <span id="recorder-tooltip-message" tooltipMessage>
        <ng-container *ngIf="!isRecorderActive">
          <ng-container *ngIf="!isRecordingRemotely()">
            <ng-container *ngIf="isRecorderConfigured"
              >{{'start recording' | translate}}</ng-container
            >
            <ng-container *ngIf="!isRecorderConfigured">
            {{'Recording disabled. Contact your administrator to enable.' | translate}}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isRecordingRemotely()">
            {{'Session is being recorded' | translate}}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isRecorderActive">
          <ng-container *ngIf="!isRecordingRemotely()">
            <ng-container *ngIf="isRecorderLocked">
              {{'Recording is locked' | translate}}
            </ng-container>
            <ng-container *ngIf="!isRecorderLocked">
              {{'stop recording' | translate}}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isRecordingRemotely()">
            {{'Session is being recorded' | translate}}
          </ng-container>
        </ng-container>
      </span>

  </av-tooltip>
</ng-container>

<ng-container *ngIf="isReadOnly">
  <av-tooltip position="bottom">
    <div
      aria-labelledby="recorder-tooltip-message"
      [class.recorder-on]="isRecordingRemotely()"
      class="recorder recorder-view"
      data-tid="recorder/customer"
    >
      <div class="recorder-indicator"></div>
      <span>REC</span>
    </div>
    <span id="recorder-tooltip-message" tooltipMessage>
      <ng-container *ngIf="isRecordingRemotely()">
        {{'Session is being recorded' | translate}}
      </ng-container>
      <ng-container *ngIf="!isRecordingRemotely()">
        {{'Session is not recorded' | translate}}
      </ng-container>
    </span>
  </av-tooltip>
</ng-container>
