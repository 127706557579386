/* eslint-disable no-shadow */
import moment from "moment";
import { IAppointmentNotification, IMetricWidget } from "./models";
import { ChatModeEnum, ConversationTypeEnum } from "@auvious/integrations";

export const AUVIOUS_PURECLOUD_VERSION = "AUVIOUS-PURECLOUD-VERSION";
export const KEY_RECORDER = "auvious.recorder.";
export const KEY_DEVICES_CHECKED = "auvious.devicesChecked";
export const KEY_DEFAULT_AUDIO_DEVICE_ID = "auvious.device.audio";
export const KEY_DEFAULT_SPEAKER_DEVICE_ID = "auvious.device.speaker";
export const KEY_DEFAULT_VIDEO_DEVICE_ID = "auvious.device.video";
export const KEY_MEDIA_VIDEO_EFFECT = "auvious.media.video.effect";
export const PARAM_TICKET_ID = "ticketId";
export const OAUTH2_CODE_GRAND_REDIRECT_URI = "/redirect";
export const OAUTH2_STATE = "oauth2.state";
export const KEY_REDIRECT_URI = "auvious.redirectUri";
export const KEY_CX_OPENID_CONFIG = "auvious.cx.openid.config";
export const KEY_COBROWSE_SESSION_ID = "auvious.cobrowse.session";
export const KEY_COBROWSE_REQUEST_TYPE = "auvious.cobrowse.request.type";
export const KEY_COBROWSE_CONTEXT = "auvious.cobrowse.context";
export const KEY_COBROWSE_CURRENT_SENDER_ENDPOINT =
  "auvious.cobrowse.sender.endpoint";
export const KEY_COBROWSE_OLD_SENDER_ENDPOINTS =
  "auvious.cobrowse.sender.history";
export const KEY_PAGE_SIZE = "auvious.pageSize";

export const CLAIM_CONFERENCE_ID = "conference_id";
export const CLAIM_APPLICATION_ID = "application_id";
export const CLAIM_INTERACTION_ID = "interaction_id";
export const CLAIM_CUSTOMER_ID = "customer_id";
export const CLAIM_ORGANIZATION_ID = "organization_id";
export const CLAIM_NAME = "name";
export const CLAIM_EMAIL = "email";
export const CLAIM_PICTURE = "picture";
export const CLAIM_OPEN_ID = "openid_sub";
export const CLAIM_TERMS_ACCEPTED = "terms_accepted";
export const CLAIM_TERMS_ACCEPTED_AT = "terms_acceptedAt";
export const CLAIM_APPOINTMENT_ID = "appointment_id";

export const SNAPSHOT_TYPE_VISION = "auvious:vision";

export const STREAM_CONTROLS_LEFT_OFFSET_THRESHOLD = 60; // if rect.left is smaller than this threshold, make the buttons smaller to fit

export const METRIC_MOCK_HOUR_DATA = [
  { hour: 1, day: 1, week: 1, month: 1, year: 2021, value: 5 },
  { hour: 2, day: 1, week: 1, month: 1, year: 2021, value: 7 },
  { hour: 3, day: 1, week: 1, month: 1, year: 2021, value: 9 },
  { hour: 4, day: 1, week: 1, month: 1, year: 2021, value: 11 },
  { hour: 5, day: 1, week: 1, month: 1, year: 2021, value: 14 },
];

export const METRIC_MOCK_DAY_DATA = [
  { hour: 1, day: 1, week: 1, month: 1, year: 2021, value: 5 },
  { hour: 1, day: 2, week: 1, month: 1, year: 2021, value: 9 },
  { hour: 1, day: 3, week: 1, month: 1, year: 2021, value: 2 },
  { hour: 1, day: 4, week: 1, month: 1, year: 2021, value: 1 },
  { hour: 1, day: 5, week: 1, month: 1, year: 2021, value: 5 },
  { hour: 1, day: 6, week: 1, month: 1, year: 2021, value: 8 },
  { hour: 1, day: 7, week: 1, month: 1, year: 2021, value: 5 },
  { hour: 1, day: 8, week: 1, month: 1, year: 2021, value: 8 },
  { hour: 1, day: 9, week: 1, month: 1, year: 2021, value: 1 },
  { hour: 1, day: 10, week: 1, month: 1, year: 2021, value: 5 },
];

export const METRIC_MOCK_WEEK_DATA = [
  { hour: 1, day: 1, week: 1, month: 1, year: 2020, value: 30 },
  { hour: 1, day: 1, week: 2, month: 1, year: 2020, value: 35 },
  { hour: 1, day: 1, week: 3, month: 1, year: 2020, value: 43 },
  { hour: 1, day: 1, week: 4, month: 2, year: 2020, value: 44 },
  { hour: 1, day: 1, week: 5, month: 2, year: 2020, value: 34 },
  { hour: 1, day: 1, week: 6, month: 2, year: 2020, value: 22 },
  { hour: 1, day: 1, week: 7, month: 3, year: 2020, value: 29 },
  { hour: 1, day: 1, week: 8, month: 3, year: 2020, value: 34 },
  { hour: 1, day: 1, week: 9, month: 3, year: 2020, value: 50 },
];

export const METRIC_MOCK_MONTH_DATA = [
  { hour: 1, day: 1, week: 1, month: 1, year: 2020, value: 150 },
  { hour: 1, day: 1, week: 2, month: 2, year: 2020, value: 120 },
  { hour: 1, day: 1, week: 3, month: 3, year: 2020, value: 122 },
  { hour: 1, day: 1, week: 4, month: 4, year: 2020, value: 114 },
  { hour: 1, day: 1, week: 5, month: 5, year: 2020, value: 152 },
  { hour: 1, day: 1, week: 6, month: 6, year: 2020, value: 175 },
  { hour: 1, day: 1, week: 7, month: 7, year: 2020, value: 158 },
  { hour: 1, day: 1, week: 8, month: 8, year: 2020, value: 181 },
];

export const METRIC_MOCK_YEAR_DATA = [
  { hour: 1, day: 1, week: 1, month: 1, year: 2019, value: 420 },
  { hour: 1, day: 1, week: 1, month: 1, year: 2020, value: 550 },
  { hour: 1, day: 1, week: 1, month: 1, year: 2021, value: 890 },
];

export const MAX_FILE_SIZE_UPLOAD = 100 * 1024 * 1024; // 100MB

export const LOCALES = {
  primaryDialects: {
    af: "af-ZA",
    ar: "ar",
    bg: "bg-BG",
    ca: "ca-AD",
    cs: "cs-CZ",
    cy: "cy-GB",
    da: "da-DK",
    de: "de-DE",
    el: "el-GR",
    en: "en-US",
    es: "es-ES",
    et: "et-EE",
    eu: "eu",
    fa: "fa-IR",
    fi: "fi-FI",
    fr: "fr-FR",
    he: "he-IL",
    hi: "hi-IN",
    hr: "hr-HR",
    hu: "hu-HU",
    id: "id-ID",
    is: "is-IS",
    it: "it-IT",
    ja: "ja-JP",
    km: "km-KH",
    ko: "ko-KR",
    la: "la",
    lt: "lt-LT",
    lv: "lv-LV",
    mn: "mn-MN",
    nb: "nb-NO",
    nl: "nl-NL",
    nn: "nn-NO",
    pl: "pl-PL",
    pt: "pt-PT",
    ro: "ro-RO",
    ru: "ru-RU",
    sk: "sk-SK",
    sl: "sl-SI",
    sr: "sr-RS",
    sv: "sv-SE",
    th: "th-TH",
    tr: "tr-TR",
    uk: "uk-UA",
    vi: "vi-VN",
    zh: "zh-CN",
  },
  languageNames: {
    "af-ZA": ["Afrikaans", "Afrikaans"],
    ar: ["العربية", "Arabic"],
    "bg-BG": ["Български", "Bulgarian"],
    "ca-AD": ["Català", "Catalan"],
    "cs-CZ": ["Čeština", "Czech"],
    "cy-GB": ["Cymraeg", "Welsh"],
    "da-DK": ["Dansk", "Danish"],
    "de-AT": ["Deutsch (Österreich)", "German (Austria)"],
    "de-CH": ["Deutsch (Schweiz)", "German (Switzerland)"],
    "de-DE": ["Deutsch (Deutschland)", "German (Germany)"],
    "el-GR": ["Ελληνικά", "Greek"],
    "en-GB": ["English (UK)", "English (UK)"],
    "en-US": ["English (US)", "English (US)"],
    "es-CL": ["Español (Chile)", "Spanish (Chile)"],
    "es-ES": ["Español (España)", "Spanish (Spain)"],
    "es-MX": ["Español (México)", "Spanish (Mexico)"],
    "et-EE": ["Eesti keel", "Estonian"],
    eu: ["Euskara", "Basque"],
    "fa-IR": ["فارسی", "Persian"],
    "fi-FI": ["Suomi", "Finnish"],
    "fr-CA": ["Français (Canada)", "French (Canada)"],
    "fr-FR": ["Français (France)", "French (France)"],
    "he-IL": ["עברית", "Hebrew"],
    "hi-IN": ["हिंदी", "Hindi"],
    "hr-HR": ["Hrvatski", "Croatian"],
    "hu-HU": ["Magyar", "Hungarian"],
    "id-ID": ["Bahasa Indonesia", "Indonesian"],
    "is-IS": ["Íslenska", "Icelandic"],
    "it-IT": ["Italiano", "Italian"],
    "ja-JP": ["日本語", "Japanese"],
    "km-KH": ["ភាសាខ្មែរ", "Khmer"],
    "ko-KR": ["한국어", "Korean"],
    la: ["Latina", "Latin"],
    "lt-LT": ["Lietuvių kalba", "Lithuanian"],
    "lv-LV": ["Latviešu", "Latvian"],
    "mn-MN": ["Монгол", "Mongolian"],
    "nb-NO": ["Norsk bokmål", "Norwegian (Bokmål)"],
    "nl-NL": ["Nederlands", "Dutch"],
    "nn-NO": ["Norsk nynorsk", "Norwegian (Nynorsk)"],
    "pl-PL": ["Polski", "Polish"],
    "pt-BR": ["Português (Brasil)", "Portuguese (Brazil)"],
    "pt-PT": ["Português (Portugal)", "Portuguese (Portugal)"],
    "ro-RO": ["Română", "Romanian"],
    "ru-RU": ["Русский", "Russian"],
    "sk-SK": ["Slovenčina", "Slovak"],
    "sl-SI": ["Slovenščina", "Slovenian"],
    "sr-RS": ["Српски / Srpski", "Serbian"],
    "sv-SE": ["Svenska", "Swedish"],
    "th-TH": ["ไทย", "Thai"],
    "tr-TR": ["Türkçe", "Turkish"],
    "uk-UA": ["Українська", "Ukrainian"],
    "vi-VN": ["Tiếng Việt", "Vietnamese"],
    "zh-CN": ["中文 (中国大陆)", "Chinese (PRC)"],
    "zh-TW": ["中文 (台灣)", "Chinese (Taiwan)"],
  },
};

export class VideoFacingModeEnum {
  static User = "user";
  static Environment = "environment";
  static Left = "left";
  static Right = "right";
}

export enum StreamTrackKindEnum {
  audio = "audio",
  video = "video",
}

export type TranscriptEvent =
  | "ASROfflineTranscriptFailedEvent"
  | "ASROfflineTranscriptProcessingEvent"
  | "ASROfflineTranscriptCreatedEvent"
  | "ASROfflineTranslationCreatedEvent"
  | "ASROfflineTranslationProcessingEvent"
  | "ASROfflineTranslationFailedEvent"
  | "AIPromptCreatedEvent"
  | "AIPromptProcessingEvent"
  | "AIPromptFailedEvent";

export type AIVisionEvent =
  | "AIRecognitionCreatedEvent"
  | "AIRecognitionProcessingEvent"
  | "AIRecognitionFailedEvent";

export enum StreamErrorEnum {
  NoMediaDevices = "no-media-devices",
  NoSuitableCameraFound = "no-suitable-camera-found",
  NoVideoTracksFound = "no-video-tracks-found",
  TrackNotReplaced = "track-not-replaced",
}

export enum ApplicationTypeEnum {
  GenesysCloud = "GENESYS",
  StandaloneOpenID = "STANDALONE_OPENID",
  Customer = "CUSTOMER",
  Test = "TEST",
  SetupTest = "SETUP_TEST",
  TalkdeskOpenID = "TALKDESK_OPENID",
  NiceOpenID = "NICE_CXONE_OPENID",
  verintOpenID = "VERINT_OPENID",
}

export enum ApplicationProviderTypeEnum {
  GOOGLE_CLOUD = "GOOGLE_CLOUD",
  AMAZON_S3 = "AMAZON_S3",
  MICROSOFT_AZURE = "AZURE_BLOB",
  SFTP = "SFTP_STORAGE",
  OPEN_AI = "OPEN_AI",
}

export { ConversationTypeEnum };

// export enum ConversationTypeEnum {
//   videoCall = "video",
//   voiceCall = "audio",
//   callback = "callback",
//   cobrowse = "cobrowse",
//   close = "close",
//   chat = "chat",
//   displayCapture = "displayCapture",
// }

export enum ConversationChannelEnum {
  genesysWebMessaging = "genesysWebMessaging",
  genesysWebChat = "genesysWebChat",
  genesysCallback = "genesysCallback",
  unknown = "unknown",
  digitalConnect = "digitalConnect",
  genesysCall = "genesysCall",
  cxoneDFO = "cxoneDFO",
  verintLiveChat = "verintLiveChat",
  genesysWhatsApp = "genesysWhatsApp",
  genesysFacebookMessenger = "genesysFacebookMessenger",
  genesysMessaging = "genesysMessaging",
}

export enum LifecycleStateEnum {
  focus = "focus",
  blur = "blur",
  stop = "stop",
}

export enum UserRoleEnum {
  agent = "AGENT",
  guestAgent = "GUEST_AGENT",
  customer = "CUSTOMER",
  supervisor = "SUPERVISOR", // used only to show all recordings
  admin = "ADMIN",
}

export enum UserCapabilityEnum {
  qrLink = "qr-link",
  arPointer = "ar-pointer",
  coBrowse = "co-browse",
  snapshot = "snapshot",
  fileTransfer = "file-transfer",
  displayCapture = "display-capture",
  acceptedTerms = "accepted-terms",
  messaging = "messaging",
  multiCam = "multi-cam",
}

export enum EndpointTypeEnum {
  coBrowse = "co-browse",
  stream = "stream",
  participant = "participant",
  displayCapture = "displayCapture",
}

export enum ConversationOriginEnum {
  CHAT = "chat",
  WIDGET = "widget",
  CALLBACK = "callback",
  POPUP = "popup",
  EMBEDDED = "embedded",
  SERVICE = "service",
  APPOINTMENT = "appointment",
  PHONE = "phone",
}

export enum ConversationDestinationEnum {
  MOBILE_OFFICE = "mobile-office",
  INTERACTION_WIDGET = "interaction-widget",
  EMBEDDED = "embedded",
  STANDALONE = "standalone",
  // CALLBACK = "callback",
  PREMISE = "premise",
  TALKDESK_CARD = "talkdesk-card",
}

export enum OriginModeEnum {
  kiosk = "kiosk",
  website = "website",
}

export enum CompositionStateEnum {
  initialized = "INITIALIZED",
  completed = "COMPLETED",
  failed = "FAILED",
  queued = "QUEUED",
  processing = "PROCESSING",
  submitted = "SUBMITTED",
  cancelled = "CANCELLED",
}

export enum TranscriptStateEnum {
  completed = "COMPLETED",
  processing = "PROCESSING",
  failed = "FAILED",
  resubmit = "RESUBMIT",
  submitted = "SUBMITTED",
}

export enum CompositionTypeEnum {
  audio = "AUDIO",
  video = "VIDEO",
}

export enum CompositionLayoutEnum {
  grid = "GRID",
}

export enum CompositionVideoFormatEnum {
  webm = "WEBM",
  mp4 = "MP4",
}

export enum CompositionAudioFormatEnum {
  mp3 = "MP3",
  wav = "WAV",
}

export enum CompositionResolutionEnum {
  low = "320x240",
}

export enum TileTypeEnum {
  screenShare = "screen-share",
  coBrowse = "co-browse",
  video = "video",
  arPointer = "ar-pointer",
  snapshot = "snapshot",
}

export enum LayoutEnum {
  spotlight,
  grid,
  floating,
}

export enum MediaFilterTypeEnum {
  backgroundBlur = "background-blur",
  backgroundImage = "background-image",
  none = "none",
}

export enum TorchStateEnum {
  on = "on",
  off = "off",
  error = "error",
}

export enum ColorEnum {
  red = "red",
  green = "green",
  blue = "blue",
  yellow = "yellow",
  orange = "orange",
  purple = "purple",
  gold = "gold",
  brown = "brown",
  mint = "mint",
  black = "black",
  white = "white",
}

export const COLOR = {
  RED: "#F75A51",
  GREEN: "#4BCF5F",
  BLUE: "#338CF7",
  YELLOW: "#F7CE33",
  ORANGE: "#F7A234",
  PURPLE: "#B26FD3",
  GOLD: "#c39953",
  BROWN: "#5C341F",
  MINT: "#90DABB",
  BLACK: "#333333",
  WHITE: "#F4F4F4",
};

export const DEFAULT_AR_POINTER_COLORS = [
  COLOR.RED,
  COLOR.GREEN,
  COLOR.BLUE,
  COLOR.YELLOW,
  COLOR.ORANGE,
  COLOR.PURPLE,
  COLOR.GOLD,
  COLOR.BROWN,
  COLOR.MINT,
];

export enum ConferenceMetadataKeyEnum {
  arPointer = "AR_POINTER",
  arPointerColors = "AR_POINTER_COLORS",
  snapshot = "SNAPSHOT",
  sketch = "SKETCH",
  recorder = "RECORDER",
  speechToText = "SPEECH_TO_TEXT",
  customerMetrics = "CUSTOMER_METRICS",
  transfer = "TRANSFER",
  coBrowse = "CO_BROWSE",
  integration = "INTEGRATION",
  geolocation = "GEOLOCATION",
  vision = "VISION",
}

export enum AssetCategoryEnum {
  background = "background",
  i18n = "i18n",
}

export enum AssetTypeEnum {
  image = "image",
  video = "video",
}

export enum SketchToolEnum {
  marker = "marker",
  eraser = "eraser",
  arrow = "arrow",
  pointer = "pointer",
  magnify = "magnify",
}

export enum SketchActionEnum {
  activated = "activated",
  toolChanged = "toolChanged",
}

export interface IInteractionMetricConference {
  duration: string;
  on_hold_duration: string;
  eventsJsonGzBase64: string;
}

export enum InteractionMetricEnum {
  callOrigin = "callOrigin",
  callOriginMode = "callOriginMode",
  callStartAt = "callStartAt",
  callEndAt = "callEndAt",
  callTransferredAt = "callTransferredAt",
  callDestination = "callDestination",
  // callRecordingUrl = 'callRecordingUrl',
  // callRecorderInfo = 'callRecorderInfo',
  ticketSentAt = "ticketSentAt",
  roomName = "roomName",
  recorderId = "recorderId",
  recorderInstanceId = "recorderInstanceId",
  recorderStorageProvider = "recorderStorageProvider",
  recordingUrl = "recordingUrl",
  cobrowseRecorderId = "cobrowseRecorderId",
  cobrowseRecorderInstanceId = "cobrowseRecorderInstanceId",
  cobrowseRecordingUrl = "cobrowseRecordingUrl",
  customerDisplayName = "customerDisplayName",
  customerId = "customerId",
  customerMetadata = "customerMetadata",
  originUrl = "originUrl",
  termsAcceptedSnapshot = "termsAcceptedSnapshot",
  termsAcceptedAt = "termsAcceptedAt",
  integrationInteractionAvailable = "integrationInteractionAvailable",
  cobrowseViewRequesteddAt = "cobrowseViewRequesteddAt",
  cobrowseAccessDeniedAt = "cobrowseAccessDeniedAt",
  cobrowseControlAcceptedAt = "cobrowseControlAcceptedAt",
  cobrowseControlRevokedAt = "cobrowseControlRevokedAt",
  cobrowseRequestType = "cobrowseRequestType",
  snapshotGeolocation = "snapshotGeolocation",
  conferences = "conferences",
}

export type InteractionMetric = {
  [key in InteractionMetricEnum]?: any;
};

export type VideoBitrateQuality =
  | "low"
  | "mediumLow"
  | "medium"
  | "mediumHigh"
  | "high";
export interface ICallQualityOptions {
  bitrate?: {
    user?: VideoBitrateQuality;
    environment?: VideoBitrateQuality;
    displayCapture?: VideoBitrateQuality;
  };
  viewer?: {
    autoAdapt: boolean;
    firstReducePublisherVideoBitrate?: number;
    minSecondsBeforeStoppingVideo?: number;
    minSecondsBeforeStartingVideo?: number;
  };
  publisher?: {
    autoAdapt: boolean;
    minSecondsBetweenDecrements?: number;
    minSecondsBetweenIncrements?: number;
  };
}

export const VIDEO_BITRATE_OPTIONS = [
  { value: 65536, label: "64Kbit/sec" },
  // { value: 131072, label: '128Kbit/sec' },
  { value: 262144, label: "256Kbit/sec" },
  // { value: 524288, label: '512Kbit/sec' },
  { value: 786432, label: "768Kbit/sec" },
  // { value: 1048576, label: '1Mbit/sec' },
  // { value: 2097152, label: '2Mbit/sec' },
];

export const SQ_VIDEO_BITRATE_INDEX = 1;
export const HQ_VIDEO_BITRATE_INDEX = 2;
export const LQ_VIDEO_BITRATE_INDEX = 0;

export const VIDEO_BITRATE_MAP: Record<VideoBitrateQuality, number> = {
  low: VIDEO_BITRATE_OPTIONS[LQ_VIDEO_BITRATE_INDEX].value,
  mediumLow: 131072, //128Kbit/sec
  medium: VIDEO_BITRATE_OPTIONS[SQ_VIDEO_BITRATE_INDEX].value,
  mediumHigh: 524288, // 512Kbit/sec
  high: VIDEO_BITRATE_OPTIONS[HQ_VIDEO_BITRATE_INDEX].value,
};

export enum MetricTypeEnum {
  VideoCallsCreated = "video_calls_created",
  VideoCallsPerformed = "video_calls_performed",
  VideoCallsJoined = "video_calls",
  VideoCallsAbandoned = "video_calls_abandoned",
  VideoCallsWithSmallDuration = "video_calls_with_small_duration",
  VideoCallsWithNormalDuration = "video_calls_with_normal_duration",
  VideoCallsWithLongDuration = "video_calls_with_long_duration",
  InteractionsCreated = "interactions_created",
  RatingsSubmitted = "ratings_submitted",
  RatingScoreAverage = "rating_score_average",
  concurentCallsMax = "concurrent_calls_max",
  concurentCallsAverage = "concurrent_calls_avg",
  conferenceAverageDuration = "conference_average_duration",
  conferenceDurationSum = "conference_duration_sum",
  callAverageDuration = "call_average_duration",
  callDurationSum = "call_duration_sum",
  cobrowseSessionsCreated = "cobrowse_sessions_created",
  cobrowseSessionsPerformed = "cobrowse_sessions_performed",
  cobrowseSessionsDenied = "cobrowse_sessions_denied",
  cobrowseSessionsAbandoned = "cobrowse_sessions_abandoned",
  cobrowseControlRequested = "cobrowse_control_requested",
  cobrowseControlAccepted = "cobrowse_control_accepted",
  cobrowseControlDenied = "cobrowse_control_denied",
  cobrowseSessionDuration = "cobrowse_session_duration",
  onholdDurationSum = "onhold_duration_sum",
}

export enum MetricRangeEnum {
  hourly = "hourly",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
  yearly = "yearly",
  total = "total",
}

export enum DateRangePresetEnum {
  hourCurrent = "hourCurrent",
  dayToday = "today",
  weekCurrent = "weekCurrent",
  monthCurrent = "monthCurrent",
  yearCurrent = "yearCurrent",

  hourPrevious = "hourPrevious",
  dayYesterday = "yesterday",
  weekPrevious = "weekPrevious",
  monthPrevious = "monthPrevious",
  yearPrevious = "yearPrevious",
  // custom = 'custom',
  allTime = "allTime",
}

export const DEFAULT_METRICS_DASHBOARD: IMetricWidget[] = [
  {
    id: "default-1",
    size: "sm",
    rangePreset: DateRangePresetEnum.allTime,
    type: MetricTypeEnum.VideoCallsCreated,
    range: MetricRangeEnum.total,
  },
  {
    id: "default-2",
    size: "sm",
    rangePreset: DateRangePresetEnum.allTime,
    type: MetricTypeEnum.RatingScoreAverage,
    range: MetricRangeEnum.total,
  },
  {
    id: "default-3",
    size: "lg",
    rangePreset: DateRangePresetEnum.monthCurrent,
    type: MetricTypeEnum.InteractionsCreated,
  },
  {
    id: "default-4",
    size: "md",
    rangePreset: DateRangePresetEnum.monthCurrent,
    type: MetricTypeEnum.VideoCallsCreated,
  },
];

export enum AuditApplicationEnum {
  recording = "RECORDING",
  composition = "COMPOSITION",
  appointment = "APPOINTMENT",
  application = "APPLICATION",
  asr = "ASR",
}

export enum AuditOutcomeEnum {
  success = "SUCCESS",
  fail = "FAIL",
}

export enum AuditActionEnum {
  create = "CREATE",
  cancel = "CANCEL",
  reExecute = "RE_EXECUTE",
  urlAttachment = "URL_ATTACHMENT",
  urlInline = "URL_INLINE",
  start = "START",
  stop = "STOP",
  update = "UPDATE",
  delete = "DELETE",
  appointmentReschedule = "APPOINTMENT_RE_SCHEDULE",
  appointmentReasign = "APPOINTMENT_RE_ASSIGN",
  asrAddLanguage = "ASR_ADD_LANGUAGE",
  asrRemoveLanguage = "ASR_REMOVE_LANGUAGE",
  asrAddTranslationLanguage = "ASR_ADD_TRANSLATION_LANGUAGE",
  asrRemoveTranslationLanguage = "ASR_REMOVE_TRANSLATION_LANGUAGE",
}

export enum TerminateReasonEnum {
  transfer = "transfer",
  leave = "leave",
  terminate = "terminate",
  error = "error",
  refresh = "refresh",
  customerDisconnected = "customerDisconnected",
  conversationEnded = "conversationEnded",
  termsRejected = "termsRejected",
  widgetScreenShareTerminated = "widgetScreenShareTerminated",
  widgetCobrowseTerminated = "widgetCobrowseTerminated",
}

export enum SizeEnum {
  small = "sm",
  medium = "md",
  large = "lg",
  extraSmall = "xs",
}

export enum ConversationMetadataEnum {
  subject = "subject",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
}

export enum RoomModeratorEnum {
  agent = "agent",
  participant = "participant",
}

export enum NotificationTypeEnum {
  toast = "toast",
  composition = "composition",
  confirm = "confirm",
  conversation = "conversation",
  fileTransfer = "fileTransfer",
  transcript = "transcript",
}

export enum NotificationSoundEnum {
  speakerTest = "speakerTest",
  participantJoin = "participantJoin",
  participantLeave = "participantLeave",
  nudge = "nudge",
}

export enum ScheduleTypeEnum {
  genesysCallback = "genesysCallback",
  appointment = "appointment",
}

export enum AppointmentNotificationTypeEnum {
  create = "CREATE",
  update = "UPDATE",
  delete = "DELETE",
  reminder = "REMINDER",
  room = "ROOM",
  reschedule = "RESCHEDULE",
  callback = "CALLBACK",
}

export enum ViewModeEnum {
  edit,
  view,
  create,
}

export enum AppointmentChannelEnum {
  EMAIL = "email",
  SMS = "sms",
  WEBHOOK = "webhooks",
}

export enum CobrowseContextEnum {
  CONFERENCE = "conference",
  STANDALONE = "standalone",
}

export enum WebhookSubscriptionTargetEnum {
  APPOINTMENTS = "APPOINTMENTS",
}

export enum WebhookSubscriptionTypeEnum {
  NOTIFICATIONS = "NOTIFICATIONS",
}

export enum WebhookSubscriptionAuthenticationEnum {
  NONE = "NONE",
  OAUTH2_CLIENT_CREDENTIALS = "OAUTH2_CLIENT_CREDENTIALS",
  HTTP_BASIC = "HTTP_BASIC",
}

export const DEFAULT_COMPOSITION_FILE_NAME = "export_{{date}}@{{time}}";

export const DEFAULT_SNAPSHOT_TYPES = [
  { label: "ID front", value: "id-front", color: COLOR.BLUE, system: true },
  { label: "ID back", value: "id-back", color: COLOR.PURPLE, system: true },
  { label: "Passport", value: "passport", color: COLOR.GREEN, system: true },
  { label: "Selfie", value: "selfie", color: COLOR.RED, system: true },
  {
    label: "Utility bill",
    value: "utility-bill",
    color: COLOR.ORANGE,
    system: true,
  },
  {
    label: "Bank document",
    value: "bank-document",
    color: COLOR.YELLOW,
    system: true,
  },
];

export const DEFAULT_APPOINTMENT_CHAT_MODE = "genesys-cloud" as ChatModeEnum;

export const DEFAULT_AI_VISION_PROMPT =
  "Identify the primary object shown in this image. Provide details on its potential usage, common issues that may arise, and any safety or maintenance tips that would be helpful for someone using it. Tailor responses to assist a support agent in answering related customer questions.";

export const DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES = {
  [AppointmentNotificationTypeEnum.create]:
    "Your appointment has been scheduled for {{fullDate}} {{time24}}, {{zone}}",
  [AppointmentNotificationTypeEnum.update]:
    "Your appointment scheduled for {{fullDate}} {{time24}}, {{zone}} has been updated.",
  [AppointmentNotificationTypeEnum.reschedule]:
    "Your appointment scheduled for {{fullDate}} {{time24}}, {{zone}} has been updated.",
  [AppointmentNotificationTypeEnum.room]:
    "Your video call is scheduled for today at {{time24}}, {{zone}}. To join please follow the link {{roomLink}}",
  [AppointmentNotificationTypeEnum.reminder]:
    "Dear {{firstName}} {{lastName}}, this is a reminder of your video call scheduled for {{fullDate}} {{time24}}, {{zone}}",
  [AppointmentNotificationTypeEnum.delete]:
    "Your video call scheduled for {{fullDate}} {{time24}}, {{zone}} has been cancelled.",
  [AppointmentNotificationTypeEnum.callback]:
    "Hello {{name}}. Your video call has been scheduled for: {{dddd, MMMM Do YYYY}}, {{HH:mm}} ({{Z}}). Open this link in a modern browser to join the call: {{roomLink}}",
};

export const DEFAULT_APPOINTMENT_NOTIFICATION_HTML_MESSAGES = {
  [AppointmentNotificationTypeEnum.create]:
    "Your appointment has been scheduled for {{fullDate}} {{time24}}, {{zone}}",
  [AppointmentNotificationTypeEnum.update]:
    "Your appointment scheduled for {{fullDate}} {{time24}}, {{zone}} has been updated.",
  [AppointmentNotificationTypeEnum.reschedule]:
    "Your appointment scheduled for {{fullDate}} {{time24}}, {{zone}} has been updated.",
  [AppointmentNotificationTypeEnum.room]:
    "Your video call is scheduled for today at {{time24}}, {{zone}}. To join please follow the link {{roomLink}}",
  [AppointmentNotificationTypeEnum.reminder]:
    "Dear {{firstName}} {{lastName}}, this is a reminder of your video call scheduled for {{fullDate}} {{time24}}, {{zone}}",
  [AppointmentNotificationTypeEnum.delete]:
    "Your video call scheduled for {{fullDate}} {{time24}}, {{zone}} has been cancelled.",
  [AppointmentNotificationTypeEnum.callback]:
    "Hello {{name}}. <br/>Your video call has been scheduled for: <b>{{dddd, MMMM Do YYYY}}, {{HH:mm}} ({{Z}})</b>.<br/>Open this link in a modern browser to join the call:<br/><a href='{{roomLink}}'>{{roomLink}}</a><br/><br/><a href='{{googleCalendarLink}}'>Add to Google Calendar</a> | <a href='{{iCalendarLink}}'>Add to iCal</a> |<a href='{{outlookCalendarLink}}'>Add to Outlook</a>",
};

export const DEFAULT_APPOINTMENT_NOTIFICATION_SUBJECTS = {
  [AppointmentNotificationTypeEnum.create]:
    "Your video call has been scheduled",
  [AppointmentNotificationTypeEnum.update]: "Your video call has been updated.",
  [AppointmentNotificationTypeEnum.reschedule]:
    "Your video call has been updated.",
  [AppointmentNotificationTypeEnum.room]:
    "Your video call is scheduled for today.",
  [AppointmentNotificationTypeEnum.reminder]: "Reminder of your video call",
  [AppointmentNotificationTypeEnum.delete]:
    "Your video call has been cancelled.",
  [AppointmentNotificationTypeEnum.callback]: "Video call reminder",
};

export const DEFAULT_CALLBACK_NOTIFICATIONS: IAppointmentNotification[] = [
  {
    id: "1",
    channel: AppointmentChannelEnum.SMS,
    type: AppointmentNotificationTypeEnum.callback,
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.callback
      ],
  },
  {
    id: "2",
    channel: AppointmentChannelEnum.EMAIL,
    type: AppointmentNotificationTypeEnum.callback,
    subject:
      DEFAULT_APPOINTMENT_NOTIFICATION_SUBJECTS[
        AppointmentNotificationTypeEnum.callback
      ],
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.callback
      ],
    htmlBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_HTML_MESSAGES[
        AppointmentNotificationTypeEnum.callback
      ],
  },
];

export const DEFAULT_APPOINTMENT_NOTIFICATIONS: IAppointmentNotification[] = [
  {
    id: "1",
    channel: AppointmentChannelEnum.SMS,
    enabled: true,
    type: AppointmentNotificationTypeEnum.room,
    period: moment.duration(5, "minutes").toISOString(),
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.room
      ],
  },
  {
    id: "2",
    channel: AppointmentChannelEnum.SMS,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reminder,
    period: moment.duration(1, "days").toISOString(),
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reminder
      ],
  },
  {
    id: "3",
    channel: AppointmentChannelEnum.SMS,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reminder,
    period: moment.duration(2, "days").toISOString(),
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reminder
      ],
  },
  {
    id: "4",
    channel: AppointmentChannelEnum.SMS,
    enabled: true,
    type: AppointmentNotificationTypeEnum.create,
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.create
      ],
  },
  {
    id: "5",
    channel: AppointmentChannelEnum.SMS,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reschedule,
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reschedule
      ],
  },
  {
    id: "6",
    channel: AppointmentChannelEnum.SMS,
    enabled: true,
    type: AppointmentNotificationTypeEnum.delete,
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.delete
      ],
  },
  {
    id: "10",
    channel: AppointmentChannelEnum.EMAIL,
    enabled: true,
    type: AppointmentNotificationTypeEnum.room,
    period: moment.duration(5, "minutes").toISOString(),
    subject:
      DEFAULT_APPOINTMENT_NOTIFICATION_SUBJECTS[
        AppointmentNotificationTypeEnum.room
      ],
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.room
      ],
    htmlBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.room
      ],
  },
  {
    id: "11",
    channel: AppointmentChannelEnum.EMAIL,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reminder,
    period: moment.duration(1, "days").toISOString(),
    subject:
      DEFAULT_APPOINTMENT_NOTIFICATION_SUBJECTS[
        AppointmentNotificationTypeEnum.room
      ],
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reminder
      ],
    htmlBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reminder
      ],
  },
  {
    id: "12",
    channel: AppointmentChannelEnum.EMAIL,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reminder,
    period: moment.duration(2, "days").toISOString(),
    subject:
      DEFAULT_APPOINTMENT_NOTIFICATION_SUBJECTS[
        AppointmentNotificationTypeEnum.reminder
      ],
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reminder
      ],
    htmlBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reminder
      ],
  },
  {
    id: "13",
    channel: AppointmentChannelEnum.EMAIL,
    enabled: true,
    type: AppointmentNotificationTypeEnum.create,
    subject:
      DEFAULT_APPOINTMENT_NOTIFICATION_SUBJECTS[
        AppointmentNotificationTypeEnum.create
      ],
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.create
      ],
    htmlBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.create
      ],
  },
  {
    id: "14",
    channel: AppointmentChannelEnum.EMAIL,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reschedule,
    subject:
      DEFAULT_APPOINTMENT_NOTIFICATION_SUBJECTS[
        AppointmentNotificationTypeEnum.reschedule
      ],
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reschedule
      ],
    htmlBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.reschedule
      ],
  },
  {
    id: "15",
    channel: AppointmentChannelEnum.EMAIL,
    enabled: true,
    type: AppointmentNotificationTypeEnum.delete,
    subject:
      DEFAULT_APPOINTMENT_NOTIFICATION_SUBJECTS[
        AppointmentNotificationTypeEnum.delete
      ],
    textBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.delete
      ],
    htmlBody:
      DEFAULT_APPOINTMENT_NOTIFICATION_MESSAGES[
        AppointmentNotificationTypeEnum.delete
      ],
  },

  {
    id: "21",
    channel: AppointmentChannelEnum.WEBHOOK,
    enabled: true,
    type: AppointmentNotificationTypeEnum.room,
    period: moment.duration(5, "minutes").toISOString(),
    properties: [],
  },
  {
    id: "22",
    channel: AppointmentChannelEnum.WEBHOOK,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reminder,
    period: moment.duration(1, "days").toISOString(),
    properties: [],
  },
  {
    id: "23",
    channel: AppointmentChannelEnum.WEBHOOK,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reminder,
    period: moment.duration(2, "days").toISOString(),
    properties: [],
  },
  {
    id: "24",
    channel: AppointmentChannelEnum.WEBHOOK,
    enabled: true,
    type: AppointmentNotificationTypeEnum.create,
    properties: [],
  },
  {
    id: "25",
    channel: AppointmentChannelEnum.WEBHOOK,
    enabled: true,
    type: AppointmentNotificationTypeEnum.reschedule,
    properties: [],
  },
  {
    id: "26",
    channel: AppointmentChannelEnum.WEBHOOK,
    enabled: true,
    type: AppointmentNotificationTypeEnum.delete,
    properties: [],
  },
];

export enum SegmentState {
  Off,
  /** Enabled only */
  // On,
  /** Working on a segmentation task */
  Setting,
  /** Segmentation ready */
  Set,
}

export enum AgentFeatureEnum {
  displayCapture,
}

export enum CustomerFeatureEnum {
  displayCapture,
}

export enum GeolocationError {
  PERMISSION_DENIED = "PERMISSION_DENIED",
  POSITION_UNAVAILABLE = "POSITION_UNAVAILABLE",
  TIMEOUT = "TIMEOUT",
}

export enum WhisperModeEnum {
  MONITOR = "monitor",
  COACH = "coach",
  BARGE = "barge",
}

export type TranscriptTransformType =
  | "summary"
  | "sentiment"
  | "prompt"
  | "translation"
  | "vision";

export type TranscriptIntentType = "summary" | "sentiment" | "prompt";
