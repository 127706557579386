<app-survey-option
  *ngFor="let o of options"
  [option]="o"
  [active]="isActive(o)"
>
  <input
    type="checkbox"
    name="{{ question.id }}"
    [ngModel]="o.value"
    (ngModelChange)="optionChange($event, o)"
  />
</app-survey-option>
<div class="form-error" *ngIf="isError()" translate>Required</div>
