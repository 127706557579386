<app-survey
  [interaction]="interaction"
  (done)="surveyCompleted($event)"
  (skipped)="surveySkipped()"
  *ngIf="visible() && isSurveyOpen() && !isRated()"
  [@slideInOut]="true"
></app-survey>

<av-card
  class="rating"
  [backdrop]="false"
  *ngIf="visible() && isRated()"
  [@slideInOut]="true"
>
  <av-card-body type="container">
    <div class="fx-column fx-align-center fx-justify-center">
      <av-icon name="starred" mode="light" class="starred"></av-icon>
      <h1 translate>Thank you!</h1>
    </div>
  </av-card-body>
</av-card>

<av-card
  role="dialog"
  [attr.aria-label]="'Please provide feedback' | translate"
  [backdrop]="false"
  class="rating"
  *ngIf="visible() && isFeedbackAvailable() && !isSurveyOpen() && !isRated()"
  [@slideInOut]="true"
>
  <av-card-header
    (close)="cancel()"
    subtitle="How was the audio and video quality ?"
  >
    <span translate>Video & Audio Quality</span>
  </av-card-header>

  <av-card-body>
    <div class="fx-column fx-align-stretch fx-justify-center">
      <div class="stars" role="radiogroup">
        <button
          role="radio"
          (mouseenter)="fill(point)"
          (mouseleave)="empty()"
          (click)="rate(point)"
          [class.star-rate]="isActive(point)"
          [class.star-dark]="isDarkMode()"
          [attr.aria-checked]="feedback.score === point"
          class="star"
          *ngFor="let point of points"
          data-tid="rating/stars"
        ></button>
      </div>
      <ng-container *ngIf="isLowRating">
        <textarea
          avInput
          name="comment"
          [maxlength]="maxChars"
          [(ngModel)]="feedback.comment"
          placeholder="{{ 'What went wrong?' | translate }}"
          rows="5"
        ></textarea>
        <div>
          <small class="text-muted pull-right"
            >{{ feedback.comment.length }}/{{ maxChars }}</small
          >
        </div>
      </ng-container>
      <div
        class="fx-row actions fx-align-center"
        [class.fx-justify-space-between]="isLowRating"
        [class.fx-justify-center]="!isLowRating"
      >
        <button
          avButton
          (click)="cancel()"
          ofType="basic"
          translate
          data-tid="rating/skip"
        >
          skip
        </button>
        <button
          avButton
          color="accent"
          *ngIf="isLowRating"
          (click)="next()"
          data-tid="rating/send"
        >
          <span *ngIf="!isSurveyAvailable()" translate> Send feedback </span>
          <span *ngIf="isSurveyAvailable()" translate> Next </span>
        </button>
      </div>
    </div>
  </av-card-body>
</av-card>
