import {
  EndpointTypeEnum,
  UserCapabilityEnum,
  UserRoleEnum,
  ConversationOriginEnum,
  TerminateReasonEnum,
  WhisperModeEnum,
} from "../core-ui.enums";
import { IDevice } from "@auvious/media-tools";
import { IEndpoint } from "@auvious/rtc";

export enum EndpointStateEnum {
  Joined = "joined",
  Sick = "sick",
  Left = "left",
}

export enum StreamStateEnum {
  Warning = "warning",
  Added = "added",
  Failed = "failed",
  Left = "left",
}

export interface IEndpointMetadata {
  roles?: UserRoleEnum[];
  capabilities?: UserCapabilityEnum[];
  language?: string;
  origin?: ConversationOriginEnum;
  type?: EndpointTypeEnum;
  mediaDevices?: IDevice[];
  mediaDevicesError?: boolean;
  name?: string;
  avatarUrl?: string;
  originUrl?: string;
  cobrowseOriginator?: IEndpoint;
  customerMetadata?: { [key: string]: any };
  termsAccepted?: string;
  termsAcceptedAt?: string;
  reason?: TerminateReasonEnum;
  whisperMode?: WhisperModeEnum;
}
