import { Directive, ElementRef, AfterViewInit } from "@angular/core";
import { trapFocus } from "../../../app/app.utils";

@Directive({
  selector: "[avTrapFocus]",
})
export class TrapFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    trapFocus(this.el.nativeElement);
  }
}
