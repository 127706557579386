<av-card-row
  class="text-center"
  *ngIf="!isTranscriptAvailable() && isTranscriptLoading()"
>
  <av-spinner size="sm"></av-spinner>
</av-card-row>

<av-card-row
  class="info-row"
  *ngIf="!isTranscriptAvailable() && !isTranscriptLoading()"
>
  <av-card-row-responsive class="fx-flex-1" [flex]="1">
    <ng-container card-row-title>
      <av-card-row-column label="Select the language of the recording.">
        <ng-container column-value>
          <select
            class="full-width"
            name="providerlanguage"
            [(ngModel)]="activeLanguage"
          >
            <option selected value="" disabled>-</option>
            <option [ngValue]="l" *ngFor="let l of languages()">
              {{ l.name }}
            </option>
          </select>
        </ng-container>
      </av-card-row-column>
    </ng-container>
    <ng-container card-row-body>
      <av-card-row-column>
        <ng-container column-value>
          <div class="fx-row fx-gap-10 fx-align-start fx-justify-space-between">
            <div class="control">
              <button
                avButton
                [disabled]="isGenerateTranscriptDisabled()"
                (click)="requestTranscript()"
                color="accent"
                class="fx-gap-10"
                data-tid="composition/transcript"
                [raised]="true"
                [loading]="isTranscriptLoading()"
              >
                <av-icon size="sm" name="transcript"></av-icon>
                {{ "Generate transcript" | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </av-card-row-column>
    </ng-container>
  </av-card-row-responsive>
</av-card-row>

<av-card-row *ngIf="isTranscriptProcessing()">
  <span translate>Processing transcript. Please wait...</span>
</av-card-row>
<av-card-row *ngIf="isTranscriptSubmitted()">
  <span translate>Transcript submitted for processing. Please wait...</span>
</av-card-row>

<av-card-row *ngIf="isTranscriptCompleted()">
  <av-card-row-responsive
    class="transcript-row"
    flex="1"
    [bodyHidden]="true"
    icon="file-transcript"
  >
    <ng-container card-row-title>
      {{ transcriptTitle() }}
    </ng-container>
    <ng-container card-row-subtitle>
      {{
        transcriptStatus().createdAt
          | amLocale : lang
          | amDateFormat : "MMM D y, h:mm a"
      }}
    </ng-container>

    <ng-container card-row-actions>
      <div class="fx-row fx-gap-10 fx-align-center fx-justify-space-between">
        <div class="control" *ngIf="isSummarizeAvailable">
          <button
            avButton
            ofType="icon"
            color="accent"
            size="sm"
            data-tid="composition/summarize"
            [raised]="true"
            (click)="summarize()"
            [disabled]="!!transformSummary() || isSummaryLoading()"
          >
            <av-icon name="summary"></av-icon>
          </button>

          <div translate>Summarize</div>
        </div>

        <div class="control" *ngIf="isTranslateAvailable">
          <div class="control-popup">
            <button
              avButton
              ofType="icon"
              color="accent"
              size="sm"
              data-tid="composition/summarize"
              [raised]="true"
              (click)="isTranslatePopupOpen.set(true)"
              [disabled]="!!transformTranslation() || isTranslateLoading()"
            >
              <av-icon name="translation"></av-icon>
            </button>

            <av-card
              [popup]="true"
              arrow="bottom-right"
              hAlign="right"
              vAlign="top"
              [backdrop]="false"
              class="translate-popup"
              *ngIf="isTranslatePopupOpen()"
            >
              <av-card-header
                subtitle="Select the language for translation"
                (close)="isTranslatePopupOpen.set(false)"
                >{{ "Language" | translate }}</av-card-header
              >
              <av-card-body>
                <select
                  class="full-width"
                  name="translatelanguage"
                  [(ngModel)]="translateLanguage"
                >
                  <option selected value="" disabled>-</option>
                  <option
                    [ngValue]="l"
                    *ngFor="let l of translationLanguages()"
                  >
                    {{ l.name }}
                  </option>
                </select>
              </av-card-body>
              <av-card-footer>
                <button
                  avButton
                  (click)="translate()"
                  color="accent"
                  size="block"
                  data-tid="composition/transcript/translate"
                  translate
                  [disabled]="!translateLanguage"
                >
                  Translate
                </button>
              </av-card-footer>
            </av-card>
          </div>
          <div translate>Translate</div>
        </div>

        <div class="control" *ngIf="isSentimentAvailable">
          <button
            avButton
            ofType="icon"
            color="accent"
            size="sm"
            data-tid="composition/summarize"
            [raised]="true"
            (click)="analyze()"
            [disabled]="!!transformSentiment() || isSentimentLoading()"
          >
            <av-icon name="sentiment"></av-icon>
          </button>
          <div translate>Sentiment</div>
        </div>

        <div class="control" *ngIf="isPromptAvailable">
          <div class="control-popup">
            <button
              avButton
              ofType="icon"
              color="accent"
              size="sm"
              data-tid="composition/prompt"
              [raised]="true"
              (click)="prompt()"
              [disabled]="!!transformPrompt() || isPromptLoading()"
            >
              <av-icon name="prompt"></av-icon>
            </button>
          </div>
          <div translate>Custom</div>
        </div>

        <ng-container *ngIf="canRequestFormattedTranscript">
          <av-tooltip message="Content">
            <button
              [attr.aria-label]="'Content' | translate"
              avButton
              size="xs"
              ofType="icon"
              color="link"
              (click)="toggleContent()"
              [loading]="isLoadingContent()"
              [disabled]="isLoadingContent()"
            >
              <av-icon
                [class.expanded]="isContentExpanded()"
                size="sm"
                name="chevron-down"
              ></av-icon>
            </button>
          </av-tooltip>
        </ng-container>

        <div class="control-separator"></div>

        <av-confirm
          [open]="confirmRemove()"
          (confirmed)="removeTranscript()"
          (canceled)="confirmRemove.set(false)"
          arrow="bottom-right"
        >
          <button
            avButton
            ofType="icon"
            color="danger"
            size="xxs"
            [disabled]="isDeleteTranscriptDisabled()"
            (click)="confirmRemove.set(true)"
          >
            <av-icon size="xs" name="trash"></av-icon>
          </button>
          <span confirmTitle translate>Are you sure?</span>
          <!-- <div confirmBody>
            {{
              "You can only export one language per recording. The existing transcript will be removed."
                | translate
            }} 
          </div>-->
        </av-confirm>

        <av-tooltip
          message="Get download link"
          *ngIf="!canRequestFormattedTranscript && !transcriptUrl()"
        >
          <button
            [attr.aria-label]="'Get download link' | translate"
            avButton
            ofType="icon"
            color="link"
            size="xs"
            data-tid="composition/download"
            [loading]="isLoadingURL()"
            (click)="getDownloadLink()"
          >
            <av-icon size="sm" name="link"></av-icon>
          </button>
        </av-tooltip>

        <av-tooltip message="Download" *ngIf="canRequestFormattedTranscript">
          <button
            [attr.aria-label]="'Download' | translate"
            avButton
            size="xs"
            ofType="icon"
            color="link"
            (click)="download()"
          >
            <av-icon size="sm" name="document-download"></av-icon>
          </button>
        </av-tooltip>

        <ng-container *ngIf="!!transcriptUrl()">
          <av-tooltip message="Download">
            <a
              [attr.aria-label]="'Download' | translate"
              avButton
              size="xs"
              ofType="icon"
              color="link"
              [href]="transcriptUrl()"
              download="{{ transcriptStatus().languageName }}"
              target="_blank"
            >
              <av-icon size="sm" name="document-download"></av-icon>
            </a>
          </av-tooltip>

          <av-copy-clipboard
            [url]="transcriptUrl()"
            size="xs"
          ></av-copy-clipboard>
        </ng-container>
      </div>
    </ng-container>
  </av-card-row-responsive>
  <av-card-body *ngIf="isContentExpanded()" type="spotlight" class="content">
    <av-copy-clipboard
      size="xs"
      [tooltipLabel]="'Copy to clipboard' | translate"
      [url]="copyData()"
    ></av-copy-clipboard>
    <app-composition-transcript-content
      [data]="content().chatTranscriptList"
    ></app-composition-transcript-content>
  </av-card-body>
</av-card-row>

<ng-container *ngIf="isTranscriptCompleted() && !!conversationId">
  <av-card-row *ngIf="!!transformSummary()">
    <app-composition-transcript-transform
      [transform]="transformSummary()"
      [conversationId]="conversationId"
      [transcriptId]="transcriptStatus().id"
      transformType="summary"
      (removed)="transformSummary.set(undefined)"
    ></app-composition-transcript-transform>
  </av-card-row>

  <av-card-row *ngIf="!!transformTranslation()">
    <app-composition-transcript-transform
      [transform]="transformTranslation()"
      [conversationId]="conversationId"
      [transcriptId]="transcriptStatus().id"
      transformType="translation"
      (removed)="transformTranslation.set(undefined)"
    ></app-composition-transcript-transform>
  </av-card-row>

  <av-card-row *ngIf="!!transformSentiment()">
    <app-composition-transcript-transform
      [transform]="transformSentiment()"
      [conversationId]="conversationId"
      [transcriptId]="transcriptStatus().id"
      transformType="sentiment"
      (removed)="transformSentiment.set(undefined)"
    ></app-composition-transcript-transform>
  </av-card-row>

  <av-card-row *ngIf="!!transformPrompt()">
    <app-composition-transcript-transform
      [transform]="transformPrompt()"
      [conversationId]="conversationId"
      [transcriptId]="transcriptStatus().id"
      transformType="prompt"
      (removed)="transformPrompt.set(undefined)"
    ></app-composition-transcript-transform>
  </av-card-row>
</ng-container>
