import { ParamMap } from "@angular/router";
import { debug as factory } from "debug";
import { PARAM_APPLICATION_ID, PARAM_TALKDESK_ID } from "./app.enums";
import { addBreadcrumb, SeverityLevel } from "@sentry/angular";
import { FormGroup } from "@angular/forms";
import { decodeBase64 } from "../core-ui";

export const createSentryLogger =
  (category: string) =>
  (message: any, level: SeverityLevel = "info") => {
    try {
      addBreadcrumb({
        category,
        message: JSON.stringify(message),
        level,
      });
    } catch {}
  };

export const debug = factory("auvious::integration");
export const debugError = factory("auvious::integration:error");
debugError.log = console.error.bind(console);

export function discoverApplicationId(
  queryParamMap: ParamMap | URLSearchParams
): string {
  if (queryParamMap.has(PARAM_APPLICATION_ID)) {
    return queryParamMap.get(PARAM_APPLICATION_ID);
  }

  if (queryParamMap.has(PARAM_TALKDESK_ID)) {
    const id = queryParamMap.get(PARAM_TALKDESK_ID);
    return id ? `talkdesk_${id}` : null;
  }

  if (queryParamMap.has("state")) {
    try {
      const state = JSON.parse(decodeBase64(queryParamMap.get("state"))) as any;
      return state.applicationId;
    } catch {}
  }

  return null;
}

/** create bundles from angular.json or provile URL */
export async function loadScript(name: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = name;
    script.onload = () => resolve();
    script.onerror = () => reject();
    document.body.appendChild(script);
  });
}

export function markFormFields(form: FormGroup) {
  for (const key in form.controls) {
    if (key in form.controls) {
      form.controls[key].markAsTouched();
    }
  }
}

export function mapToJSON(map: Map<any, any>): object {
  return Array.from(map.entries()).reduce((o, [key, value]) => {
    o[key] = value;

    return o;
  }, {});
}

export function trapFocus(element) {
  element.addEventListener("keydown", function (e) {
    const getActiveElement = () => {
      if (document.activeElement?.shadowRoot) {
        return document.activeElement.shadowRoot.activeElement;
      } else {
        return document.activeElement;
      }
    };

    const isTabPressed = e.keyCode === 9; // isTabPressed
    if (!isTabPressed) return;

    const focusableEls1 = element.querySelectorAll(
      'a[href], button, textarea, input[type="text"],' +
        'input[type="radio"], input[type="checkbox"], select'
    );
    const focusableEls = Array.from(focusableEls1).filter(
      (el: any) => !el.disabled
    );
    const firstFocusableEl: any = focusableEls[0];
    const lastFocusableEl: any = focusableEls[focusableEls.length - 1];

    if (e.shiftKey) {
      /* shift + tab */ if (getActiveElement() === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } /* tab */ else {
      if (getActiveElement() === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }
  });
}
